/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import CustomButton from '../../../components/CustomButton';
import TextAreaControl from '../../../components/TextAreaControl';
import SwitchControl from '../../../components/SwitchControl';
import CustomControl from '../../../components/CustomControl';
import { createCallOff } from '../../../services/HumanResourcesService';
import { ValidationGroup, validateExistence } from '../../../validation';

export default function HRScheduleCallOffCreator({userIdentifier, handleCreateCallOff}) {

    const [date, setDate] = useState('');
    const [notes, setNotes] = useState('');
    const [notifiedManager, setNotifiedManager] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const validationGroup = new ValidationGroup();

    const handleCreateNoShow = async () => {
        setIsSubmitting(true);
        const callOff = {
            userIdentifier: userIdentifier,
            date: date, 
            notifiedManager: notifiedManager,
            notes: notes,
            submissionDate: moment().format('YYYY-MM-DD')
        }
        const response = await createCallOff(callOff);
        if (response.status === 200) {
            callOff.uid = response.uid;
            handleCreateCallOff(callOff);
        }
        setIsSubmitting(false);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Report Call Off / No-Show</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <CustomControl validator={validationGroup.createValidator(() => validateExistence(date))} floatingLabel type='date' value={date} setValue={setDate} title='Date'/>
                <TextAreaControl validator={validationGroup.createValidator(() => validateExistence(notes))} max={300} rows={6} value={notes} setValue={setNotes} label='Notes'/>
                <SwitchControl title='Did employee notify manager?' value={notifiedManager} setValue={setNotifiedManager}/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit' isLoading={isSubmitting} onClick={handleCreateNoShow}/>
            </Modal.Footer>    
        </>
    )
}