/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import './NavigationBar.css';
import './NavigationBarLink.css'
import Modal from 'react-bootstrap/Modal';
import { faUser, faClock, faCalendar, faCalendarXmark, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { 
    faChartLine,
    faTableColumns, 
    faBuilding, 
    faGripLines, 
    faUsers, 
    faToolbox,
    faUsersBetweenLines,
    faRightFromBracket,
    faDollarSign,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/logo.png';
import NavigationBarHeader from './NavigationBarHeader';
import NavigationBarLink from './NavigationBarLink';
import Cookies from 'universal-cookie';
import LogoutModal from './LogoutModal';
import moment from 'moment';
import StopShadowingModal from './StopShadowingModal';

function NavigationBar({auth}) {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showStopShadowing, setShowStopShadowing] = useState(false);
    const userData = new Cookies().get('userData');
    const timeclockEnabled = new Cookies().get('timeclockEnabled');
    const permissions = userData?.pay.permissions;

    const hrPermissions = 
        permissions.hrHire == 1
        || permissions.hrTerminate == 1
        || permissions.hrProfile == 1
        || permissions.hrTimesheet == 1
        || permissions.hrSchedule == 1
        || permissions.hrPay == 1
        || permissions.hrPto == 1
        || permissions.hrLoans == 1
        || permissions.hrDocuments == 1
        || permissions.hrPermissions == 1
    ;

    const reportsPermissions = permissions.hrTimesheet == 1;

    
      
            
    return (
        <div className='nav-bar-wrapper' style={{flexShrink: 0}}>
            {/* <button type="button" className={'nav-bar-collapse-button' + (collapsed ? ' nav-bar-collapse-button-collapsed' : '')} onClick={() => {setCollapsed(!collapsed)}}>
                <FontAwesomeIcon icon={collapsed ? faCaretRight : faCaretLeft}/>
            </button> */}
            <img src={logo} alt={'TTA LOGO'} id='logo' style={{paddingLeft: 8, paddingRight: 8}}/>
            <div className='hidden-scrollbar' style={{width: '100%', flex: 1, overflowY: 'scroll'}}>
                <div style={{padding: '0px 9px 0px 8px'}}>
                    <div style={{marginBottom: 16}}>
                        <NavigationBarHeader title={'Account'} icon={faUser}/>
                        <NavigationBarLink title={'Dashboard'} icon={faTableColumns} destination={'/'}/>
                        <NavigationBarLink title={userData.firstName + '\'s Profile'} icon={faUser} destination={'/profile'}/>
                        { timeclockEnabled == 1 && 
                            <NavigationBarLink title={'Timeclock'} icon={faClock} destination={'/timeclock'}/>
                        }
                        <NavigationBarLink title={'My Schedule'} icon={faCalendar} destination={'/my-schedule'}/>
                        <NavigationBarLink title={'Days Off'} icon={faCalendarXmark} destination={'/days-off'}/>
                        <NavigationBarLink title={'My Documents'} icon={faFolderOpen} destination={'/my-documents'}/>
                    </div>
                    { (permissions.myCompany == 1 || permissions.scheduling == 1) && 
                        <div style={{marginBottom: 16}}>
                            <NavigationBarHeader title={'ISP Management'} icon={faBuilding}/>
                            { permissions.myCompany == 1 && 
                                <NavigationBarLink title={'My Company'} icon={faUsers} destination={'/my-company'}/>
                            }
                            { permissions.scheduling == 1 && 
                                <NavigationBarLink title={'Scheduling'} icon={faGripLines} destination={'/scheduling'}/>
                            }
                        </div>
                    }
                    { (permissions.payroll == 1 || hrPermissions) && 
                        <div style={{marginBottom: 16}}>
                            <NavigationBarHeader title={'Operations'} icon={faToolbox}/>
                            { permissions.payroll == 1 && 
                                <NavigationBarLink title={'Payroll'} icon={faDollarSign} destination={'/payroll'}/>
                            }
                            { hrPermissions && 
                                <NavigationBarLink title={'Human Resources'} icon={faUsersBetweenLines} destination={'/human-resources'}/>
                            }
                            { reportsPermissions && 
                                <NavigationBarLink title={'Reports'} icon={faChartLine} destination={'/reports'}/>
                            }
                        </div>
                    }
                </div>
                <div style={{width:'100%', height: 1, backgroundColor: 'lightgray', margin: '16px 0px 16px 0px'}}/>
                <div style={{padding: '0px 9px 0px 8px'}}>
                    <button className='nav-bar-link' style={{border: 'none', width: '100%'}} onClick={() => {setShowLogoutModal(true)}}>
                        <div style={{width: 25, display: 'flex', justifyContent: 'center'}}>
                            <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 8}}/>
                        </div>
                        <p style={{fontSize: 18, margin: 0, }}>Logout</p>
                    </button>
                </div>
                { auth.adminIdentifier && 
                    <div style={{padding: '0px 9px 0px 8px'}}>
                        <button className='nav-bar-link' style={{border: 'none', width: '100%', color: 'red'}} onClick={() => {setShowStopShadowing(true)}}>
                            <div style={{width: 25, display: 'flex', justifyContent: 'center'}}>
                                <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 8}}/>
                            </div>
                            <p style={{fontSize: 18, margin: 0, }}>Stop Shadowing</p>
                        </button>
                    </div>
                }
            </div>
            
            <div style={{textAlign: 'center', fontSize: 12}}>
                <p style={{margin: 0}}>{`Copyright © ${moment().format('YYYY')}`}</p>
                <p style={{margin: 0}}>Tactical Transportation Advisors</p>
                <p style={{margin: 0}}>Version 1.0.6</p>
            </div>
            <Modal show={showLogoutModal} onHide={() => {setShowLogoutModal(false)}} centered>
                <LogoutModal/>
            </Modal>
            <Modal show={showStopShadowing} onHide={() => {setShowStopShadowing(false)}} centered>
                <StopShadowingModal/>
            </Modal>

        </div>
    );
}

export default NavigationBar;