/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './App.css';
import React from 'react';
import NavigationBar from './components/NavigationBar/NavigationBar';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { Modal, Toast } from 'react-bootstrap';
import AcknowledgementModal from './views/AcknowledgementModal';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import TopNavigationBar from './components/TopNavigationBar/TopNavigationBar';
import Admin from './views/Admin/Admin';


export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showOffCanvas: true,
            showModal: props.documents?.length > 0,
            showNotification:false,

            alertContent: undefined,
            showAlert: false,
            mobileView: window.innerWidth < 500
        }
        this.contentRef = React.createRef();

        this.alert = this.alert.bind(this);
        this.dismissAlert = this.dismissAlert.bind(this);
        this.handleResize = this.handleResize.bind(this);

        AppGlobals.alert = this.alert;
        AppGlobals.dismissAlert = this.dismissAlert;
    }


    alert(element) {
        this.setState({alertContent: element, showAlert: true});
    }

    dismissAlert() {
        this.setState({showAlert: false});
    }

    handleResize() {
        if (this.state.mobileView && window.innerWidth >= 500) {
            this.setState({mobileView: false});
        } else if (!this.state.mobileView && window.innerWidth < 500) {
            this.setState({mobileView: true});
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render() {   
    
        return (
            <div className="App"> {/* Navigation bar rendered second with row-reverse so that shadow renders on top of outlet */}
                { this.props.auth.userIdentifier ? 
                    <BrowserRouter>
                        <div ref={this.contentRef} className={'cwt-in'} style={{flex: 1,  height: '100vh', display: 'flex', flexDirection: 'column', position: 'relative'}}> 
                            { this.state.mobileView && 
                                <TopNavigationBar auth={this.props.auth}/>
                            }
                            <div style={{height: '100vh', paddingTop: this.state.mobileView ? 60 : 0}}>
                                <Router mobileView={this.state.mobileView}/>
                            </div>
                            { !this.state.mobileView && 
                                <Button style={{color:'white', position: 'absolute', zIndex: 1, bottom: '45%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, padding: 2, height: 100}} onClick={() => {this.setState({showOffCanvas: !this.state.showOffCanvas})}}>
                                    <FontAwesomeIcon icon={this.state.showOffCanvas ? faCaretLeft : faCaretRight}/>
                                </Button>
                            }
                        </div>
                        { !this.state.mobileView && 
                            <CSSTransition nodeRef={this.contentRef} in={this.state.showOffCanvas} timeout={250} classNames='my-node' unmountOnExit>
                                <div ref={this.contentRef}>
                                    <NavigationBar auth={this.props.auth}/>
                                </div>
                            </CSSTransition>
                        }
                    </BrowserRouter>
                :
                    <Admin/>
                }
                <Modal show={this.state.showModal} onHide={() => {this.setState({showModal: false})}} fullscreen backdrop='static'>
                    <AcknowledgementModal documents={this.props.documents} hideModal={() => {this.setState({showModal: false})}}/>
                </Modal>
                <Modal className='invisible-modal' show={this.state.showAlert} onHide={() => {this.setState({showAlert: false})}}>
                    <Toast show={this.state.showAlert} onClose={this.dismissAlert}>
                        {this.state.alertContent}
                    </Toast>
                </Modal>
            </div>
        );
    }
}

export class AppGlobals {
    static alert;
    static dismissAlert;
    static CookieMonster = new Cookies();
}