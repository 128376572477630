/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getSchedule(locationIdentifier, startDate, endDate) {
    const data = {
        type: 'scheduling',
        target: 'getSchedule',
        token: getToken(),
        locationIdentifier: locationIdentifier,
        startDate: startDate,
        endDate: endDate
    }

    return await getResponse(data);
}

export async function createShift(shift) {
    const data = {
        type: 'scheduling',
        target: 'createShift',
        token: getToken(),
        shift: shift,
    }

    return await getResponse(data);
}

export async function updateShift(shift) {
    const data = {
        type: 'scheduling',
        target: 'updateShift',
        token: getToken(),
        shift: shift,
    }

    return await getResponse(data);
}

export async function deleteShift(uid) {
    const data = {
        type: 'scheduling',
        target: 'deleteShift',
        token: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function createShiftType(shiftType) {
    const data = {
        type: 'scheduling',
        target: 'createShiftType',
        token: getToken(),
        shiftType: shiftType,
    }

    return await getResponse(data);
}

export async function updateShiftType(shiftType) {
    const data = {
        type: 'scheduling',
        target: 'updateShiftType',
        token: getToken(),
        shiftType: shiftType,
    }

    return await getResponse(data);
}

export async function deleteShiftType(uid, replacementUid) {
    const data = {
        type: 'scheduling',
        target: 'deleteShiftType',
        token: getToken(),
        uid: uid,
        replacementUid: replacementUid,
    }

    return await getResponse(data);
}

export async function createShiftTemplate(shiftTemplate) {
    const data = {
        type: 'scheduling',
        target: 'createShiftTemplate',
        token: getToken(),
        shiftTemplate: shiftTemplate,
    }

    return await getResponse(data);
}

export async function updateShiftTemplate(shiftTemplate) {
    const data = {
        type: 'scheduling',
        target: 'updateShiftTemplate',
        token: getToken(),
        shiftTemplate: shiftTemplate,
    }

    return await getResponse(data);
}

export async function deleteShiftTemplate(uid) {
    const data = {
        type: 'scheduling',
        target: 'deleteShiftTemplate',
        token: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function createScheduleTemplate(scheduleTemplate) {
    const data = {
        type: 'scheduling',
        target: 'createScheduleTemplate',
        token: getToken(),
        scheduleTemplate: scheduleTemplate,
    }

    return await getResponse(data);
}

export async function overwriteScheduleTemplate(scheduleTemplate) {
    const data = {
        type: 'scheduling',
        target: 'overwriteScheduleTemplate',
        token: getToken(),
        scheduleTemplate: scheduleTemplate,
    }

    return await getResponse(data);
}

export async function renameScheduleTemplate(scheduleTemplate) {
    const data = {
        type: 'scheduling',
        target: 'renameScheduleTemplate',
        token: getToken(),
        scheduleTemplate: scheduleTemplate,
    }

    return await getResponse(data);
}

export async function deleteScheduleTemplate(uid) {
    const data = {
        type: 'scheduling',
        target: 'deleteScheduleTemplate',
        token: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function importScheduleTemplate(uid, startDate, endDate) {
    const data = {
        type: 'scheduling',
        target: 'importScheduleTemplate',
        token: getToken(),
        uid: uid,
        startDate: startDate,
        endDate: endDate
    }

    return await getResponse(data);
}