/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './ShiftTimeline.css';
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import ReactSlider from "react-slider";

export default function ShiftTimeline({startTime, setStartTime, endTime, setEndTime, breaks, setBreaks}) {
    const [zoom, setZoom] = useState(1);

    const timelineRef = useRef();

    useEffect(() => {
        timelineRef.current.scroll(0, 300);
    }, [])

    const handleAddBreak = () => {
        const newBreaks = Array.from(breaks);
        if (newBreaks.length > 0 && newBreaks[newBreaks.length - 1].endTime + 30 <= endTime - 30) {
            newBreaks.push({
                startTime: newBreaks[newBreaks.length - 1].endTime + 30,
                endTime: newBreaks[newBreaks.length - 1].endTime + 30 + 30
            })
        } else if (breaks.length === 0 && startTime + 30 <= endTime - 30) {
            newBreaks.push({
                startTime: startTime + 30,
                endTime: startTime + 60
            })

        }
        setBreaks(newBreaks);
    }

    const handleRemoveBreak = (index) => {
        const newBreaks = breaks.toSpliced(index, 1);
        setBreaks(newBreaks);
    }

    const handleSetSlider = (values, index) => {
        if (index === 0) {
            setStartTime(Math.min(1425, values[0]));
        } else if (index === values.length - 1) {
            setEndTime(values[values.length - 1]);
        } else {
            const newBreaks = Array.from(breaks);
            const bIndex = parseInt((index - 1) / 2);
            if (index % 2 === 0) {
                newBreaks[bIndex].endTime = values[index];
            } else {
                newBreaks[bIndex].startTime = values[index];
            }
            setBreaks(newBreaks);
        }
    }

    const sliderValues = [startTime, ...breaks.reduce((prev, curr) => {return prev.concat([curr.startTime, curr.endTime])}, []), endTime];

    return (
        <div style={{paddingBottom: 4, border: '1px solid lightgray', borderRadius: 6}}>
            <div style={{display: 'flex', gap: 12, alignItems: 'center', padding: 6, borderBottom: '1px solid lightgray'}}>
                <span>Zoom</span>
                <Form.Range value={zoom} onChange={(event) => {setZoom(event.target.value)}} step={0.1} min={1} max={2}/>
            </div>
            <div ref={timelineRef} style={{height: 600, overflowY: 'scroll', paddingTop: 12, paddingBottom: 16,}}>
                <div style={{height: 2016 * zoom, display: 'flex'}}>
                    <div style={{position: 'relative', width: 94}}>
                        {
                            Array.from(new Array(48).keys()).map((index) => {
                                return (
                                    <span key={index} className='unselectable-text' style={{fontWeight: index % 24 === 0 ? 'bold' : 'normal', position: 'absolute', right: 10, top: -11 + ((index * (2016 / 47.5)) * zoom), fontSize: 15}}>
                                        {moment().startOf('day').add(index * 60, 'minutes').format(index % 24 === 0 ? 'MMM D' : 'h A')}
                                    </span>
                                )
                            })
                        }
                    </div>
                    <ReactSlider 
                        defaultValue={[0, 100]} 
                        min={0}
                        max={60*48 - 30}
                        step={15}
                        minDistance={15}
                        orientation='vertical'
                        value={sliderValues}
                        marks={Array.from(new Array(48).keys()).map(index => index * 60)}
                        onChange={handleSetSlider} 
                        markClassName='custom-slider-mark'
                        
                        trackClassName='custom-slider-track'
                        renderTrack={(props, state) => (
                            <div {...props}>
                                <div style={state.index === 0 || state.index === state.value.length ? {width:2, flex: 1, backgroundColor:  'lightgrey'} : state.index % 2 === 0 ? {width: 4, flex: 1, backgroundColor: 'red'} : {width: 4, flex: 1, backgroundColor: 'var(--bs-primary)'}}/>

                            </div>
                        )}
                        renderThumb={(props, state) => (
                            <div {...props}>
                                <div className='custom-thumb unselectable-text'>
                                    <div>
                                        <span style={{whiteSpace: 'nowrap'}}>
                                            {`${moment().startOf('day').add(state.valueNow, 'minutes').format('h:mm A')} ${state.index === 0 ? 'Start Time' : state.index === state.value.length - 1 ? 'End Time' : state.index % 2 === 0 ? 'Resume Work' : 'Break'}`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}