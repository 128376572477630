/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react'
import { deleteCompanyDocument, getAllCompanyDocuments, getCompanyDocument, updateCompanyDocument } from '../../../services/MyCompanyService'
import PageSpinner from '../../../components/PageSpinner';
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import QuickTable from '../../../components/QuickTable';
import moment from 'moment';
import { getFileIcon, saveFile } from '../../../tools';
import MyCompanyDocumentUpload from './MyCompanyDocumentUpload';
import CustomAlert from '../../../components/CustomAlert';
import { AppGlobals } from '../../../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye, faEyeSlash, faFileSignature, faX } from '@fortawesome/free-solid-svg-icons';
import AlertModal from '../../../components/AlertModals/AlertModal';
import ApiControl from '../../../components/ApiControl';
import SortableTable, { SortableTableCol, SortableTableRow } from '../../../components/SortableTable';


export default function MyCompanyDocuments() {
    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [employeeCount, setEmployeeCount] = useState(0);
    const [showUpload, setShowUpload] = useState(false);

    const [documentToDelete, setDocumentToDelete] = useState(undefined);
    const [documentToToggleIsRequired, setDocumentToToggleIsRequired] = useState(undefined);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getAllCompanyDocuments();
        if (response.status === 200) {
            setDocuments(response.documents);
            setEmployeeCount(response.employeeCount);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    const handleDeleteDocument = async () => {
        const response = await deleteCompanyDocument(documentToDelete);
        if (response.status === 200) {
            handleDocumentCrud('delete', documentToDelete);
            return true;
        }
        return false;
    }

    const handleDownloadDocument = async (uid) => {
        const response = await getCompanyDocument(uid);
        if (response.status === 200) {
            saveFile(response.document.name, response.document.base64);
        } else {
            AppGlobals.alert(<CustomAlert type={'danger'} title={response.message ?? 'Failed to Download Document'}/>)
        }
    }

    const handleToggleDocumentIsRequired = async () => {
        const document = {...documentToToggleIsRequired, isRequired: documentToToggleIsRequired.isRequired ? 0 : 1};
        const response = await updateCompanyDocument(document);
        if (response.status === 200) {
            handleDocumentCrud('update', document);
            return true;
        }
        return false;
    }

    const handleDocumentCrud = (type, data) => {
        let newArr = Array.from(documents);
        if (type === 'create') {
            newArr.push(data);
        } else if (type === 'update') {
            newArr = newArr.filter(t => t.uid != data.uid);
            newArr.push(data);
        } else if (type === 'delete') {
            newArr = newArr.filter(t => t.uid != data);
        }
        setDocuments(newArr);
        setShowUpload(false);
    }




    const columns = [
        new SortableTableCol('name', 'Name', null, (a, b) => {return a > b ? 1 : a < b ? -1 : 0}, null),
        new SortableTableCol('type', 'Type', null, (a, b) => {return a > b ? 1 : a < b ? -1 : 0}, null),
        new SortableTableCol('dateCreated', 'Date Created', 1, (a, b) => {return (a > b ? 1 : a < b ? -1 : 0)}, true),
        new SortableTableCol('acknowledgements', 'Acknowledgements', 1, null, null),
        new SortableTableCol('actions', 'Actions', 1, null, null),
    ];


    const documentRows = documents.map((d) => {

        const acknowledgementElements = d.acknowledgements.map((ack) => {
            return (
                <li key={ack.uid}>{`${ack.user.lastName}, ${ack.user.firstName} ${ack.user.middleName} (${moment(ack.date).format('MMM D, YYYY')})`}</li>
            )
        })

        return new SortableTableRow(
            {
                name: d.name,
                type: d.type,
                dateCreated: d.dateCreated,
            }, 
            (
                <tr key={d.uid} style={{verticalAlign: 'middle'}}>
                    <td>
                        <ApiControl value={d.name} maxLength={100} api={async (value) => {return await updateCompanyDocument({...d, name: value})}} setValue={(value) => {handleDocumentCrud('update', {...d, name: value})}}/>
                    </td>
                    <td>
                        <FontAwesomeIcon icon={getFileIcon(d.type)} style={{color: 'var(--bs-primary)', marginRight: 4}}/>
                        <span>{d.type}</span>
                    </td>
                    <td>{moment(d.dateCreated).format('MMM D, YYYY')}</td>
                    <td>
                        { (d.isRequired || d.acknowledgements.length > 0) ?
                            <OverlayTrigger overlay={
                                <Popover style={{position: 'fixed'}}>
                                    <Popover.Body>
                                        <ul style={{margin: 0}}>
                                            {acknowledgementElements}
                                        </ul>
                                    </Popover.Body>
                                </Popover>
                            }>
                                <Button variant='outline-primary'>
                                    {`${d.acknowledgements.length} / ${employeeCount}`}
                                </Button>
                            </OverlayTrigger>
                        :
                            <span style={{opacity: 0.5}}>N/A</span>
                        }
                    </td>
                    <td>
                        <div style={{display: 'flex', gap: 12, justifyContent: 'flex-end'}}>
                            { d.type === 'pdf' &&
                                <Button variant='outline-primary' onClick={() => {setDocumentToToggleIsRequired(d)}}>
                                    <FontAwesomeIcon icon={faFileSignature}/>
                                </Button>
                            }
                            <Button variant='outline-primary' onClick={() => {handleDownloadDocument(d.uid)}}>
                                <FontAwesomeIcon icon={faDownload}/>
                            </Button>
                            <Button variant='outline-danger' onClick={() => {setDocumentToDelete(d.uid)}}>
                                <FontAwesomeIcon icon={faX}/>
                            </Button>
                        </div>
                    </td>
                </tr>
            )
        )
    });

    return isLoading ? <PageSpinner/> : (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
            <Button variant='outline-primary' style={{alignSelf: 'flex-end'}} onClick={() => {setShowUpload(true)}}>Upload Document</Button>
            <SortableTable columns={columns} rows={documentRows} bordered striped size='sm'/>
            <Modal show={showUpload} onHide={() => {setShowUpload(false)}}>
                <MyCompanyDocumentUpload handleDocumentCrud={handleDocumentCrud}/>
            </Modal>
            <AlertModal 
                show={documentToDelete !== undefined} 
                onHide={() => {setDocumentToDelete(undefined)}} 
                title='Delete Document?' 
                message={'This cannot be undone.'} 
                buttonLabel={'Delete Document'} 
                callBack={handleDeleteDocument}
            />
            <AlertModal 
                show={documentToToggleIsRequired !== undefined} 
                onHide={() => {setDocumentToToggleIsRequired(undefined)}} 
                title={documentToToggleIsRequired?.isRequired ? 'Stop Requiring Acknowledgement?' : 'Require Employees to Acknowledge?'}
                message={documentToToggleIsRequired?.isRequired ? 'Employee acknowledgements will still be viewable.' : 'When logging in to the website, employees will be required to acknowledge this document before proceeding.'} 
                buttonLabel={documentToToggleIsRequired?.isRequired ? 'Stop Requiring Acknowledgement' : 'Require Employees to Acknowledge'} 
                callBack={handleToggleDocumentIsRequired}
            />
        </div>
    )

}