/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import { formatTime, intToTime } from "../../../tools";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

export default function SchedulingTableShiftCard({shift, employee, shiftType, handleSelectShift, conflicts, chronological}) {

    let conflictSeverity = conflicts.length > 0 ? 1 : 0;

    for (let i = 0; i < conflicts.length; i++) {
        if (conflicts[i] === 'Shift') {
            conflictSeverity = 3;
            break;
        } else if (conflicts[i] === 'Approved Time Off' && conflictSeverity < 2) {
            conflictSeverity = 2;
        }
    }

    const conflictElements = conflicts.map((c, index) => {
        return (
            <li key={index}>{c}</li>
        )
    })

    return (
        <div className="cursor-pointer" style={{position: 'relative', display: 'flex', width: '100%'}} onClick={() => {handleSelectShift(shift)}}>
            <div style={{width: 4, backgroundColor: `#${shiftType.color}`}}/>
            <div style={{fontWeight: 'normal', borderTop: `1px solid #${shiftType.color}`, borderBottom: `1px solid #${shiftType.color}`, borderRight: `1px solid #${shiftType.color}`, borderTopRightRadius: 6, borderBottomRightRadius: 6, flex: 1, display: 'flex', flexDirection: 'column', width: 0}}>
                <span style={{fontSize: 14, fontWeight: 'bold', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{`${employee.lastName}, ${employee.firstName} ${employee.middleName}`}</span>
                { chronological && 
                    <span style={{fontSize: 14, opacity: 0.5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{shiftType.title}</span>
                }
                <span style={{fontSize: 12, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                    {shift.startTime ? `${intToTime(shift.startTime)} - ${intToTime(shift.endTime)}` : 'All Day'}
                </span>
            </div>
            { conflictSeverity > 0 &&
                <div style={{position: 'absolute', right: 4}}>
                    <OverlayTrigger overlay={
                        <Popover style={{maxWidth: 'none', position: 'fixed'}}>
                            <Popover.Header style={{whiteSpace: 'nowrap'}}>Shift Conflicts With 1 or More Items!</Popover.Header>
                            <Popover.Body>
                                <ul style={{marginBottom: 0}}>
                                    {conflictElements}
                                </ul>
                            </Popover.Body>
                        </Popover>
                    }>
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{color: conflictSeverity === 1 ? 'gold' : conflictSeverity === 2 ? 'orange' : 'red'}}/>
                    </OverlayTrigger>
                </div>
            }
        </div>
    )
}