/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { validateDecimal, validateInteger } from '../tools';
import { AppGlobals } from '../App';
import CustomAlert from './CustomAlert';
import { Spinner } from 'react-bootstrap';

export default function ApiControl({type, title, value, setValue, api, disabled, validation, maxLength, maxInt, maxDecimal}) {
    const [isSaving, setIsSaving] = useState(false);
    const [localValue, setLocalValue] = useState(value === undefined || value === null ? '' : value);

    const onBlur = async () => {
        if (value !== localValue && (!validation || validation(localValue))) {
            setIsSaving(true);
            const response = await api(localValue);
            if (response.status === 200) {
                setValue(localValue);
            } else {
                AppGlobals.alert(<CustomAlert type='danger' title='Failed to save changes!'/>);
                setLocalValue(value);
            }
            setIsSaving(false);
        } else if (value !== localValue) {
            setLocalValue(value);
        }
    }

    const handleSetValue = (value) => {

        if (maxLength) {
            value = value.slice(0, maxLength);
        } else if (maxInt && validateInteger(value) > maxInt) {
            value = maxInt.toString();
        } else if (maxDecimal && validateDecimal(value) > maxDecimal) {
            value = maxDecimal.toString();
        }
        setLocalValue(value);
    }

    const content = (
        <div style={{position: 'relative'}}>
            { (isSaving && type === 'color') ?
                <div style={{height: 38, width: 48, border: '1px solid lightgray', borderRadius: 6, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spinner style={{color: 'var(--bs-primary)'}} size='sm'/>
                </div>
                :
                <Form.Control 
                    onBlur={onBlur} 
                    // isInvalid={validator && !validator.isValid()} 
                    // isValid={validator && validator.isValid()} 
                    disabled={disabled || isSaving} 
                    type={type} 
                    placeholder={''} 
                    value={type === 'color' ? `#${localValue}` : localValue} 
                    onChange={(event) => {handleSetValue(type === 'color' ? event.target.value.substring(1) : event.target.value)}} 
                    style={value !== localValue ? {backgroundColor: '#faffe3'} : {}}
                />
            }
            {/* { validator ? <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> : ''}
            { validator ? <Form.Control.Feedback type='valid'>{validator.getValidMessage()}</Form.Control.Feedback> : ''} */}
            { isSaving && type !== 'color' && 
                <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 6, paddingRight: 10}}>
                    <span style={{fontStyle: 'italic', opacity: 0.5}}>Saving</span>    
                    <Spinner style={{color: 'var(--bs-primary)'}} size='sm'/>
                </div>
            }
            { !isSaving &&

                <div style={{position: 'absolute', fontSize: 11, top: 0, bottom: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 6, paddingRight: 10}}>
                    { maxLength ?
                        <div>
                            <span>{localValue?.length ?? 0}</span>
                            <span>/</span>
                            <span>{maxLength}</span>
                        </div>
                        : maxInt ?
                            <span style={{color: validateInteger(localValue) == maxInt ? 'red' : 'black'}}>{`max: ${maxInt}`}</span>
                        : maxDecimal ?
                            <span style={{color: validateDecimal(localValue) == maxDecimal ? 'red' : 'black'}}>{`max: ${maxDecimal}`}</span>
                        :
                            ''
                    }
                </div>
            }
            {/* <div style={{fontSize: 11, position: 'absolute', right: 8, top: 40}}>
                { maxLength ?
                    <div>
                        <span>{value?.length ?? 0}</span>
                        <span>/</span>
                        <span>{maxLength}</span>
                    </div>
                    : maxInt ?
                        <span style={{color: validateInteger(value) == maxInt ? 'red' : 'black'}}>{`max: ${maxInt}`}</span>
                    : maxDecimal ?
                        <span style={{color: validateDecimal(value) == maxDecimal ? 'red' : 'black'}}>{`max: ${maxDecimal}`}</span>
                    :
                        ''
                }
            </div> */}
        </div>
    )

    return content;
}