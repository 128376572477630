/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { validateDecimal, validateInteger } from '../tools';

function CustomControl({type, title, originalValue, value, setValue, disabled, validator, maxLength, maxInt, maxDecimal, floatingLabel, style, wrapperStyle, onBlur, min, max}) {

    const handleSetValue = (value) => {

        if (maxLength) {
            value = value.slice(0, maxLength);
        } else if (maxInt && validateInteger(value) > maxInt) {
            value = maxInt.toString();
        } else if (maxDecimal && validateDecimal(value) > maxDecimal) {
            value = maxDecimal.toString();
        }
        setValue(value);
    }

    const content = (
        <>
            <Form.Control 
                onBlur={onBlur}
                isInvalid={validator && !validator.isValid()} 
                isValid={validator && validator.isValid()} 
                disabled={disabled}
                type={type} 
                min={min}
                max={max}
                placeholder={title} 
                value={value === undefined || value === null ? '' : value} 
                onChange={(event) => {handleSetValue(event.target.value)}} 
                style={originalValue !== undefined && originalValue !== null && value && value.toString() !== originalValue.toString() ? {backgroundColor: '#faffe3', ...style} : style}
            />
            { validator ? <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> : ''}
            { validator ? <Form.Control.Feedback type='valid'>{validator.getValidMessage()}</Form.Control.Feedback> : ''}
            <div style={{position: 'absolute', fontSize: 11, bottom: 2, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 6, paddingRight: 10}}>
                { maxLength ?
                    <div>
                        <span>{value?.length ?? 0}</span>
                        <span>/</span>
                        <span>{maxLength}</span>
                    </div>
                    : maxInt ?
                        <span style={{color: validateInteger(value) == maxInt ? 'red' : 'black'}}>{`max: ${maxInt}`}</span>
                    : maxDecimal ?
                        <span style={{color: validateDecimal(value) == maxDecimal ? 'red' : 'black'}}>{`max: ${maxDecimal}`}</span>
                    :
                        ''
                }
            </div>
        </>
    )

    return floatingLabel ? <FloatingLabel label={title} style={{position: 'relative', flex: 1,  ...wrapperStyle}}>{content}</FloatingLabel> : <div style={{position: 'relative', flex: 1,  ...wrapperStyle}}>{content}</div>;
}

export default CustomControl;