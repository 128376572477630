/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';


export default function TextAreaControl({value, label, setValue, disabled, rows, originalValue, max, validator}) {
    const [touched, setTouched] = useState(false);


    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control 
                as='textarea' 
                maxLength={max} 
                disabled={disabled} 
                rows={rows} 
                value={value} 
                onChange={(event) => {setValue(event.target.value)}} 
                onBlur={() => {setTouched(true)}} 
                isInvalid={validator && !validator.isValid()} 
                isValid={validator && validator.isValid()} 
                style={{backgroundColor: originalValue !== undefined && originalValue !== value ? '#faffe3' : ''}}
            />
            { validator ? <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> : ''}
            { validator ? <Form.Control.Feedback type='valid'>{validator.getValidMessage()}</Form.Control.Feedback> : ''}
        </Form.Group>
    )
}