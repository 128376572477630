/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';

export default function CustomButton({style, isLoading, label, onClick, disabled, type, variant = 'outline-primary'}) {
    return (
        <Button type={type} disabled={disabled || isLoading} variant={variant} style={style} onClick={onClick}>
            {isLoading ? <Spinner size='sm'/> : label}
        </Button>
    );
}
