/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from "react-bootstrap/Modal";
import CustomControl from '../../components/CustomControl';
import { Validator, validateExistence, ValidationGroup } from '../../validation';
import Button from 'react-bootstrap/Button';
import { requestOff } from '../../services/DaysOffService';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import CustomButton from '../../components/CustomButton';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';

export default function DaysOffRequestModal({hideModal, loadData, requestOffHistory, otherApprovals}) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [notes, setNotes] = useState('');
    const [requestOffError, setRequestOffError] = useState('');
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);

    const validationGroup = new ValidationGroup();

    const handleSubmit = async () => {
        setIsLoadingRequest(true);
        if (requestOffError) {
            setRequestOffError(false);
        }

        const scheduleItem = {
            startDate: startDate,
            endDate: endDate,
            notes: notes,
        };

        const momentStart = moment(startDate);
        const momentEnd = moment(endDate);
        for(let i = 0; i < requestOffHistory.length; i++) {
            if(momentStart.isBetween(moment(requestOffHistory[i].startDate), moment(requestOffHistory[i].endDate),'days','[]') || momentEnd.isBetween(moment(requestOffHistory[i].startDate), moment(requestOffHistory[i].endDate),"days","[]")){
                setRequestOffError(true);
                setIsLoadingRequest(false);
                return;
            }
        }

        const response = await requestOff(scheduleItem);

        if (response.status == '200') {
            loadData();
            hideModal();
        }
        
        setIsLoadingRequest(false);
    }

    const disabledDates = requestOffHistory.reduce((prev, curr) => {
        const dates = [];
        const numberOfDays = moment(curr.endDate).diff(curr.startDate, 'days') + 1;
        for (let i = 0; i < numberOfDays; i++) {
            dates.push(moment(curr.startDate).add(i, 'days').format('YYYY-MM-DD'));
        }
        return prev.concat(dates);
    }, []);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Request Off</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                         <CustomDatePicker 
                            validator={validationGroup.createValidator(() => validateExistence(endDate))} 
                            title={'Date Range'} 
                            startDate={startDate} 
                            setStartDate={setStartDate} 
                            endDate={endDate} 
                            setEndDate={setEndDate} 
                            disabledDates={disabledDates} 
                            minDate={moment().add(1, 'day').format('YYYY-MM-DD')}
                            />
                        </Col>
                        <Col xxl={12}>
                            <Form.Group>
                                <Form.Label>Reason</Form.Label>
                                <Form.Control as='textarea' maxLength={65535} rows={4} value={notes} onChange={(event) => {setNotes(event.target.value)}}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <span style={{color:'red'}}>{requestOffError && 'Date range must not include date previously requested off'}{endDate && startDate && endDate < startDate && 'Start date cannot be after end date'}</span>
                <CustomButton onClick={handleSubmit} disabled={!validationGroup.isValid() || endDate < startDate} isLoading={isLoadingRequest} label={'Submit'}/>
    
            </Modal.Footer>
        </>
    )
}