/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import HRUserSchedulePTODaySelector from './HRUserSchedulePTODaySelector';
import CustomButton from '../../../components/CustomButton';
import HRTimeOffModal from './HRTimeOffModal';
import KeyValueRow from '../../../components/KeyValueRow';
import { rejectRequestOff } from '../../../services/HumanResourcesService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export default function HRSchedulePendingRequest({selectedTimeOff, handleAnswerRequest}) {
    
    const [isLoading, setIsLoading] = useState(false);
    const [showDaySelector, setShowDaySelector] = useState(false);
    const [showTimeOffModal, setShowTimeOffModal] = useState(false);    

    const handleRejectRequest = async () => {
        setIsLoading(true);
        const response = await rejectRequestOff(selectedTimeOff.uid);
        if (response.status === 200) {
            handleAnswerRequest(selectedTimeOff.uid, -1);
        }
        setIsLoading(false);
    } 


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Pending Time Off Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button style={{marginBottom: 12}} variant='outline-primary' onClick={() => {setShowTimeOffModal(true)}}>View All Upcoming Time Off</Button>
                <ListGroup>
                    <KeyValueRow isListItem title='Date Range' value={`${moment(selectedTimeOff.startDate).format('MMM D, YYYY')} - ${moment(selectedTimeOff.endDate).format('MMM D, YYYY')}`}/>
                    <KeyValueRow isListItem title='Submission Date' value={moment(selectedTimeOff.submissionDate).format('MMM D, YYYY')}/>
                    <KeyValueRow isListItem title='Notes' value={selectedTimeOff.notes}/>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                { selectedTimeOff.startDate > moment().format('YYYY-MM-DD') ?
                    <>
                        <Button variant={'outline-primary'} onClick={() => {setShowDaySelector(true)}}>Accept Request</Button>
                        <CustomButton label='Reject Request' isLoading={isLoading} onClick={handleRejectRequest}/>
                    </>
                    :
                    <>
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'gold'}}/>
                        <span>This request can no longer be accepted. To manually schedule time off, click the <b>Schedule Time Off</b> button on the <b>Human Resources</b>-<b>Schedule</b> page.</span>
                    </>
                }
            </Modal.Footer>
                
            <Modal show={showDaySelector} onHide={() => {setShowDaySelector(false)}}>
                <HRUserSchedulePTODaySelector selectedTimeOff={selectedTimeOff} handleCreatePto={() => {setShowDaySelector(false); handleAnswerRequest(selectedTimeOff.uid, 1)}}/>
            </Modal>
            <Modal show={showTimeOffModal} onHide={() => {setShowTimeOffModal(false)}} size='lg'>
                <HRTimeOffModal/>
            </Modal>
        </>
    )
}