/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Button, Modal } from "react-bootstrap";
import CustomButton from "../CustomButton";
import { stopShadowing } from "../../services/AdminService";
import { AppGlobals } from "../../App";
import { useState } from "react";
import CustomAlert from "../CustomAlert";

export default function StopShadowingModal({}) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleStopShadowing = async () => {
        setIsSubmitting(true);
        const response = await stopShadowing();
        if (response.status === 200) {
            window.location.reload();
        } else {
            AppGlobals.alert(<CustomAlert type='danger' title={response.message}/>);
        }
        setIsSubmitting(false);
    }


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Stop Shadowing?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Upon submission, You will be navigated back to the Admin Dashboard
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isSubmitting} onClick={handleStopShadowing} label={'Stop Shadowing'}/>
            </Modal.Footer>
        </>
    )
}