import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap';
import MyCompanyProfile from './MyCompanyProfile';
import MyCompanyDocuments from './MyCompanyDocuments/MyCompanyDocuments';
import MyCompanyLocations from './MyCompanyLocations/MyCompanyLocations';
import MyCompanyDevices from './MyCompanyDevices';

export default function MyCompany() {

  const [activeTab, setActiveTab] = useState('Profile & Settings');

  const tabs = {
      'Profile & Settings': <MyCompanyProfile/>,
      'Locations': <MyCompanyLocations/>,
      'Devices': <MyCompanyDevices/>,
      'Documents': <MyCompanyDocuments/>,

  };

  const tabButtons = Object.keys(tabs).map((key) => {
    return (
        <Button 
            key={key}
            style={activeTab=== key ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} 
            onClick={() => setActiveTab(key)}
            >
            {key}
        </Button>
    )
});

  return (
      <div className="page-wrapper" style={{padding: 12, flexDirection: 'column'}}>
        <ButtonGroup>
            {tabButtons}
        </ButtonGroup>
        <div style={{display: 'flex', gap: 20, margin: 'auto', marginTop: 12, alignItems: 'center'}}>

        </div>
        <div style={{height: '100%', margin: '12px 0px 0px 12px',  overflowY: 'scroll'}}>
            <div style={{height: '100%', marginRight: 12}}>
                {tabs[activeTab]}
            </div>
        </div>
      </div>
  )
}
