import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomControl from '../../components/CustomControl';
import { AddressValidator, ValidationGroup, validateEmail, validateExistence, validateGreaterThanZero, validatePhoneNumber, validateSsn } from '../../validation';
import EmergencyContact from '../../components/EmergencyContact';
import Picker from '../../components/Picker';
import ChildSupportControl from '../../components/ChildSupportControl';
import QuickTable from '../../components/QuickTable';
import { getHireInfo, hireNewEmployee } from '../../services/HumanResourcesService';
import { validateDecimal } from '../../tools';
import AvailabilityControl from '../../components/AvailabilityControl';
import RadioControl from '../../components/RadioControl';
import AddressControl from '../../components/AddressControl';
import moment from 'moment';
import DropdownControl from '../../components/DropdownControl';
import CustomButton from '../../components/CustomButton';
import SwitchControl from '../../components/SwitchControl';
import PageSpinner from '../../components/PageSpinner';
import { AppGlobals } from '../../App';
import CustomAlert from '../../components/CustomAlert';
import { validateUsd } from '../Payroll/payrollTools';
import DocumentRequestModal from './HRDocuments/DocumentRequestModal';

export default function HumanResourcesHire({loadHub, employees, permissionsTemplate, locations}) {

    const defaultPermissions = {}; 
    permissionsTemplate.forEach(p => defaultPermissions[p.key] = 0);

    const [showOnboarding, setShowOnboarding] = useState(false);
    const [sendOnboardingDocuments, setSendOnboardingDocuments] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userIdentifier, setUserIdentifier] = useState(null);
    const [hireEmployee, setHireEmployee] = useState({pay: { childSupport: []}, permissions: defaultPermissions, availability: '0000000', address: {}, emergencyContacts: []});
    const [contactsIsValid, setContactsIsValid] = useState(true);
    const [responseError, setResponseError] = useState(null);

    const validationGroup = new ValidationGroup();
    const addressValidator = new AddressValidator(hireEmployee?.address);


    async function handleSubmit(){
        setIsSubmitting(true);
        const entireEmployeeToSend = {
            ...hireEmployee, 
            pay: 
            {...hireEmployee.pay, 
                dateEffective: hireEmployee.startDate, 
                childSupport: JSON.stringify(hireEmployee.pay.childSupport),
                vision: validateUsd(hireEmployee.pay.vision),
                medical: validateUsd(hireEmployee.pay.medical),
                dental: validateUsd(hireEmployee.pay.dental),
                payRate: validateUsd(hireEmployee.pay.payRate)
            }
        }

        const response = await hireNewEmployee(entireEmployeeToSend);
        if (response.status === 200) {
            setUserIdentifier(response.userIdentifier);
            if(sendOnboardingDocuments){
                setShowOnboarding(true)
            }else{
                loadHub();
            }
        } else {
            setResponseError(response.message);
        }
        setIsSubmitting(false);
    }

    function handleSetHireEmployee(key, value){
        const newHireEmployee = structuredClone(hireEmployee)
        newHireEmployee[key] = value;
        setHireEmployee(newHireEmployee);
    }

    function handleSetHireEmployeePay(key, value){
        const newHireEmployee = structuredClone(hireEmployee)
        newHireEmployee['pay'][key] = value;
        setHireEmployee(newHireEmployee);
    }

    function handleSetHireEmployeePermissionsTemplate(template){
        const newHireEmployee = structuredClone(hireEmployee)
        permissionsTemplate.map(p=>newHireEmployee['permissions'][p.key] = parseInt(p[template]));
        setHireEmployee(newHireEmployee);
    }

    function handleSetContact(key, value, index){
        const newHireEmployee = structuredClone(hireEmployee);
        const newContact = newHireEmployee['emergencyContacts'][index];
        newContact[key] = value;
        setHireEmployee(newHireEmployee);
    }
  
      function handleAddEmergencyContacts(){
        const newHireEmployee = structuredClone(hireEmployee);
        newHireEmployee['emergencyContacts'] = [...hireEmployee.emergencyContacts, {firstName: '', lastName: '', phoneNumber: '', email: '', relationship: '', address: undefined}];
        setHireEmployee(newHireEmployee);
    }
  
      function handleDeleteContact(deleteIndex){
        const newHireEmployee = structuredClone(hireEmployee);
        const newContacts = newHireEmployee['emergencyContacts'].toSpliced(deleteIndex, 1);
        newHireEmployee['emergencyContacts'] = newContacts;
        setHireEmployee(newHireEmployee);
    }

    const handlePermissionChange = (permissionKey) => {
        const newHireEmployee = structuredClone(hireEmployee);
        newHireEmployee['permissions'][permissionKey] = newHireEmployee['permissions'][permissionKey] ? 0 : 1;
        setHireEmployee(newHireEmployee);
    }

    const permissionRows = permissionsTemplate?.map((p) => {
        return (
            <tr key={p.key}>
                <td>{p.label}</td>
                <td>{p.description}</td>
                <td>
                    <Form.Check type='switch' checked={hireEmployee.permissions[p.key] ?? false} onChange={()=> handlePermissionChange(p.key)}/>
                </td>
            </tr>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Hire New Employee</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                        <Col lg={12}>
                            <p style={{fontWeight: 'bold', fontSize: 24, width: '100%', textAlign: 'left'}}>Personal Information</p>
                        </Col>
                        <Col lg={4}>
                            <CustomControl
                                maxLength={30} 
                                type='text' 
                                title='First Name'
                                floatingLabel={true} 
                                style={{marginBottom: 10}}
                                value={hireEmployee.firstName}
                                setValue={(value) => {handleSetHireEmployee('firstName', value)}}
                                validator={validationGroup.createValidator(() => validateExistence(hireEmployee.firstName))}
                                />
                        </Col>
                        <Col lg={4}>
                            <CustomControl
                                maxLength={30} 
                                type='text' 
                                title='Middle Name'
                                floatingLabel={true} 
                                style={{marginBottom: 10}}
                                value={hireEmployee.middleName}
                                setValue={(value) => {handleSetHireEmployee('middleName', value)}}
                                validator={validationGroup.createValidator(() => validateExistence(hireEmployee.middleName))}
                                />
                        </Col>
                        <Col lg={4}>
                            <CustomControl
                                maxLength={30} 
                                type='text' 
                                title='Last Name'
                                floatingLabel={true} 
                                style={{marginBottom: 10}}
                                value={hireEmployee.lastName}
                                setValue={(value) => {handleSetHireEmployee('lastName', value)}}
                                validator={validationGroup.createValidator(() => validateExistence(hireEmployee.lastName))}
                                />
                        </Col>
                        <Col lg={4}>
                            <CustomControl
                                maxLength={50} 
                                type='text' 
                                title='Email Address'
                                floatingLabel={true} 
                                style={{marginBottom: 10}}
                                value={hireEmployee.email}
                                setValue={(value) => {handleSetHireEmployee('email', value)}}
                                validator={validationGroup.createValidator(() => validateEmail(hireEmployee.email))}
                                />
                        </Col>
                        <Col lg={4}>
                            <CustomControl
                                maxLength={10} 
                                type='number' 
                                title='Phone Number'
                                floatingLabel={true} 
                                style={{marginBottom: 10}}
                                value={hireEmployee.phoneNumber}
                                setValue={(value) => {handleSetHireEmployee('phoneNumber', value)}}
                                validator={validationGroup.createValidator(() => validatePhoneNumber(hireEmployee.phoneNumber))}
                                />
                        </Col>
                        <Col lg={4}>
                            <CustomControl
                                type='date' 
                                title='Date of Birth'
                                floatingLabel={true} 
                                style={{marginBottom: 10}}
                                value={hireEmployee.dateOfBirth}
                                setValue={(value) => {handleSetHireEmployee('dateOfBirth', value)}}
                                validator={validationGroup.createValidator(() => validateExistence(hireEmployee.dateOfBirth))}
                                />
                        </Col>
                        <Col lg={12}>
                            <CustomControl
                                maxLength={9}
                                type='number' 
                                title='Social Security Number'
                                floatingLabel={true} 
                                style={{marginBottom: 10}}
                                value={hireEmployee.ssn}
                                setValue={(value) => {handleSetHireEmployee('ssn', value)}}
                                validator={validationGroup.createValidator(() => validateSsn(hireEmployee.ssn))}
                                />
                        </Col>
                        <AddressControl validator={addressValidator} title='Address' lg={4} address={hireEmployee.address} setAddress={((value) => {handleSetHireEmployee('address', value)})}/>
                    </Row>
                    <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                        <Col lg={12}>
                            <p style={{fontWeight: 'bold', fontSize: 24, width: '100%', textAlign: 'left'}}>Emergency Contacts</p>
                                {hireEmployee.emergencyContacts && 
                                hireEmployee.emergencyContacts.map((contact, i)=>{
                                    return <EmergencyContact setContactsIsValid={setContactsIsValid} key={i} contact={contact} index={i} handleDeleteContact={handleDeleteContact} handleSetContact={handleSetContact}/>
                                })
                            }
                            <Button onClick={handleAddEmergencyContacts} style={{marginTop: 10}} variant={'outline-primary'}>Add Emergency Contact</Button>
                        </Col>
                    </Row>
                    <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                        <Col lg={12}>
                            <p style={{fontWeight: 'bold', fontSize: 24, width: '100%', textAlign: 'left'}}>Employment Details</p>
                        </Col>
                        <Col lg={12} style={{marginBottom: 12}}>
                                <CustomControl maxLength={50} floatingLabel title='Employee Id' value={hireEmployee.employeeId} setValue={(value) => {handleSetHireEmployee('employeeId', value)}} validator={validationGroup.createValidator(() => validateExistence(hireEmployee.employeeId))}/>
                        </Col>
                        <Col lg={12} style={{marginBottom: 18}} >
                            <DropdownControl
                                title='Direct Supervisor' 
                                value={hireEmployee.supervisor} 
                                setValue={(value) => {handleSetHireEmployee('supervisor', value)}} 
                                itemNames={employees.map(e => e.firstName + ' ' + e.lastName)} 
                                itemValues={employees.map(e=> e.uid)}
                                />
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 12}}>
                            <CustomControl maxLength={40} floatingLabel title='Title' value={hireEmployee.pay.title} setValue={(value) => {handleSetHireEmployeePay('title', value)}} validator={validationGroup.createValidator(() => validateExistence(hireEmployee.pay.title))}/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 12}}>
                            <Picker title='Employee Type' selection={hireEmployee.pay.fulltime} setSelection={(value) => {handleSetHireEmployeePay('fulltime', value)}} validator={validationGroup.createValidator(() => validateExistence(hireEmployee.pay.fulltime))} itemValues={[1, 0]} itemNames={['Full-Time', 'Part-Time']}/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 12}}>
                            <Picker validator={validationGroup.createValidator(() => validateExistence(hireEmployee.pay.payType))} title='Pay Type' selection={hireEmployee.pay.payType} setSelection={(value) => {handleSetHireEmployeePay('payType', value)}}  itemValues={[0, 1, 2]} itemNames={['Per Hour', 'Salaried (Non-Exempt)', 'Salaried (Exempt)']}/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 12}}>
                            <CustomControl floatingLabel maxDecimal={999999.99} validator={validationGroup.createValidator(() => validateGreaterThanZero(hireEmployee.pay.payRate))} title='Pay Rate' value={hireEmployee.pay.payRate} setValue={(value) => {handleSetHireEmployeePay('payRate', value)}} type='number'/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 12}}>
                            <Picker title='PTO Accrual Type' selection={hireEmployee.pay.ptoAccrualType} setSelection={(value) => {handleSetHireEmployeePay('ptoAccrualType', value)}} itemValues={[0, 1]} itemNames={['Per 40 Hours', 'Per Pay Period']}/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 12}}>
                            <CustomControl floatingLabel maxDecimal={99.999} title={`PTO Accrual ${hireEmployee.pay.ptoAccrualType == 0 ? 'Per 40 Hours' : 'Per Pay Period'}`} value={hireEmployee.pay.ptoAccrual ? hireEmployee.pay.ptoAccrual : ''} setValue={(value) => {handleSetHireEmployeePay('ptoAccrual', validateDecimal(value))}} type='number'/>
                        </Col>
                        <Col xl={12} style={{marginBottom: 12}}>   
                            <CustomControl floatingLabel validator={validationGroup.createValidator(() => validateExistence(hireEmployee.startDate))} type='date' title='Start Date' value={hireEmployee.startDate} setValue={(value) => {handleSetHireEmployee('startDate', value)}}/>
                        </Col>
                        <RadioControl 
                            lg={6}
                            title='Location' 
                            inline
                            selection={hireEmployee.locationIdentifier} 
                            setSelection={(value) => handleSetHireEmployee('locationIdentifier', value)} 
                            optionNames={locations?.map(l=>l.name)} 
                            optionValues={locations?.map(l=>l.uid)} 
                            validator={validationGroup.createValidator(() => validateExistence(hireEmployee.locationIdentifier))}
                            />
                        <AvailabilityControl 
                            lg={6}
                            title='Availability' 
                            inline 
                            value={hireEmployee.availability}
                            setValue={(value)=>{handleSetHireEmployee('availability', value)}}
                            />
                        <Col lg={6} xl={4} style={{marginBottom: 12, marginTop: 8}}>
                            <CustomControl maxDecimal={9999.99} floatingLabel title='Medical Insurance' value={hireEmployee.pay.medical} setValue={(value) => {handleSetHireEmployeePay('medical', value)}} type='number'/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 12, marginTop: 8}}>
                            <CustomControl maxDecimal={9999.99} floatingLabel title='Dental Insurance' value={hireEmployee.pay.dental} setValue={(value) => {handleSetHireEmployeePay('dental', value)}} type='number'/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 12, marginTop: 8}}>
                            <CustomControl maxDecimal={9999.99} floatingLabel title='Vision Insurance' value={hireEmployee.pay.vision} setValue={(value) => {handleSetHireEmployeePay('vision', value)}} type='number'/>
                        </Col>   
                        <ChildSupportControl value={hireEmployee.pay.childSupport} setValue={(value)=>handleSetHireEmployeePay('childSupport', value)}/>
                    </Row>
                    <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                        <Col lg={12}>
                            <p style={{fontWeight: 'bold', fontSize: 24, width: '100%', textAlign: 'left'}}>Permissions</p>
                        </Col>
                        <div style={{display: 'flex', alignItems: 'center', padding: 12, border: '1px solid lightgray', marginBottom: 16, gap: 18}}>
                            <p style={{fontSize: 16, margin: 0}}>Default Permission Templates</p>
                            <Button onClick={()=>handleSetHireEmployeePermissionsTemplate('associate')} style={{width: 120}} variant='outline-primary'>Associate</Button>
                            <Button onClick={()=>handleSetHireEmployeePermissionsTemplate('manager')}  style={{width: 120}} variant='outline-primary'>Manager</Button>
                            <Button onClick={()=>handleSetHireEmployeePermissionsTemplate('owner')} style={{width: 120}} variant='outline-primary'>Owner</Button>
                        </div>
                        <QuickTable size='sm' rows={permissionRows} headers={['Permission Title', 'Description', 'On / Off']} widths={[null, null, 1]} noWrap responsive={false}/>     
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {responseError && <p style={{color: 'red'}}>{responseError}</p>}
                    <Form.Check type='switch' label='Send Employee Onboarding Documents?' checked={sendOnboardingDocuments} onChange={() => setSendOnboardingDocuments(!sendOnboardingDocuments)}/>
                    <CustomButton isLoading={isSubmitting} label='Submit' disabled={!validationGroup.isValid() || !addressValidator.isValid() || (hireEmployee.emergencyContacts?.length > 0 && !contactsIsValid)} style={{minWidth: 120}}  onClick={handleSubmit} variant='outline-primary'/>
                </Modal.Footer>
            <Modal show={showOnboarding} onHide={() => window.location.reload()} size='xl'>
                <DocumentRequestModal defaultAllDocumentsOn userIdentifier={userIdentifier} hideModal={() => window.location.reload()}/>
            </Modal>
        </>
    )
}

