/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { bigToUsd, validateDecimal, validateInteger, validateUsd, toFixedMax } from "../payrollTools";
import { usdFormatter } from "../../../tools";
import AdditionalPay from "../Models/AdditionalPay";
import DBonus from "../Models/DBonus";
import Deduction from "../Models/Deduction";
import Big from "big.js";
import PayrollPeriodEntryPayRow from "./PayrollPeriodEntryPayRow";

export default function PayrollPeriodEntryRow(entry) {
    let rows = [];

    const entryColumnInclusion = entry.getColumnInclusion();


    if (entryColumnInclusion.includeOvertimeColumns) {
        entry.weeks.forEach((week, index) => {
            const weekColumnInclusion = week.getColumnInclusion();

            if (weekColumnInclusion.includeOvertimeColumns) {
                const row = {};
                row['Days Worked'] = week.daysWorked;
                row['Hours Worked'] = 40;
                if (entry.weeks.length > 1) {
                    row['Week #'] = index + 1;
                }
                row['Hourly Rate'] = bigToUsd(week.hourlyRate());
                row['Hourly Wages'] = bigToUsd(week.hourlyWages());
                row['Adjusted Hourly Rate'] = bigToUsd(week.adjustedHourlyRate());
                row['Overtime Rate'] = bigToUsd(week.overtimeRate());
                row['Overtime Hours'] = toFixedMax(week.overtimeHoursWorked(), 3);
                row['Overtime Wages'] = bigToUsd(week.overtimeWages());
                rows.push(row);
            } else {
                const newRows = rows.concat(week.pay.map(p => PayrollPeriodEntryPayRow(p)));
                newRows[0]['Days Worked'] = week.daysWorked;
                newRows[0]['Hours Worked'] = toFixedMax(week.hoursWorked, 3);
                if (entry.weeks.length > 1) {
                    newRows[0]['Week #'] = index + 1;
                }
                rows = rows.concat(newRows);
            }
        })
    } else {
        const reducedPay = entry.getReducedPay();

        const newRows = reducedPay.map(p => PayrollPeriodEntryPayRow(p));

        const daysWorked = entry.weeks.reduce((prev, curr) => {
            return prev + curr.daysWorked;
        }, 0);

        if (newRows.length > 0) {
            newRows[0]['Days Worked'] = daysWorked;
            rows = rows.concat(newRows);
        } else {
            rows.push({'Days Worked': daysWorked});
        }
    }

    // if (!entryColumnInclusion.includeEachWeek) {
    //     const row = {};
    //     if (entryColumnInclusion.includeSalary) {
    //         row['Salary'] = usdFormatter.format(entry.weeks[0].payRate);
    //         row['Regular Wages'] = bigToUsd(entry.weeks.reduce((prev, curr) => {
    //             return prev.plus(curr.regularWages());
    //         }, new Big(0.0)));
    //     }
    //     if (entryColumnInclusion.includeHourly) {
    //         row['Hourly Rate'] = usdFormatter.format(entry.weeks[0].payRate);
    //         row['Hourly Wages'] = bigToUsd(entry.weeks.reduce((prev, curr) => {
    //             return prev.plus(curr.hourlyWages());
    //         }, new Big(0.0)));
    //     }
    //     if (entryColumnInclusion.includeOvertimeColumns) {
    //         row['Adjusted Hourly Rate'] = bigToUsd(entry.weeks[0].adjustedHourlyRate());
    //         row['Overtime Rate'] = bigToUsd(entry.weeks[0].overtimeRate());
    //         row['Overtime Hours'] = toFixedMax(entry.weeks[0].overtimeHoursWorked().toNumber(), 3);
    //         row['Overtime Wages'] = bigToUsd(entry.weeks[0].overtimeWages());
    //     }
    //     row['Days Worked'] = entry.weeks.reduce((prev, curr) => {
    //         return prev + curr.daysWorked;
    //     }, 0);
    //     row['Hours Worked'] = toFixedMax(entry.weeks.reduce((prev, curr) => {
    //         return prev + curr.hoursWorked;
    //     }, 0), 3);
    //     rows.push(row);
    // } else {
    //     entry.weeks.forEach((week, index) => {
    //         const weekColumnInclusion = week.getColumnInclusion();
    //         const row = {};
    //         row['Week #'] = index + 1;
    //         if (weekColumnInclusion.includeSalary) {
    //             row['Salary'] = usdFormatter.format(week.payRate);
    //             row['Regular Wages'] = bigToUsd(week.regularWages());
    //         }
    //         if (weekColumnInclusion.includeHourly) {
    //             row['Hourly Rate'] = usdFormatter.format(week.payRate);
    //             row['Hourly Wages'] = bigToUsd(week.hourlyWages());
    //         }
    //         if (weekColumnInclusion.includeOvertimeColumns) {
    //             row['Adjusted Hourly Rate'] = bigToUsd(week.adjustedHourlyRate());
    //             row['Overtime Rate'] = bigToUsd(week.overtimeRate());
    //             row['Overtime Hours'] = bigToUsd(week.overtimeHoursWorked());
    //             row['Overtime Wages'] = bigToUsd(week.overtimeWages());
    //         }
    //         row['Days Worked'] = week.daysWorked;
    //         row['Hours Worked'] = toFixedMax(week.hoursWorked, 3);
    //         rows.push(row);
    //     })
    // }

    const firstRow = rows[0];

    if (entryColumnInclusion.includePto) {
        firstRow['PTO Hours'] = entry.pto();
        firstRow['PTO Wages'] = bigToUsd(entry.weeks.reduce((prev, curr) => {
            return prev.plus(curr.ptoWages());
        }, new Big(0.0)));
    }

    if (entryColumnInclusion.includeHolidayWages) {
        firstRow['Holiday Wages'] = bigToUsd(entry.weeks.reduce((prev, curr) => {
            return prev.plus(curr.holidayWages());
        }, new Big(0.0)));
    }
    

    firstRow['Name'] = entry.name();
    firstRow['Location'] = entry.location.name;

    const ndBonuses = entry.weeks.reduce((prev, curr) => {
        return prev.concat(curr.ndBonuses);
    }, []);

    ndBonuses.forEach((ndBonus, index) => {
        firstRow[`Nondiscretionary Bonus #${index + 1}`] = usdFormatter.format(ndBonus.getAmount());
    })

    DBonus.dBonusTypes.forEach((type) => {
        const totalDbonuses = entry.weeks.reduce((prev, curr) => {
            return prev.concat(curr.dBonuses);
        }, []);
        const total = totalDbonuses.filter(dBonus => dBonus.type === type).reduce((prev, curr) => {
            return prev + curr.getAmount();
        }, 0.0);
        if (total > 0) {
            firstRow[type] = usdFormatter.format(total);
        }
    })

    AdditionalPay.additionalPayTypes.forEach((apType) => {
        entry.getAllAdditionalPay().filter(ap => ap.type === apType).forEach((ap, index) => {
            firstRow[`${apType} #${index + 1}`] = usdFormatter.format(ap.getAmount());
        });
    })

    
    firstRow['Gross'] = bigToUsd(entry.gross());

    for (let i = 0; i < entry.getEnabledLoans().length; i++) {
        firstRow[`Loan #${i + 1}`] = usdFormatter.format(entry.getEnabledLoans()[i].getAmount());
    }

    if (entryColumnInclusion.inlcudeChildSupport) {
        firstRow['Child Support'] = usdFormatter.format(entry.totalChildSupport())
    };
    if (entryColumnInclusion.includeMedical) {
        firstRow['Medical Insurance'] = usdFormatter.format(validateDecimal(entry.medical))
    };
    if (entryColumnInclusion.includeDental) {
        firstRow['Dental Insurance'] = usdFormatter.format(validateDecimal(entry.dental))
    };
    if (entryColumnInclusion.includeVision) {
        firstRow['Vision Insurance'] = usdFormatter.format(validateDecimal(entry.vision))
    };

    Deduction.deductionTypes.forEach((type) => {
        const total = entry.deductions.filter(d => d.type === type).reduce((prev, curr) => {
            return prev + curr.getAmount();
        }, 0.0);
        if (total > 0) {
            firstRow[type] = type === '401K (% of Gross)' || type === 'Other (% of Gross)' ? `${total}%` : usdFormatter.format(total);
        }
    })
        
    return rows;
}