/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CustomControl from "../../../components/CustomControl";
import ApiControl from "../../../components/ApiControl";
import { createShiftType, updateShiftType } from "../../../services/SchedulingService";
import CustomButton from "../../../components/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import ShiftTypeDeleteModal from "./ShiftTypeDeleteModal";


export default function ShiftTypeEditor({shiftTypes, handleSetShiftTypes, loadData}) {
    const [title, setTitle] = useState('');
    const [color, setColor] = useState('#000000');
    const [shiftTypeToDelete, setShiftTypeToDelete] = useState(undefined);
    const [isCreating, setIsCreating] = useState(false);

    const updateShiftTypeApi = async (uid, key, value) => {
        const newShiftType = shiftTypes.find(st => st.uid === uid);
        if (newShiftType) {
            newShiftType[key] = value;
            return await updateShiftType(newShiftType);
        }
    }

    const handleCreateShiftType = async () => {
        setIsCreating(true);
        const shiftType = {
            title: title,
            color: color.substring(1)
        }
        const response = await createShiftType(shiftType);
        if (response.status === 200) {
            const newShiftTypes = Array.from(shiftTypes);
            shiftType.uid = response.uid;
            newShiftTypes.push(shiftType);
            handleSetShiftTypes(newShiftTypes);
        }
        setIsCreating(false);
    }

    const handleSetShiftType = (uid, key, value) => {
        const newShiftTypes = Array.from(shiftTypes);
        newShiftTypes.find(st => st.uid === uid)[key] = value;
        handleSetShiftTypes(newShiftTypes);
    }


    const shiftTypeElements = shiftTypes.map((st) => {
        return (
            <div key={st.uid} style={{display: 'flex', flexDirection: 'row', gap: 6}}>
                <div style={{flex: 1}}>
                    <ApiControl title={'Title'} maxLength={30} value={st.title} api={async (value) => {return await updateShiftTypeApi(st.uid, 'title', value)}} setValue={(value) => {handleSetShiftType(st.uid, 'title', value)}}/>
                </div>
                <ApiControl title={'Color'} type='color' value={st.color} api={async (value) => {return await updateShiftTypeApi(st.uid, 'color', value)}} setValue={(value) => {handleSetShiftType(st.uid, 'color', value)}}/>
                { shiftTypes.length > 1 && 
                    <Button variant="outline-danger" onClick={() => {setShiftTypeToDelete(st.uid)}}><FontAwesomeIcon icon={faTrashAlt}/></Button>
                }
            </div>
        )
    })


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Shift Types</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                {shiftTypeElements}

            </Modal.Body>
            <Modal.Footer>

                <div style={{flex: 1}}>
                    <CustomControl title='Title' value={title} setValue={setTitle} maxLength={30}/>
                </div>
                <div>
                    <CustomControl title='Color' type='color' value={color} setValue={setColor}/>
                </div>

                <CustomButton disabled={!title} isLoading={isCreating} onClick={handleCreateShiftType} label={
                    <>
                        <FontAwesomeIcon icon={faPlus}/>
                        <span style={{marginLeft: 6}}>Add Shift Type</span>
                    </>
                    
                }/>
                
            </Modal.Footer>
            <Modal show={shiftTypeToDelete} onHide={() => {setShiftTypeToDelete(undefined)}}>
                <ShiftTypeDeleteModal shiftTypes={shiftTypes} shiftTypeToDelete={shiftTypeToDelete} loadData={loadData}/>
            </Modal>
        </>
    )
}