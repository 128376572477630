import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Modal, Toast } from "react-bootstrap";
import CustomButton from "../../components/CustomButton";
import { requestReset } from "../../services/ResetPasswordService";
import CustomControl from "../../components/CustomControl";
import { ValidationGroup, validatePhoneNumber } from "../../validation";
import CustomAlert from "../../components/CustomAlert";
import { AppGlobals } from "../../App";


export default function ForgotPassword({hideModal, setAlertContent}){

    const [phoneNumber, setPhoneNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const validationGroup = new ValidationGroup();

    async function handleRequestPasswordReset(){
        setIsLoading(true);
        const response = await requestReset(phoneNumber);
        if(response.status === 200){
            setAlertContent(<CustomAlert type='success' title={response.message}/>)
            hideModal();
        } else {
            setAlertContent(<CustomAlert type='danger' title={response.message}/>)
        }
        setIsLoading(false);
    }
    
    return(
        <>
            <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <CustomControl title='Phone Number' value={phoneNumber} validator={validationGroup.createValidator(()=>validatePhoneNumber(phoneNumber))} setValue={setPhoneNumber} maxLength={10}/>
            </Modal.Body>
            <Modal.Footer>
                <div style={{display: 'flex', alignItems: 'center', gap: 12, color: 'gray'}}>
                    <FontAwesomeIcon icon={faInfoCircle}/>
                    <span>After submitting you will receive a text with a link to reset your password</span>
                </div>
                <CustomButton label='Submit' disabled={!validationGroup.isValid()} isLoading={isLoading} variant={'outline-primary'} onClick={()=>handleRequestPasswordReset()}/>
            </Modal.Footer>
        </>
    )
}