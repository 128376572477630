/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from 'react-router-dom';
import './NavigationBarLink.css';

function NavigationBarLink({title, icon, destination}) {
    return (
        <div style={{paddingRight: 0, paddingLeft: 0}}>
            <NavLink exact className={(isActive) => isActive ? 'nav-bar-link nav-bar-link-active' : 'nav-bar-link'} to={destination}>
                <div style={{width: 25, display: 'flex', justifyContent: 'center'}}>
                    <FontAwesomeIcon icon={icon} style={{marginRight: 8}}/>
                </div>
                <p style={{fontSize: 18, margin: 0, }}> {title}</p>
            </NavLink>
        </div>
        
        
    );
}

export default NavigationBarLink;