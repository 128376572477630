/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'universal-cookie';
import { 
    faUser, 
    faTableColumns, 
    faAddressCard, 
    faClock, 
    faCalendar, 
    faRightFromBracket, 
    faBuilding, 
    faGripLines, 
    faUsers, 
    faToolbox,
    faMoneyCheckAlt,
    faBook,
    faUsersBetweenLines,
    faCaretRight,
    faCaretLeft,
    faBars,
    faFileInvoiceDollar
    
} from '@fortawesome/free-solid-svg-icons';
import { NavLink, NavDropdown, Nav, Navbar, Container, Modal } from 'react-bootstrap';
import logo from '../../assets/logo.png';
import { useState } from 'react';
import LogoutModal from '../NavigationBar/LogoutModal';
import { useHistory, useLocation } from 'react-router-dom';
import StopShadowingModal from '../NavigationBar/StopShadowingModal';

function TopNavigationBar({auth}) {
    const history = useHistory();
    const location = useLocation();

    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showStopShadowing, setShowStopShadowing] = useState(false);
    const userData = new Cookies().get('userData');
    const timeclockEnabled = new Cookies().get('timeclockEnabled');
    const permissions = userData?.pay.permissions;

    const hrPermissions = 
        permissions.hrHire == 1
        || permissions.hrTerminate == 1
        || permissions.hrProfile == 1
        || permissions.hrTimesheet == 1
        || permissions.hrSchedule == 1
        || permissions.hrPay == 1
        || permissions.hrPto == 1
        || permissions.hrLoans == 1
        || permissions.hrDocuments == 1
        || permissions.hrPermissions == 1
    ;

    const url = location.pathname;
   
    return (
        <div style={{position: 'fixed', zIndex: 1, width: '100%', display: 'flex', padding: '4px 8px 4px 8px', justifyContent: 'space-between', alignItems: 'center', height: 60, backgroundColor: 'white', borderBottom: '1px solid rgb(118, 118, 118)'}}>
            <img src={logo} alt={'TTA LOGO'} style={{height: 50}}/>
            <Dropdown>
                <Dropdown.Toggle variant={'outline-primary'}>
                    <FontAwesomeIcon icon={faBars}/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Header>Account</Dropdown.Header>
                    <Dropdown.Item active={url === '/'} onClick={() => {history.push('/')}}>Dashboard</Dropdown.Item>
                    <Dropdown.Item active={url === '/profile'} onClick={() => {history.push('/profile')}}>{userData.firstName + '\'s Profile'}</Dropdown.Item>
                    { timeclockEnabled == 1 && 
                        <Dropdown.Item active={url === '/timeclock'} onClick={() => {history.push('/timeclock')}}>Timeclock</Dropdown.Item>
                    }
                    <Dropdown.Item active={url === '/my-schedule'} onClick={() => {history.push('/my-schedule')}}>My Schedule</Dropdown.Item>
                    <Dropdown.Item active={url === '/days-off'} onClick={() => {history.push('/days-off')}}>Days Off</Dropdown.Item>
                    <Dropdown.Item active={url === '/my-documents'} onClick={() => {history.push('/my-documents')}}>My Documents</Dropdown.Item>
                    <Dropdown.Divider/>
                    { (permissions.myCompany == 1 || permissions.scheduling == 1) && 
                        <>
                            <Dropdown.Header>ISP Management</Dropdown.Header>
                            { permissions.myCompany == 1 && 
                                <Dropdown.Item active={url === '/my-company'} onClick={() => {history.push('/my-company')}}>My Company</Dropdown.Item>
                            }
                            { permissions.scheduling == 1 && 
                                <Dropdown.Item active={url === '/scheduling'} onClick={() => {history.push('/scheduling')}}>Scheduling</Dropdown.Item>
                            }
                            <Dropdown.Divider/>
                        </>
                    }
                    { (permissions.payroll == 1 || hrPermissions) && 
                        <>
                            <Dropdown.Header>Operations</Dropdown.Header>
                            { permissions.payroll == 1 && 
                                <Dropdown.Item active={url === '/payroll'} onClick={() => {history.push('/payroll')}}>Payroll</Dropdown.Item>
                            }
                            { hrPermissions && 
                                <Dropdown.Item active={url === '/human-resources'} onClick={() => {history.push('/human-resources')}}>Human Resources</Dropdown.Item>
                            }
                            <Dropdown.Divider/>
                        </>
                    }

                    <Dropdown.ItemText>
                        <p style={{opacity: 0.5, marginBottom: 0}}>Logged in as</p>
                        <span style={{color: 'var(--bs-primary)'}}>{userData.firstName + ' ' + userData.lastName}</span>
                    </Dropdown.ItemText>
                    <Dropdown.Divider/>
                    <Dropdown.Item onClick={() => {setShowLogoutModal(true)}}>Logout</Dropdown.Item>
                    { auth.adminIdentifier && 
                        <Dropdown.Item onClick={() => {setShowStopShadowing(true)}} style={{color: 'red'}}>Stop Shadowing</Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
                
            <Modal show={showLogoutModal} onHide={() => {setShowLogoutModal(false)}} centered>
                <LogoutModal/>
            </Modal>
            <Modal show={showStopShadowing} onHide={() => {setShowStopShadowing(false)}} centered>
                <StopShadowingModal/>
            </Modal>
        </div>
    )
}


export default TopNavigationBar;