/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import ValidationIcon from './VaildationIcon';
import Col from 'react-bootstrap/Col';

function AvailabilityControl({forceTouched, title = 'Availability', inline, value, setValue, validator, originalValue, xs, sm, md, lg, xl, xxl}) {
    const [touched, setTouched] = useState(false);

    const handleToggleAvilability = (index) => {
        const newArr = Array.from(value);

        if(newArr[index] == 0){
            newArr[index] = 1
        } else{
            newArr[index] = 0
        }
        setValue(newArr.join(''));
    }
    
    const checkboxes = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => {
        return (
            <Form.Check onFocus={() => {if (!touched) {setTouched(true)}}} key={day} inline={inline} type='checkbox' label={day} checked={value[index]==1} onChange={() => {handleToggleAvilability(index)}}/>
        )
    });

    const valuesAreEqual = (a, b) => {
        for (let i = 0; i < 7; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }

    const content = (
        <Form.Group>
            <Form.Label>{title}</Form.Label>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid ' + (validator && (touched || forceTouched) && validator.isValid() ? 'green' : validator  ? 'red' : 'lightgray'), borderRadius: 6, padding: 12, marginBottom: 8, backgroundColor: originalValue !== undefined && !valuesAreEqual(originalValue, value) ? '#faffe3' : ''}}>
                <div>
                    {checkboxes}
                </div>
                <ValidationIcon hide={!validator} isValid={validator && validator.isValid()}/>
            </div>
        </Form.Group>
    );

    const contentWrappedInCol = (
        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
            {content}
        </Col>
    )

    return (
        <>
            {xs || sm || md || lg || xl || xxl ? contentWrappedInCol : content}
        </>
    )
}

export default AvailabilityControl;