/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {Form, Button, Modal} from 'react-bootstrap';
import QuickTable from '../../components/QuickTable';

export default function SchedulingWeekSelector({date, setDate}) {

    const [dateLocal, setDateLocal] = useState(date);
    const [selectedMonth, setSelectedMonth] = useState(moment(date).startOf('month').format('YYYY-MM-DD'));

    const rows = [0, 1, 2, 3, 4, 5].map((weekIndex) => {

        const startOfWeek = moment(selectedMonth).startOf('week').add(weekIndex, 'weeks').format('YYYY-MM-DD');
        const isSelected = moment(dateLocal).startOf('week').format('YYYY-MM-DD') === startOfWeek;

        const columns = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
            const day = moment(startOfWeek).add(dayIndex, 'days')

            const isToday = day.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
            const offMonth = day.format('MM') !== moment(selectedMonth).format('MM');
            
            return (
                <td key={dayIndex} style={{textAlign: 'center', color: isSelected ? 'white' : 'black', backgroundColor: isToday && !isSelected ? '#faffe3' : undefined, fontWeight: isToday ? 'bolder' : undefined, opacity: offMonth ? 0.3 : 1}}>
                    {day.format('D')}
                </td>
            );
        });

        return (
            <tr key={weekIndex} className='cursor-pointer' style={isSelected ? {backgroundColor: 'var(--bs-primary)', color: 'white'} : {}} onClick={() => {setDateLocal(startOfWeek)}}>
                {columns}
            </tr>
        );
    });

    const calendarTable = (
        <QuickTable headers={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']} rows={rows} widths={['14.3%', '14.3%', '14.3%', '14.3%', '14.3%', '14.3%', '14.3%']} hover centerHeaders striped={false} noMargin size='sm'/>
    )

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Select Week</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', marginBottom: 8, gap: 8}}>
                    <Button variant={'outline-primary'} onClick={() => setSelectedMonth(moment(selectedMonth).subtract(1, 'month').format('YYYY-MM-DD'))}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </Button>
                    <Form.Control type='month' value={moment(selectedMonth).format('YYYY-MM')} onChange={(event) => {setSelectedMonth(moment(event.target.value ? event.target.value : moment().format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD'))}}/>
                    <Button variant={'outline-primary'} onClick={() => setSelectedMonth(moment(selectedMonth).add(1, 'month').format('YYYY-MM-DD'))}>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </Button>
                </div>
                {calendarTable}                   
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={!dateLocal || dateLocal === date} variant={'outline-primary'} onClick={() => {setDate(dateLocal)}}>
                    {`View ${moment(dateLocal).format('MMM D')} - ${moment(dateLocal).add(6, 'days').format('MMM D')}`}
                </Button>
            </Modal.Footer>
        </>
    )
}