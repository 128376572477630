/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import CustomButton from "../../../components/CustomButton";
import DropdownControl from "../../../components/DropdownControl";
import { deleteShiftType } from "../../../services/SchedulingService";

export default function ShiftTypeDeleteModal({shiftTypes, shiftTypeToDelete, loadData}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [shiftTypeToSub, setShiftTypeToSub] = useState(undefined);
    const [confirmation, setConfirmation] = useState('');

    const handleDeleteShiftType = async (sub) => {
        setIsSubmitting(true);
        const response = await deleteShiftType(shiftTypeToDelete, sub);
        if (response.status === 200) {
            loadData();
        }
        setIsSubmitting(false);
    }


    const confirmationCode = 'delete associated shifts';

    const letterElements = confirmationCode.split('').map((char, index) => {
        return (
            <p key={index} style={{display: 'inline', marginBottom: 0, color: (confirmation.length >= (index + 1) && confirmation.charAt(index) === char) ? 'green' : 'red'}}>{char}</p>
        )
    });

    const shiftTypeTitleToBeDeleted = shiftTypes.find(st => st.uid === shiftTypeToDelete)?.title;

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Delete <i style={{fontWeight: 'bold'}}>{shiftTypeTitleToBeDeleted}</i> Shift Type?</Modal.Title>
            </Modal.Header>
            <Modal.Header style={{display: 'flex', flexDirection: 'column'}}>
                <p>Please select a replacement shift type for shifts which currently rely on the shift type <b>{shiftTypeTitleToBeDeleted}</b>.</p>
                <div style={{display: 'flex', alignItems: 'end', gap: 6, width: '100%'}}>
                    <div style={{flex: 1}}>
                        <DropdownControl title='Replacement' value={shiftTypeToSub} setValue={setShiftTypeToSub} itemValues={shiftTypes.filter(st => st.uid !== shiftTypeToDelete).map(st => st.uid)} itemNames={shiftTypes.filter(st => st.uid !== shiftTypeToDelete).map(st => st.title)}/>
                    </div>
                    <CustomButton variant="outline-danger" disabled={!shiftTypeToSub} onClick={() => handleDeleteShiftType(shiftTypeToSub)} label='Delete & Replace' isLoading={isSubmitting}/>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>Or type the following confirmation code to delete any shifts which rely on the shift type <b>{shiftTypeTitleToBeDeleted}</b>.</p>
                <div style={{paddingLeft: 12, paddingBottom: 4}}>
                    {letterElements}
                </div>
                <div style={{display: 'flex', alignItems: 'end', gap: 6}}>
                    <Form.Control value={confirmation} onChange={(event) => {setConfirmation(event.target.value)}} placeholder={confirmationCode}/>
                    <CustomButton variant="outline-danger" disabled={confirmation !== confirmationCode} label='Confirm' onClick={() => {handleDeleteShiftType()}} isLoading={isSubmitting}/>
                </div>
            </Modal.Body>
        </>
    )
}