/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { getFakeUID } from '../tools';

export default function DropdownControl({value, setValue, itemNames, itemValues, title, disabled, validator}) {
    const [initialized, setInitialized] = useState(false);
    const [search, setSearch] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [touched, setTouched] = useState(false);
    
    if (!initialized && value && itemValues.length > 0) {
        setInitialized(true);
        for (let i = 0; i < itemValues.length; i++) {
            if (itemValues[i] == value) {
                setSearch(itemNames[i]);
                break;
            }
        }
    }

    let filteredItemNames = [];
    let filteredItemValues = [];

    for (let i = 0; i < itemNames.length; i++) {
        if (itemNames[i].toLowerCase().includes(search.toLowerCase())) {
            filteredItemNames.push(itemNames[i]);
            filteredItemValues.push(itemValues[i]);
        }
    }

    const dropdownItems = filteredItemNames.map((name, index) => {
        return (
            <Dropdown.Item 
                key={getFakeUID()} 
                eventKey={name} 
                active={value && value == filteredItemValues[index]} 
                onClick={() => {
                    setValue(filteredItemValues[index]); 
                    setSearch(name); 
                    setShowDropdown(false)
                }}
            >
                {name}
            </Dropdown.Item>
        )
    });

    const handleOnChange = (event) => {
        setSearch(event.target.value);
        setValue(undefined);
        if (!showDropdown) {
            setShowDropdown(true);
        }
       
    }

    return (
        <Form.Group style={{position: 'relative'}}>
            <Form.Label>{title}</Form.Label>
            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch}/>
                </InputGroup.Text>
                <Form.Control 
                    disabled={disabled}
                    type='text' 
                    value={search}
                    onChange={handleOnChange} 
                    onFocus={() => {setShowDropdown(true)}} 
                    onBlur={() => {
                        setTimeout(() => {
                            setShowDropdown(false);
                            if (!value) {
                                setSearch(''); 
                            }
                            if (!touched) {
                                setTouched(true);
                            }
                        }, 150)
                    }}
                    isValid={validator?.isValid() || (!validator && value)}
                    isInvalid={validator && !validator?.isValid()}
                />
            </InputGroup>
            <Dropdown.Menu onMouseDown={(e)=>e.preventDefault()} show={showDropdown} style={{maxHeight: 200, overflowY: 'scroll', marginLeft: 40}}>
                {dropdownItems}
            </Dropdown.Menu>
        </Form.Group>
    )
}