import React, { useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import CustomButton from '../../../components/CustomButton'
import CustomControl from '../../../components/CustomControl';
import { updateLocation } from '../../../services/MyCompanyService';
import { AddressValidator, ValidationGroup, validateExistence } from '../../../validation';
import AddressControl from '../../../components/AddressControl';
import AddressAutoFill from '../../../components/AddressAutoFill';

export default function LocationEditor({location, setSelectedLocation, setShowEditorModal, loadData}) {
    
    const [showModal, setShowModal] = useState(false);

    const validationGroup = new ValidationGroup();
    const addressValidator = new AddressValidator(location?.address);

    function handleSetLocation(key, value){
        const newLocation = structuredClone(location);
        newLocation[key] = value;
        setSelectedLocation(newLocation);  
    }

    function handleSubmit(){
        updateLocation(location);
        loadData();
        setShowEditorModal(false);
    }

  return (
    <>
    <Modal.Header closeButton>
        <Modal.Title>{'Editing Location'}</Modal.Title>  
            <Button variant={'outline-primary'} style={{marginLeft: 24}} onClick={() => {setShowModal(true)}}>{location.isEnabled == 1 ? 'Set location as Inactive' : 'Set location as active'}</Button>              
    </Modal.Header>
    <Modal.Body>
        <Container fluid>
            <Row>
                <Col lg={12}>
                    <CustomControl
                        maxLength={50}
                        floatingLabel={true}
                        title='Location Name'
                        style={{marginBottom: 24}}
                        value={location.name}
                        setValue={(value)=>handleSetLocation('name', value)}
                        validator={validationGroup.createValidator(() => validateExistence(location.name))}
                    />
                    {/* <AddressControl title={'Location Address'} validator={addressValidator} lg={4} address={location.address} setAddress={(value)=> handleSetLocation('address', value)}/> */}
                    <AddressAutoFill label='Address' value={location.address} setValue={(value)=> handleSetLocation('address', value)} validator={addressValidator}/>
                </Col>
            </Row>
        </Container>
    </Modal.Body>
    <Modal.Footer>
        <CustomButton disabled={!addressValidator.isValid() || !validationGroup.isValid()} label={'Save Changes'} onClick={handleSubmit}/>
    </Modal.Footer>
    <Modal show={showModal} onHide={() => {setShowModal(false)}} centered>
        <Modal.Header closeButton>
            <Modal.Title>{location.isEnabled === 1 ? 'Confirm Deactivation' : 'Confirm Reactivation'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { location.isEnabled === 1 ? 'Deactivating a location will prevent it from appearing in scheduling and prevent users from being assigned to it.' 
                : 'Reactivating a location will make it appear in scheduling and allow users to be assigned to it.'
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant={'outline-primary'} onClick={() => {setShowModal(false); handleSetLocation('isEnabled', location.isEnabled == 1 ? 0: 1);}}>Confirm</Button>
        </Modal.Footer>
    </Modal>
</>
  )
}
