/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getTimesheetEntries(startDate, endDate) {
    const data = {
        type: 'timeclock',
        target: 'getTimesheets',
        token: getToken(),
        startDate: startDate,
        endDate: endDate
    }
    return await getResponse(data);
}

export async function punch() {
    const data = {
        type: 'timeclock',
        target: 'punch',
        token: getToken(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    return await getResponse(data);
}