/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { logout } from "../../services/AuthenticationService";
import CustomButton from "../CustomButton";
import { AppGlobals } from "../../App";
import CustomAlert from "../CustomAlert";

export default function LogoutModal() {
    const [isLoading, setIsLoading] = useState(false);


    const handleLogout = async () => {
        setIsLoading(true);
        const response = await logout();
        if (response.status === 200) {
            window.location.reload(false);
        } else {
            AppGlobals.alert(<CustomAlert type='danger' title={response.message}/>);
        }
        setIsLoading(false);
    }


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Are You Sure You Want to Logout?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <CustomButton onClick={handleLogout} isLoading={isLoading} label='Logout'/>
            </Modal.Footer>
        </>
    )
}
