/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getUsersForTimesheetReport() {
    const data = {
        type: 'reports',
        target: 'getUsersForTimesheetReport',
        token: getToken(),
    }
    
    return await getResponse(data);
}

export async function getTimesheetReportData(employees, startDate, endDate) {
    const data = {
        type: 'reports',
        target: 'getTimesheetReportData',
        token: getToken(),
        employees: employees,
        startDate: startDate,
        endDate: endDate
    }
    
    return await getResponse(data);
}
