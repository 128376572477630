/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import moment from "moment";
import { Card, Table } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import PageSpinner from "../../components/PageSpinner";
import Modal from 'react-bootstrap/Modal';
import DaysOffRequestModal from "./DaysOffRequestModal";
import { getRequestOffHistory } from "../../services/DaysOffService";
import { dateIsInRange, dateRangeToString } from "../../tools";
import DateRangeSelector from "../../components/DateRangeSelector";


class DaysOff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            requestOffHistory: [],
            otherApprovals:[],
            showModal: false,
            showDateRangeFilter:false,
            startDate:'',
            endDate:''
        }

        this.loadData = this.loadData.bind(this);
    }

    loadData() {
        this.setState({isLoading: true});

        getRequestOffHistory().then((response) => {
            if (response.status == '200') {
                this.setState({requestOffHistory: response.timeOff.sort((a, b) => {
                    if (moment(a.submissionDate).isBefore(moment(b.submissionDate))) {
                        return 1;
                    } else if (moment(a.submissionDate).isAfter(moment(b.submissionDate))) {
                        return -1;
                    } else {
                        return 0;
                    }
                })});
            }
            this.setState({isLoading: false});
        });
    }

    componentDidMount() {
        this.loadData();
    }

    handleSetDateRange = (startDate, endDate) => {
        this.setState({startDate: startDate, endDate: endDate});
    }

    render() {
       const filteredRequestsOff =  this.state.requestOffHistory.filter((request)=>{
           return dateIsInRange(request.submissionDate, this.state.startDate, this.state.endDate);
        });

        const previousRequests = filteredRequestsOff.map((timeoffitem) => {
            return (
                <tr key={timeoffitem.uid}>
                    <td>{moment(timeoffitem.submissionDate).format('MMM D, YYYY')}</td>
                    <td>{moment(timeoffitem.startDate).format('MMM D, YYYY')}</td>
                    <td>{timeoffitem.endDate ? moment(timeoffitem.endDate).format('MMM D, YYYY'): ''}</td>
                    <td>{timeoffitem.notes}</td>
                    <td>{
                        timeoffitem.status == 0 ? 'Pending' : 
                        timeoffitem.status == -1 ? 'Rejected' : 
                        'Approved'}
                    </td>
                </tr>
            )
        });

        const content = (
            <div  style={{maxHeight: '100%', width:'100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 12}}>
                    <p style={{fontWeight:'bold', fontSize:20, marginRight:10}}>Previous Requests</p>
                    <Button variant={'outline-primary'} onClick={() => {this.setState({showDateRangeFilter: true})}}>{'Submission Date Filter: ' + dateRangeToString(this.state.startDate, this.state.endDate)}</Button>
                    <Button variant={'outline-primary'} onClick={() => {this.setState({showModal: true})}}>Request Off</Button>
                </div>
                <div style={{overflowY: 'scroll'}}>
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>Date Submitted</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Notes</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {previousRequests}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    
        return (
            <div className="page-wrapper" style={{padding: 12}}>
                {this.state.isLoading ? <PageSpinner/> : content}
                <Modal show={this.state.showModal} onHide={() => {this.setState({showModal: false})}} centered size='lg'>
                    <DaysOffRequestModal requestOffHistory={this.state.requestOffHistory} otherApprovals={this.state.otherApprovals} hideModal={() => {this.setState({showModal: false})}} loadData={this.loadData}/>
                </Modal>
                <DateRangeSelector show={this.state.showDateRangeFilter} onHide={() => {this.setState({showDateRangeFilter: false})}} centered handleSetDateRange={this.handleSetDateRange} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'allTime']}/>
            </div>
        )
    }
}




export default DaysOff;