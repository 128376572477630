/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import { getTimesheetReportData, getUsersForTimesheetReport } from '../../services/ReportsService';
import CustomButton from '../../components/CustomButton';
import moment from 'moment';
import PageSpinner from '../../components/PageSpinner';
import TimesheetReport from './TimesheetReport';

export default function TimesheetReportForm({}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [employees, setEmployees] = useState([]);
    const [locations, setLocations] = useState([]);
    const [payrollEnabled, setPayrollEnabled] = useState(false);
    const [payrollPeriodLength, setPayrollPeriodLength] = useState(7);
    const [payrollPeriodStartDay, setPayrollPeriodStartDay] = useState(0);

    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const [timesheetEntries, setTimesheetEntries] = useState([]);
    const [showPdf, setShowPdf] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getUsersForTimesheetReport();
        if (response.status === 200) {
            setEmployees(response.employees);
            setLocations(response.locations);
            setPayrollEnabled(response.payrollEnabled);
            setPayrollPeriodLength(response.payrollPeriodLength);
            setPayrollPeriodStartDay(response.payrollPeriodStartDay);
        }
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const response = await getTimesheetReportData(selectedEmployees, startDate, endDate);
        if (response.status === 200) {
            setTimesheetEntries(response.timesheetEntries);
            setShowPdf(true);
        }
        setIsSubmitting(false);
    }

    const payrollPeriodElements = [0, 1, 2, 3].map((weekIndex) => {
        const weekStart = moment().startOf('week').add(payrollPeriodStartDay, 'days').subtract(weekIndex, 'weeks').format('YYYY-MM-DD');
        const weekEnd = moment(weekStart).add(payrollPeriodLength, 'days').subtract(1, 'days').format('YYYY-MM-DD');

        return (
            <Dropdown.Item key={weekIndex} active={startDate === weekStart && endDate === weekEnd} onClick={() => {
                setStartDate(weekStart);
                setEndDate(weekEnd);
            }}>
                {`${moment(weekStart).format('MMM D')} - ${moment(weekEnd).format('MMM D')}`}
            </Dropdown.Item>
        )
    })

    const employeeElements = employees.map((e) => {
        return (
            <Dropdown.Item key={e.uid} active={selectedEmployees.includes(e.uid)} onClick={() => {
                if (selectedEmployees.includes(e.uid)) {
                    setSelectedEmployees(selectedEmployees.filter(em => em != e.uid));
                } else {
                    setSelectedEmployees(selectedEmployees.concat([e.uid]));
                }
            }}>
                {`${e.firstName} ${e.lastName} (${e.location.name})`}
            </Dropdown.Item>
        )
    })
    const locationElements = locations.map((l) => {

        return (
            <Dropdown.Item key={l.uid} active={employees.filter(e => e.locationIdentifier == l.uid).map(e => e.uid).join() == selectedEmployees.join()} onClick={() => {
                    setSelectedEmployees(employees.filter(e => e.locationIdentifier == l.uid).map(e => e.uid));
            }}>
                {l.name}
            </Dropdown.Item>
        )
    })


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Timesheet Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> : 
                    <>
                        <h5>Date Range</h5>
                        <div style={{display: 'flex', gap: 12, marginBottom: 12, alignItems: 'center'}}>
                            <div style={{flex: 1}}>
                                <CustomDatePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} title='Select Custom Date Range'/>
                            </div>
                            { payrollEnabled && 
                                <>
                                    <div style={{height: 52, width: 1, backgroundColor: 'lightgray'}}/>
                                    <Dropdown>
                                        <Dropdown.Toggle variant='outline-primary'>Select Payroll Period</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {payrollPeriodElements}
                                        </Dropdown.Menu>

                                    </Dropdown>
                                </>
                            }
                        </div>
                        <h5>Employees</h5>
                        <div style={{display: 'flex', gap: 12}}>
                            <Dropdown autoClose='outside'>
                                <Dropdown.Toggle variant='outline-primary'>Select Employees</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {employeeElements}
                                </Dropdown.Menu>
                            </Dropdown>
                            <div style={{width: 1, backgroundColor: 'lightgray'}}/>
                            <Dropdown>
                                <Dropdown.Toggle variant='outline-primary'>Select Employees For Location</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {locationElements}
                                </Dropdown.Menu>
                            </Dropdown>
                            <div style={{width: 1, backgroundColor: 'lightgray'}}/>
                            <Button variant='outline-primary' onClick={() => {setSelectedEmployees(employees.map(e => e.uid))}}>Select All Employees</Button>
                        </div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton 
                    disabled={!startDate || !endDate || selectedEmployees.length === 0}
                    label={(!startDate || !endDate) ? 'Select A Date Range' : selectedEmployees.length == 0 ? 'No Employees Selected' : `Get Timesheet Report for ${selectedEmployees.length} Employee(s) Between ${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}`}
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                />
            </Modal.Footer>
            <Modal show={showPdf} onHide={() => {setShowPdf(false)}} fullscreen>
                <TimesheetReport users={employees.filter(e => selectedEmployees.includes(e.uid))} timesheets={timesheetEntries} startDate={startDate} endDate={endDate}/>
            </Modal>
        </>
    )
}