/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Modal, Row } from 'react-bootstrap';
import OfferLetterEditor from '../../../components/OfferLetterEditor';
// import CustomCheck from '../../../components/CustomCheck';
import { DirectDepositDocuments, OnboardingDocuments, OnboardingDocumentsShort, TaxDocumentsShort, addressToString, availabilityToBinaryString, availabilityToString, employeeTypeDictionary, formatPhoneNumber, formatTimeNew, parseAvailability, payTypeDictionary, stateTaxDocuments, taxDocumentDictionary, usdFormatter, validateDecimalFixed, validateInteger } from '../../../tools';
import SwitchControl from '../../../components/SwitchControl';
import RadioControl from '../../../components/RadioControl';
import { ValidationGroup, validateExistence, validateIsDate } from '../../../validation';
import CustomControl from '../../../components/CustomControl';
import CustomButton from '../../../components/CustomButton';
import moment from 'moment';
import PageSpinner from '../../../components/PageSpinner';
import { createDocumentRequest, getDocumentRequestData } from '../../../services/HumanResourcesService';
import { AppGlobals } from '../../../App';
import CustomAlert from '../../../components/CustomAlert';
import CustomCheck from '../../../components/CustomCheck';
import DropdownControl from '../../../components/DropdownControl';
import MyCompanyOfferLetters from '../../MyCompany/MyCompanyOfferLetters';

export default function DocumentRequestModal({userIdentifier, hideModal, defaultAllDocumentsOn}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [signers, setSigners] = useState([]);
    const [employee, setEmployee] = useState(undefined);
    const [company, setCompany] = useState(undefined);
    const [location, setLocation] = useState(undefined);
    const [showOfferLetter, setShowOfferLetter] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState(defaultAllDocumentsOn ? ['w4', 'i9', 'dd', 'f8850'] : []);
    const [selectedDirectDepositDocument, setSelectedDirectDepositDocument] = useState(defaultAllDocumentsOn ? 'dd' : null);
    const [selectedTaxDocument, setSelectedTaxDocument] = useState(undefined);
    const [includeEContacts, setIncludeEContacts] = useState(defaultAllDocumentsOn ?? false);
    const [includeOfferLetter, setIncludeOfferLetter] = useState(defaultAllDocumentsOn ?? false);
    const [offerLetters, setOfferLetters] = useState([]);
    const [selectedOfferLetter, setSelectedOfferLetter] = useState(null);
    const [showOfferLetterModal, setShowOfferLetterModal] = useState(false);
    const [startDate, setStartDate] = useState(''); //W4, Offer Letter
    const [supervisorName, setSupervisorName] = useState(''); //Offer Letter
    const [selectedAo, setSelectedAo] = useState(undefined); //Offer Letter
    const [employerSigner, setEmployerSigner] = useState(undefined); //I9, some Tax Documents
    const [content, setContent] = useState('');
    const offerLetterElements = offerLetters.map((ol) => {
        return (
            <Dropdown.Item key={ol.uid} onClick={() => {setContent(ol.content); setSelectedOfferLetter(ol.uid)}}>{ol.name}</Dropdown.Item>
        )
    })

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if(includeOfferLetter){
            setSelectedOfferLetter(offerLetters[0]?.uid)
        }
    }, [includeOfferLetter])

    useEffect(() => {
        if(offerLetters.length === 0){
            setIncludeOfferLetter(false);
        }
    }, [offerLetters])

    useEffect(() => {
        setContent(offerLetters.find(ol => ol.uid == selectedOfferLetter)?.content ?? '');
    }, [selectedOfferLetter])

    async function loadData(){
        setIsLoading(true);
        const response = await getDocumentRequestData(userIdentifier);
        if(response.status === 200){
            setEmployee(response.employee);
            setCompany(response.company);
            setLocation(response.location);
            setSigners(response.signerCandidates);
            setOfferLetters(response.offerLetters);
            setStartDate(response.employee.startDate ? response.employee.startDate : '');
            if (defaultAllDocumentsOn) {
                setSelectedTaxDocument(stateTaxDocuments[response.csa?.address.administrativeArea] ?? undefined);
            }
        }else{
            AppGlobals.alert(<CustomAlert type={'danger'} title={response.message ?? 'Failed to retrieve document request data'}/>)
        }
        setIsLoading(false);
    }

    async function handleSubmit(){
        setIsSubmitting(true);

        const documents = {};
        selectedDocuments.forEach((type) => {
            const document = {title: `${employee.firstName} ${employee.lastName} ${OnboardingDocumentsShort[type]}`};
            if (type == 'w4') {
                document.startDate = startDate;
            }
            documents[type] = document;
        })
        if (selectedTaxDocument) {
            documents[selectedTaxDocument] = {title: `${employee.firstName} ${employee.lastName} ${TaxDocumentsShort[selectedTaxDocument]}`};
        }
        if (selectedDirectDepositDocument) {
            documents[selectedDirectDepositDocument] = {title: `${employee.firstName} ${employee.lastName} ${DirectDepositDocuments[selectedDirectDepositDocument]}`};
        }
        if (includeOfferLetter) {
            const ao = signers.find(s => s.uid == selectedAo);
            documents.offerLetter = {
                title: `${employee.firstName} ${employee.lastName} Offer Letter`,
                content: content,
                companyName: company.companyName,
                employeeType: employee.pay.fulltime,
                startDate: startDate,
                supervisorName: supervisorName,
                terminalAddress: location.address,
                payType: employee.pay.payType,
                payRate: validateDecimalFixed(employee.pay.payRate, 2),
                expectedAvailability: employee.availability,
                benefits: company.benefits,
                aoName: `${ao.firstName} ${ao.lastName}`,
                aoPhoneNumber: ao.phoneNumber,
                aoEmail: ao.email
            };
        }

        const response = await createDocumentRequest(userIdentifier, documents, employerSigner ?? '', includeEContacts, startDate ? startDate : null);
        if(response.status === 200){
            hideModal()
        }
        AppGlobals.alert(<CustomAlert type={response.status === 200 ? 'success' : 'danger'} title={response.message}/>)
        setIsSubmitting(false)
    }


    const validationGroup = new ValidationGroup();
    const totalDocumentsSelected = selectedDocuments.length + (selectedTaxDocument ? 1 : 0) + (includeEContacts ? 1 : 0) + (includeOfferLetter ? 1 : 0) + (selectedDirectDepositDocument ? 1 : 0);
    const requiresEmployerSigner = selectedDocuments.includes('i9') || selectedDocuments.includes('f8850') || ['a4', 'l4', 'miw4', 'it2104', 'rev419'].includes(selectedTaxDocument);
    const requiresStartDate = selectedDocuments.includes('w4') || selectedDocuments.includes('i9') || includeOfferLetter;

    const offerLetterRadioValues = [...offerLetters.map(ol => ol.uid)];
    const offerLetterRadioLabels = [...offerLetters.map(ol => `${ol.name}`)];

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Send Employee Document Request</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                { isLoading ? <PageSpinner/> :
                    <>

                        <div style={{border: '1px solid lightgray', padding: 12, borderRadius: 6}}>
                            <h5 style={{textAlign: 'center'}}>Onboarding Documents to Include</h5>
                            <Container fluid style={{padding: 0}}>
                                <Row>
                                    <Col xl={6} style={{display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8}}>
                                        <CustomCheck inline title='Documents' selection={selectedDocuments} setSelection={setSelectedDocuments} optionNames={Object.values(OnboardingDocuments)} optionValues={Object.keys(OnboardingDocuments)}/>                                    
                                        <SwitchControl title={'Include Direct Deposit Form'} value={selectedDirectDepositDocument !== null} setValue={(value) => {setSelectedDirectDepositDocument(value ? 'dd' : null)}}/>
                                        { selectedDirectDepositDocument && 
                                            <RadioControl title='Selected Direct Deposit Form' inline selection={selectedDirectDepositDocument} setSelection={setSelectedDirectDepositDocument} optionNames={Object.values(DirectDepositDocuments)} optionValues={Object.keys(DirectDepositDocuments)}/>
                                        }
                                        <SwitchControl title={'Request Emergency Contacts'} value={includeEContacts} setValue={setIncludeEContacts}/>
                                    </Col>
                                    <Col xl={6} style={{display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8}}>
                                        <SwitchControl title={'Include State Tax Document'} value={selectedTaxDocument !== undefined} setValue={(value) => {setSelectedTaxDocument(value ? stateTaxDocuments[location?.address.administrativeArea] ?? 'it4' : undefined)}}/>
                                        { selectedTaxDocument && 
                                            <RadioControl inline title={'Selected State Tax Document'} selection={selectedTaxDocument} setSelection={setSelectedTaxDocument} optionNames={Object.values(taxDocumentDictionary)} optionValues={Object.keys(taxDocumentDictionary)}/>
                                        }
                                        <SwitchControl disabled={offerLetters.length === 0} title={'Include Offer Letter'} value={includeOfferLetter} setValue={setIncludeOfferLetter}/>
                                        { includeOfferLetter && 
                                            <>
                                                {offerLetters.length > 0 &&
                                                    <>
                                                        <RadioControl 
                                                        inline 
                                                        title={'Selected Offer Letter'} 
                                                        selection={selectedOfferLetter ?? offerLetters?.[0]?.uid} 
                                                        setSelection={(value)=>{setContent(offerLetters.find(ol=>ol.uid == value)?.content ?? ''); setSelectedOfferLetter(value)}} 
                                                        optionNames={offerLetterRadioLabels} 
                                                        optionValues={offerLetterRadioValues}
                                                        />
                                                        <Button variant='outline-primary' onClick={() => {setShowOfferLetter(true)}}>
                                                            <span>Edit Selected Offer Letter</span>
                                                        </Button>
                                                    </>
                                                }
                                            </>
                                        }
                                        <Button onClick={() => setShowOfferLetterModal(true)} variant='outline-primary'>Create Offer Letter</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        { (requiresEmployerSigner || requiresStartDate || includeOfferLetter) && 
                            <div style={{border: '1px solid lightgray', padding: 12, borderRadius: 6}}>
                                <h5 style={{textAlign: 'center'}}>Required Information</h5>
                                <Container fluid style={{padding: 0}}>
                                    <Row>
                                        { requiresEmployerSigner &&
                                            <Col lg={12} style={{marginBottom: 12}}>
                                                <DropdownControl value={employerSigner} setValue={setEmployerSigner} itemNames={signers.map(s => `${s.firstName} ${s.lastName}`)} itemValues={signers.map(s => s.email)} title={'Signer on behalf of Employer'} validator={validationGroup.createValidator(() => validateExistence(employerSigner))}/>
                                            </Col>
                                        }
                                        { requiresStartDate &&
                                            <Col xl={6} lg={12}>
                                                <CustomControl floatingLabel title='Employment Start Date' value={startDate} setValue={setStartDate} type='date' validator={validationGroup.createValidator(() => validateIsDate(startDate))}/>
                                            </Col>
                                        }
                                        { includeOfferLetter && 
                                            <>
                                                <Col xl={6} lg={12}>
                                                    <CustomControl floatingLabel title='Name of Immediate Supervisor' value={supervisorName} setValue={setSupervisorName} validator={validationGroup.createValidator(() => validateExistence(supervisorName))}/>
                                                </Col>
                                                <DropdownControl value={selectedAo} setValue={setSelectedAo} itemNames={signers.map(s => `${s.firstName} ${s.lastName}`)} itemValues={signers.map(s => s.uid)} title={'Manager to Appear of Offer Letter'} validator={validationGroup.createValidator(() => validateExistence(selectedAo))}/>
                                            </>
                                        }
                                    </Row>
                                </Container>
                            </div>
                        }
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <span style={{color: totalDocumentsSelected ? 'green' : 'red'}}>{`${totalDocumentsSelected} document${totalDocumentsSelected != 1 ? 's' : ''} selected`}</span>
                <CustomButton label={`Submit Document Request`} isLoading={isSubmitting} onClick={handleSubmit} disabled={!validationGroup.isValid() || totalDocumentsSelected == 0 || (includeOfferLetter && !content)}/>
            </Modal.Footer>
            <Modal show={showOfferLetter} onHide={() => {setShowOfferLetter(false)}} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Offer Letter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Dropdown.Toggle variant='outline-primary'>Import Template</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {offerLetterElements}
                        </Dropdown.Menu>
                    </Dropdown>
                    <h5 style={{textAlign: 'center', color: 'gray', fontStyle: 'italic', margin: 0}}>Any modifications will be reflected in the offer letter sent to the employee</h5>
                    <OfferLetterEditor value={content} setValue={setContent} setSelectedOfferLetter={setSelectedOfferLetter} offerLetterData={{
                        companyName: company?.companyName,
                        employeeName: `${employee?.firstName} ${employee?.lastName}`,
                        employeeType: employee?.pay.fulltime,
                        startDate: startDate,
                        supervisorName: supervisorName,
                        locationAddress: location?.address,
                        payType: employee?.pay.payType,
                        payRate: validateDecimalFixed(employee?.pay.payRate, 2),
                        expectedAvailability: availabilityToString(employee?.availability ?? ''),
                        benefits: company?.benefits,
                        offerLetterManagerName: `${signers.find(s => s.uid == selectedAo)?.firstName ?? ''} ${signers.find(s => s.uid == selectedAo)?.lastName ?? ''}`,
                        offerLetterManagerPhoneNumber: signers.find(s => s.uid == selectedAo)?.phoneNumber ?? '',
                        offerLetterManagerEmail: signers.find(s => s.uid == selectedAo)?.email ?? ''
                    }}/>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-primary' onClick={() => {setShowOfferLetter(false)}}>Done</Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={showOfferLetterModal} onHide={() => setShowOfferLetterModal(false)}>
                <MyCompanyOfferLetters offerLetters={offerLetters} setOfferLetters={setOfferLetters}/>
            </Modal>
        </>
    )
}

