/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer, useState } from "react";
import { Dropdown, FloatingLabel, Form, InputGroup, Modal, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import CustomButton from "./CustomButton";
import { addressToString } from "../tools";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import img1 from '../assets/addressInstructions1.png';
import img2 from '../assets/addressInstructions2.png';
import { autoCompleteAddress, validateAddress } from "../services/AddressService.js";
import { AppGlobals } from "../App.js";
import CustomAlert from "./CustomAlert";
import Decoder from "../decoding.js";
import Address from "../model/Address.js";

export default function AddressAutoFill({label, value, setValue, noRedAsterisk, validator}){
    const [isSearching, setIsSearching] = useState(false);
    const [isValidating, setIsValidating] = useState(false);
    const [search, setSearch] = useState('');
    const [lastSearch, setLastSearch]= useState('');
    const [showDropdown, setShowDropdown] = useState(true);
    const [searchResults, setSearchResults] = useState([]);
    const [ticker, setTicker] = useState(0);
    const [showValidation, setShowValidation] = useState(true);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (search !== lastSearch && search) {
            setLastSearch(search);
            handleSearchRequest();
        }
    }, [ticker])

    useEffect(() => {
        const interval = setInterval(handleInterval, 1000);
        return () => clearInterval(interval);
    }, []);

    async function handleSearchRequest(){
        setIsSearching(true);
        const response = await autoCompleteAddress(search);
        if (response.status === 200) {
            setSearchResults(response.predictions.map(p => { return {description: p.description, placeId: p.place_id}}));
        }
        setIsSearching(false);
    }

    async function handleValidateAddress(placeId){
        setShowDropdown(false);
        setIsValidating(true);
        const response = await validateAddress(placeId);
        if (response.status === 200) {
            setValue(Address.decodeValidated(response.address));
            if (!showValidation) {
                setShowValidation(true);
            }
        } else {
            AppGlobals.alert(<CustomAlert type='danger' title={response.message}/>);
        }
        setIsValidating(false);
    }

    function handleInterval(){
        setTicker(Date.now());
    }

    function handleSetValue(value){
        setSearch(value);
    }

    const searchResultElements = searchResults.map((sr) => {
        return (
            <Dropdown.Item key={sr.placeId} onClick={() => {setSearch(sr.description); handleValidateAddress(sr.placeId)}}>{sr.description}</Dropdown.Item>
        )
    })

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center', gap: 4}}>
                <InputGroup>
                {value && <CustomButton variant="primary" style={{color: 'white'}} label='Edit' onClick={() => setValue(undefined)} isLoading={isValidating}/>}
                    { value ?
                        <OverlayTrigger overlay={(
                            <Popover style={{position: 'fixed'}}>
                                <Popover.Body>
                                    {addressToString(value)}
                                </Popover.Body>
                            </Popover>
                        )}>
                            <Form.Control isValid={showValidation && validator?.isValid()} style={{height: 58, flex: 1}} value={addressToString(value)} readOnly disabled/>
                        </OverlayTrigger>
                    :
                        <FloatingLabel label={<div>{label}<span style={{color: 'red'}}>{(!noRedAsterisk) ? '*' : ''}</span></div>}>
                            <Form.Control 
                                value={search}
                                style={{height: 58}}
                                onFocus={() => {setShowDropdown(true)}}
                                onChange={(e)=>handleSetValue(e.target.value)}
                                placeholder=" "
                                disabled={isValidating}
                                isInvalid={showValidation && validator && !validator?.isValid()}
                            />
                        </FloatingLabel>
                    }

                    <CustomButton variant={'primary'} style={{color: 'white'}} isLoading={isValidating} label='Clear' onClick={()=>{setSearch(''); setValue(undefined)}}/>
                </InputGroup>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FontAwesomeIcon style={{width: 24, height: 24, color: 'var(--bs-primary)', cursor: 'pointer'}} onClick={() => setShowModal(true)} icon={faCircleQuestion}/>
                </div>
            </div>
            <Dropdown show={searchResults.length > 0 && search && showDropdown} onBlur={()=>setShowDropdown(false)}>
                <Dropdown.Menu style={{width: '100%'}}>
                    { isSearching 
                        ? <Dropdown.Item disabled style={{display: 'flex', justifyContent: 'center'}}>
                            <Spinner style={{color: 'var(--bs-primary)', padding: 12}}/> 
                          </Dropdown.Item>
                        : searchResultElements
                    }
                </Dropdown.Menu>
            </Dropdown>
            <Modal size="lg" centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Address Help</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>To add a secondary street address into the form, type both the prefix (ex: apt) and suffix (ex: 102) after the primary street address.</p>
                    <img style={{ width: '100%', padding: 16}} src={img1}/>
                    <img style={{ width: '100%', padding: 16}} src={img2}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}