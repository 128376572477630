/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import CustomControl from '../../../components/CustomControl';
import { Alert } from 'react-bootstrap';
import { createTimesheetEntry, deleteTimesheetEntry, updateTimesheetEntry } from '../../../services/HumanResourcesService';
import APIModal from '../../../components/APIModal';
import DateTimePicker from '../../../components/DateTimePicker';

function HRUserTimesheetEditor({employee, selectedEntry, handleCrud, timesheets}) {

    const [inPunch, setInPunch] = useState(selectedEntry ? selectedEntry.inPunch : moment().format('YYYY-MM-DD HH:mm:ss'));
    const [outPunch, setOutPunch] = useState(selectedEntry?.outPunch ?? '');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    
    const handleSubmit = () => {
        setIsLoading(true);
        const newEntry = {
            uid: selectedEntry ? selectedEntry.uid : undefined,
            userIdentifier: employee.uid,
            inPunch: inPunch,
            outPunch: outPunch ? outPunch : undefined
        }

        if (selectedEntry) {
            updateTimesheetEntry(newEntry).then((response) => {
                if (response?.status == 200) {
                    handleCrud('update', newEntry);
                } else {
                    setError(response.message);
                }
                setIsLoading(false);
            });
        } else {
            createTimesheetEntry(newEntry).then((response) => {
                if (response?.status == 200) {
                    newEntry.uid = response.uid;
                    handleCrud('create', newEntry);
                } else {
                    setError(response.message);
                }
                setIsLoading(false);
            });
        }
    }

    const handleDelete = async () => {
        if (selectedEntry) {
            const response = await deleteTimesheetEntry(selectedEntry.uid);
            if (response.status == 200) {
                handleCrud('delete', selectedEntry.uid);
                return true;
            }
        }
        return 'Failed to delete timesheet entry';
    }

    const handleTimeUpdates = () => {
        setError(undefined);

        if (outPunch && outPunch < inPunch) {
            setError('The out time must come after the in time');
            return;
        }

        const filteredTimesheets = timesheets.filter(e => e.uid !== selectedEntry?.uid ?? -1);

        if (filteredTimesheets.length > 0) {

            const sortedTimesheets = Array.from(filteredTimesheets).sort((a, b) => {
                if (a.inPunch < b.inPunch) {
                    return -1;
                } else if (a.inPunch > b.inPunch) {
                    return 1;
                } else {
                    return 0;
                }
            })
    
            if (!sortedTimesheets[sortedTimesheets.length - 1].outPunch) {
                if (inPunch > sortedTimesheets[sortedTimesheets.length - 1].inPunch || !outPunch || outPunch > sortedTimesheets[sortedTimesheets.length - 1].inPunch) {
                    setError('This employee is currently clocked in. You may only create a timesheet entry if it is historic and has both an IN and OUT time.');
                    return;
                }
            }
    
            for (let i = 0; i < sortedTimesheets.length - 1; i++) {
                const entryI = sortedTimesheets[i];
                for (let j = i + 1; j < sortedTimesheets.length; j++) {
                    const entryJ = sortedTimesheets[j];
                    if (entryI.outPunch && entryJ.inPunch < entryI.outPunch) {
                        setError('You can not overlap time with an existing timesheet entry');
                        return;
                    }
                }
            }
        }
    };

    useEffect(() => {
        handleTimeUpdates();
    }, [inPunch, outPunch]);
    
    


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedEntry ? 'Editing Timesheet Entry' : 'Creating Timesheet Entry'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant='warning' style={{fontSize:'10', padding:2}}>{error}</Alert>}
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 12}}>
                    <DateTimePicker title='In Time' value={inPunch} setValue={setInPunch} type={'dateTimePrecise'}/>
                    <DateTimePicker title='Out Time' value={outPunch} setValue={setOutPunch} type={'dateTimePrecise'} optional defaultDate={inPunch}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                { selectedEntry &&
                    <Button onClick={() => setShowDeleteConfirmation(true)} style={{color: 'red', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: 0, marginRight: 8}}>
                        Delete Timesheet Entry
                    </Button>
                }

                <Button variant={'outline-primary'} disabled={isLoading || error} onClick={handleSubmit}>
                    {isLoading ? <Spinner size='sm'/> : selectedEntry ? 'Save Changes' : 'Submit'}
                </Button>
            </Modal.Footer>   
            <APIModal show={showDeleteConfirmation} title={'Delete Confirmation'} message={'Permanently delete this timesheet entry?'} buttonLabel={'Delete'} onHide={() => {setShowDeleteConfirmation(false)}} centered callBack={handleDelete}/>
        </>
    )
}

export default HRUserTimesheetEditor;