import React from 'react'
import KeyValueRow from '../../components/KeyValueRow'
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap'
import { encodeAvailability, parseAvailability, payTypeDictionary, usdFormatter } from "../../tools";
import { toFixedMax } from '../Payroll/payrollTools';

export default function PositionInfo({user, pay}) {
  return (
    <Container fluid>
        <div style={{padding:5}}>
            <p style={{textAlign: 'center', margin: 0, fontSize: 24, fontWeight: 'bold', padding:10}}>Position at {user.companyName}</p>
        </div>
        <Row style={{paddingBottom:5, marginBottom: 8}}>
            <Col>
                <Card>
                    <Card.Body style={{display:'flex', flexDirection:'column'}}>
                        <div>
                            <Card.Title style={{textAlign:'center', paddingBottom:10}}>Employment Details</Card.Title>
                        </div>
                        <ListGroup>
                            <KeyValueRow isListItem title={'Effective Date'} value={pay.dateEffective} />
                            <KeyValueRow isListItem title={'Title'} value={pay.title} />
                            <KeyValueRow isListItem title={'Employee Type'} value={pay.fulltime == 1 ? 'Full-Time' : 'Part-Time'}/>
                            <KeyValueRow isListItem title={'Expected Availability'} value={encodeAvailability(user.availability)}/>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row style={{paddingBottom:5, marginBottom: 8}}>
            <Col>
                <Card>
                    <Card.Body style={{display:'flex', flexDirection:'column'}}>
                        <div>
                            <Card.Title style={{textAlign:'center', paddingBottom:10}}>Pay Earnings</Card.Title>
                        </div>
                        <ListGroup>
                            <KeyValueRow isListItem title={'Pay Type'} value={payTypeDictionary[pay.payType]} />
                            <KeyValueRow isListItem title={'Pay Rate'} value={usdFormatter.format(pay.payRate)} />
                            <KeyValueRow isListItem title={`PTO Accrual Per ${pay.ptoAccrualType == 0 ? '40 Hours' : 'Pay Period'}`} value={`${toFixedMax(pay.ptoAccrual, 3)} Hour(s)`}/>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row style={{paddingBottom:5, marginBottom: 8}}>
            <Col>
                <Card>
                    <Card.Body style={{display:'flex', flexDirection:'column'}}>
                        <div>
                            <Card.Title style={{textAlign:'center', paddingBottom:10}}>Pay Deductions</Card.Title>
                        </div>
                        <ListGroup>
                            <KeyValueRow isListItem title={'Medical'} value={usdFormatter.format(pay.medical)} />
                            <KeyValueRow isListItem title={'Dental'} value={usdFormatter.format(pay.dental)} />
                            <KeyValueRow isListItem title={'Vision'} value={usdFormatter.format(pay.vision)}/>
                            <KeyValueRow isListItem title={'Child Support'} value={pay.childSupport ? usdFormatter.format(JSON.parse(pay.childSupport).reduce((prev, curr) => {
                                return prev + parseFloat(curr);
                            }, 0)) : '$0.00'} />
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
)
}
