/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";

import moment from "moment";

import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from '../../components/List/List';
import { intToTime, scheduleTypeDictionary } from "../../tools";

function CalendarObject({object, popup, color, icon, title, status, iconColor}) {

    let items;
    let allBreaks = object.breaks?.map((el)=>{
        return ['Break: ', `${intToTime(el.startTime)} - ${intToTime(el.endTime)}`]
    })
   
    switch (title) {
        case object.title:
            items = [
                ['Date: ', moment(object.date).format('MMM D, YYYY')],
                ['Start Time: ', intToTime(object.startTime)],
                ...allBreaks,
                ['End Time: ', intToTime(object.endTime)],
            ];
            break;
        case 'Time Off':
            items = [
                ['Start Date: ', moment(object.startDate).format('MMM D, YYYY')],
                ['End Date: ', moment(object.endDate).format('MMM D, YYYY')],
                ['Reason: ', object?.notes ?? ''],
                ['Submission Date: ', moment(object.submissionDate).format('MMM D, YYYY')]
            ];
            break;
        default:
            items = [];
            break;
    }
    
    const popover = (
        <Popover style={{position:'fixed', maxWidth: 1000}}>
            <Popover.Header style={{fontSize: '1.1vmax', backgroundColor: 'white', color: 'black'}}>{object.title ? object.title : `${status} Time Off Request`}</Popover.Header>
            <Popover.Body>
                <List items={items} responsive={true}/>
            </Popover.Body>
        </Popover>
    );

    return (
        <div className="cursor-pointer" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 4, border: `2px solid ${color}`, backgroundColor: 'white', borderRadius: 6}}>
            <OverlayTrigger placement={popup ? 'top' : 'bottom'} overlay={popover}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingLeft: 6, paddingRight: 6}}>
                    <p style={{margin: 0, color: 'black', opacity: 0.7, fontSize: '1.1vmax'}}>{title}</p>
                    <FontAwesomeIcon icon={icon} style={{height: '1.1vmax', color: iconColor ? iconColor : 'black', opacity: 0.5}}/>
                </div>
            </OverlayTrigger>
        </div>
    )
}

export default CalendarObject;