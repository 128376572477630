/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { Button, InputGroup, ListGroup, Modal } from "react-bootstrap";
import CustomControl from "../../components/CustomControl";
import CustomButton from "../../components/CustomButton";
import { createScheduleTemplate, deleteScheduleTemplate, importScheduleTemplate, overwriteScheduleTemplate, renameScheduleTemplate } from "../../services/SchedulingService";
import AlertModal from "../../components/AlertModals/AlertModal";
import moment from "moment";
import { AppGlobals } from "../../App";
import CustomAlert from "../../components/CustomAlert";

export default function ScheduleTemplateEditor({scheduleTemplates, handleScheduleTemplateCrud, shifts, locationIdentifier, startOfWeek, loadData}) {

    const [selectedScheduleTemplate, setSelectedScheduleTemplate] = useState(undefined);
    const [isCreating, setIsCreating] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);

    const [name, setName] = useState('');

    const [rename, setRename] = useState('');

    const [modalSwitch, setModalSwitch] = useState('');

    const handleCreateTemplate = async () => {
        setIsCreating(true);
        const templateShifts = shifts.map((shift) => {
            return {
                userIdentifier: shift.userIdentifier,
                dayIndex: moment(shift.date).format('d'),
                startTime: shift.startTime,
                endTime: shift.endTime,
                shiftTypeIdentifier: shift.shiftTypeIdentifier,
                breaks: shift.breaks
            }
        });

        const scheduleTemplate = {
            name: name,
            locationIdentifier: locationIdentifier,
            shifts: templateShifts
        }
        const response = await createScheduleTemplate(scheduleTemplate);

        if (response.status === 200) {
            scheduleTemplate.uid = response.uid;
            handleScheduleTemplateCrud('create', scheduleTemplate);

        }
        setIsCreating(false);
    }

    const handleOverwriteTemplate = async () => {
        const templateShifts = shifts.map((shift) => {
            return {
                userIdentifier: shift.userIdentifier,
                dayIndex: moment(shift.date).format('d'),
                startTime: shift.startTime,
                endTime: shift.endTime,
                shiftTypeIdentifier: shift.shiftTypeIdentifier,
                breaks: shift.breaks
            }
        });

        const scheduleTemplate = {
            uid: selectedScheduleTemplate,
            shifts: templateShifts
        }
        const response = await overwriteScheduleTemplate(scheduleTemplate);

        if (response.status === 200) {
            AppGlobals.alert(<CustomAlert type='success' title={response.message}/>);
            return true;
        }
        return false;
    }

    const handleRenameTemplate = async () => {
        setIsRenaming(true);

        const template = {uid: selectedScheduleTemplate, name: rename};

        const response = await renameScheduleTemplate(template);

        if (response.status === 200) {
            handleScheduleTemplateCrud('update', template);
            setModalSwitch('');
        }

        setIsRenaming(false);
    }

    const handleImportScheduleTemplate = async () => {
        const response = await importScheduleTemplate(selectedScheduleTemplate, moment(startOfWeek).format('YYYY-MM-DD'), moment(startOfWeek).add(6, 'days').format('YYYY-MM-DD'));
        if (response.status === 200) {
            loadData();
            return true;
        }
        return false;
    }

    const handleDeleteScheduleTemplate = async () => {
        const response = await deleteScheduleTemplate(selectedScheduleTemplate);
        if (response.status === 200) {
            handleScheduleTemplateCrud('delete', selectedScheduleTemplate);
            setSelectedScheduleTemplate(undefined);
            return true;
        }
        return false;
    }


    const scheduleTemplateElements = scheduleTemplates.map((st) => {
        return (
            <ListGroup.Item key={st.uid} action active={selectedScheduleTemplate === st.uid} onClick={() => {setSelectedScheduleTemplate(st.uid)}}>
                {st.name}
            </ListGroup.Item>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Schedule Templates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup style={{marginBottom: 12}}>
                    <CustomControl title='Template Name' value={name} setValue={setName} style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}/>
                    <CustomButton disabled={!name} isLoading={isCreating} label='Create New Template From Schedule' onClick={handleCreateTemplate}/>
                </InputGroup>
                <ListGroup>
                    {scheduleTemplateElements}
                </ListGroup>

            </Modal.Body>
            { selectedScheduleTemplate && 
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={() => {setModalSwitch('delete')}}>Delete Template</Button>

                    <CustomButton label='Overwrite Template' onClick={() => {setModalSwitch('overwrite')}}/>
                    <CustomButton label='Rename Template' onClick={() => {setModalSwitch('rename'); setRename(scheduleTemplates.find(t => t.uid === selectedScheduleTemplate)?.name)}}/>
                    <CustomButton label='Import Template' onClick={() => {setModalSwitch('import')}}/>
                </Modal.Footer>
            }
            
            <AlertModal 
                show={modalSwitch === 'overwrite'} 
                onHide={() => {setModalSwitch('')}} 
                title={'Overwrite Schedule Template With Current Schedule?'} 
                message={'The selected schedule template will be updated to reflect the shifts which are currently scheduled for the selected location/week. This cannot be undone.'} 
                buttonLabel={'Overwrite Schedule Template'} 
                callBack={handleOverwriteTemplate}
            />
            <AlertModal 
                show={modalSwitch === 'import'} 
                onHide={() => {setModalSwitch('')}} 
                title={'Import Schedule Template Into Selected Week?'} 
                message={'Importing a schedule template will override any preexisting shifts scheduled for the week. This cannot be undone.'} 
                buttonLabel={'Import Schedule Template'} 
                callBack={handleImportScheduleTemplate}
            />
            <AlertModal 
                show={modalSwitch === 'delete'} 
                onHide={() => {setModalSwitch('')}} 
                title={'Delete Schedule Template?'} 
                message={'This cannot be undone.'} 
                buttonLabel={'Delete Schedule Template'} 
                callBack={handleDeleteScheduleTemplate}
            />
            <Modal show={modalSwitch === 'rename'} onHide={() => {setModalSwitch('')}}>
                <Modal.Header closeButton>
                    <Modal.Title>Rename Schedule Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CustomControl title={'New Template Name'} value={rename} setValue={setRename} floatingLabel/>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton isLoading={isRenaming} disabled={!rename} label='Submit' onClick={handleRenameTemplate}/>
                </Modal.Footer>
            </Modal>
        </>
    )
}