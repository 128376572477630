/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Dashboard from './views/Dashboard/Dashboard';
import Profile from './views/Profile/Profile';
import MySchedule from './views/MySchedule/MySchedule';
import DaysOff from './views/DaysOff/DaysOff';
import { Switch, Route } from 'react-router-dom';
import ErrorPage from './views/ErrorPage/ErrorPage';
import Scheduling from './views/Scheduling/Scheduling';
import HumanResources from './views/HumanResources/HumanResources';
import Payroll from './views/Payroll/Payroll';
import MyCompany from './views/MyCompany/MyCompany';
import MyDocuments from './views/MyDocuments/MyDocuments';
import TimeClock from './views/TimeClock/TimeClock';
import Cookies from 'universal-cookie';
import Reports from './views/Reports/Reports';


export default function Router({mobileView}) {
    const cookies = new Cookies();
    const permissions = cookies.get('userData').pay.permissions;
    const timeclockEnabled = cookies.get('timeclockEnabled');

    const hrPermissions = 
        permissions.hrHire
        || permissions.hrTerminate
        || permissions.hrProfile
        || permissions.hrTimesheet
        || permissions.hrSchedule
        || permissions.hrPay
        || permissions.hrPto
        || permissions.hrLoans
        || permissions.hrDocuments
        || permissions.hrPermissions
    ;

    const reportsPermissions = permissions.hrTimesheet == 1;

    return (
        <Switch>
            <Route exact path='/' render={props => (
                <Page key={1} title='MTB - Dashboard'>
                    <Dashboard {...props}/>
                </Page>
            )}/>
            <Route path='/profile' render={props => (
                <Page key={2} title='MTB - Profile'>
                    <Profile {...props}/>
                </Page>
            )}/>
            { timeclockEnabled == 1 &&
                <Route path='/timeclock' render={props => (
                    <Page key={2} title='MTB - Timeclock'>
                        <TimeClock mobileView={mobileView} {...props}/>
                    </Page>
                )}/>
            }
            <Route path='/my-schedule' render={props => (
                <Page key={4} title='MTB - My Schedule'>
                    <MySchedule {...props}/>
                </Page>
            )}/>
            <Route key={5} path='/days-off' render={props => (
                <Page title='MTB - Days Off'>
                    <DaysOff {...props}/>
                </Page>
            )}/>
            <Route key={5} path='/my-documents' render={props => (
                <Page title='MTB - My Documents'>
                    <MyDocuments {...props}/>
                </Page>
            )}/>
            { permissions.myCompany == 1 && 
                <Route path='/my-company' render={props => (
                    <Page key={4} title='MTB - My Company'>
                        <MyCompany/>
                    </Page>
                )}/>
            }
            { permissions.scheduling == 1 && 
                <Route path='/scheduling' render={props => (
                    <Page key={4} title='MTB - Scheduling'>
                        <Scheduling {...props}/>
                    </Page>
                )}/>
            }
            { permissions.payroll == 1 && 
                <Route key={6} path='/payroll' render={props => (
                    <Page title='MTB - Payroll'>
                        <Payroll/>
                    </Page>
                )}/>
            }
            { hrPermissions && 
                <Route key={6} path='/human-resources' render={props => (
                    <Page title='MTB - Human Resources'>
                        <HumanResources/>
                    </Page>
                )}/>
            }
            { reportsPermissions && 
                <Route key={6} path='/reports' render={props => (
                    <Page title='MTB - Reports'>
                        <Reports/>
                    </Page>
                )}/>
            }
            

            <Route path='*' component={ErrorPage}/>
        </Switch>
    )
}

class Page extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = this.props.title;
    }

    render() {
        return (
            <>{this.props.children}</>
        )
    }
}