/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import { Card, Form, Button, Container, Row, Col, Toast } from 'react-bootstrap';
import './Login.css';
import { useState } from 'react';
import logo from '../../assets/TTA-Logo-150-white.png';
import React from 'react';
import { Formik} from 'formik';
import Cookies from 'universal-cookie';
import MFA from '../MFA/MFA';
import Modal from 'react-bootstrap/Modal';
import ForgotPassword from './ForgotPassword';
import CustomControl from '../../components/CustomControl';
import { ValidationGroup, validateEmail, validateExistence } from '../../validation';
import CustomButton from '../../components/CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import truckTiles from '../../assets/truck-tiled.png';
import { login } from '../../services/AuthenticationService';



function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [alertContent, setAlertContent] = useState('');

    const [showMFA, setShowMFA] = useState(false);
    const [tokenIdentifier, setTokenIdentifier] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [showForgotPassword, setShowForgotPassword] = useState(false);


    const validationGroup = new ValidationGroup();

    const handleSubmit = async (event) => {
        event?.preventDefault();
        setIsSubmitting(true);
        setError(undefined);

        const response = await login(email, password);

        if (response.status === 200) {
            if (response.token) {
                new Cookies().set('token', response.token, {path: '/'});
                window.location.reload();

            } else if (response.tokenIdentifier) {
                setTokenIdentifier(response.tokenIdentifier);
                setShowMFA(true);
            }
        } else {
            setError(response.message);
        }


        setIsSubmitting(false);
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: 8}}>
            <div className='login-background' style={{position: 'absolute', width: '100%', height: '100%', opacity: 0.03}}>

            </div>

            {/* <img src={truckTiles} style={{backgroundRepeat: 'repeat', position: 'absolute', height: '100%', width: '100%'}}/> */}
            <Card style={{maxWidth: '100%', display: 'flex', flexDirection: 'row', width: 800, minHeight: 600, border: '1px solid var(--bs-primary)', borderRadius: 8}}>
                <Container fluid>
                    <Row style={{height: '100%'}}>
                        <Col md={6} style={{padding: 0}}>
                            <Card.Body className='login-lhs-card' style={{padding: 32, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'var(--bs-primary)', textAlign: 'center'}}>
                                <img src={logo} style={{padding: 8, maxWidth: '100%'}}/>
                                {/* <FontAwesomeIcon icon={faTruck} size='5x' style={{color: 'white'}}/> */}
                                <span style={{color: 'white', opacity: 0.9, fontWeight: 'bold'}}>A comprehensive solution to your business needs</span>
                            </Card.Body>
                        </Col>
                        <Col md={6} style={{padding: 0}}> 
                            <Card.Body style={{height: '100%'}}>
                                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 12, height: '100%', padding: 32}}>
                                    <div style={{textAlign: 'center'}}>
                                        <h1>Hello!</h1>
                                        <span style={{color: 'gray'}}>Please login below.</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                                        <CustomControl floatingLabel validator={validationGroup.createValidator(() => validateEmail(email))} title='Email Address' value={email} setValue={setEmail}/>
                                        <CustomControl floatingLabel type='password' validator={validationGroup.createValidator(() => validateExistence(password))} title='Password' value={password} setValue={setPassword}/>
                                    
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: 6, textAlign: 'center'}}>
                                        <CustomButton outline isLoading={isSubmitting} label='Login' onClick={handleSubmit}/>
                                        <span style={{color: 'red'}}>{error}</span>
                                    </div>
                                    <Button style={{background:'none', backgroundColor:'none', boxShadow:'none', border:'none', color: 'var(--bs-primary)'}} onClick={()=>setShowForgotPassword(true)}>Forgot Password?</Button>
                                </form>
                            </Card.Body>
                        </Col>

                    </Row>
                </Container>
            </Card>
            <Modal show={showMFA} backdrop='static' onHide={() => {setShowMFA(false)}} centered size='lg'>
                <MFA tokenIdentifier={tokenIdentifier}/>
            </Modal>
            <Modal show={showForgotPassword} onHide={()=>{setShowForgotPassword(false)}}>
                <ForgotPassword setAlertContent={setAlertContent} hideModal={()=>setShowForgotPassword(false)}/>
            </Modal>
            <Modal className='invisible-modal' show={alertContent} onHide={()=>setAlertContent(undefined)}>
                <Toast show={alertContent} onClose={()=>setAlertContent(undefined)}>
                    {alertContent}
                </Toast>
            </Modal>
        </div>
    )
}

export default Login;