/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { toFixedMax } from '../Payroll/payrollTools';
import { Form, InputGroup } from 'react-bootstrap';
import React from 'react';

export default function TimesheetList({timesheetData, selectedDate, setSelectedDate}) {

    const startOfMonth = moment(selectedDate).startOf('month').startOf('week').format('YYYY-MM-DD');


    const timesheetCards = Array.from(timesheetData).sort((a, b) => {
        if (a.inPunch < b.inPunch) {
            return 1;
        } else if (a.inPunch > b.inPunch) {
            return -1;
        } else {
            return 0;
        }
    }).map((entry) => {
        return (
            <div key={entry.uid}>
                <p style={{marginBottom: 4}}>{moment(entry.inPunch).format('MMMM D')}</p>
                <div style={{backgroundColor: 'white', display: 'flex', flexDirection: 'column', textAlign: 'start', paddingLeft: 4, paddingRight: 4, border: '1px solid var(--bs-primary)', borderLeft: '6px solid var(--bs-primary)', borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
                        
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2}}>
                        <span style={{color: 'black'}}>{ moment(entry.inPunch).format('h:mm A')}</span>
                        <FontAwesomeIcon icon={faArrowRight}/>
                        <span style={{color: 'black'}}>{moment(entry.outPunch).format('h:mm A')}</span>
                    </div>
                    <span style={{fontWeight: 'bold', opacity: 0.7, textAlign: 'center'}}>{'Total: ' + toFixedMax(moment(entry.outPunch).diff(moment(entry.inPunch), 'hours', true), 2) + ' Hours'}</span>
                            
                </div>
            </div>
        )
    })

   

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, height: '100%'}}>
            <InputGroup style={{margin: 8}}>
                <Button variant={'outline-primary'} onClick={() => {setSelectedDate(moment(selectedDate).subtract(1, 'month').format('YYYY-MM-DD'))}}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </Button>
                <Form.Control type='month' value={moment(selectedDate).format('YYYY-MM')} onChange={(event) => {setSelectedDate(`${event.target.value}-01`)}}/>
                <Button variant={'outline-primary'} onClick={() => {setSelectedDate(moment(selectedDate).add(1, 'month').format('YYYY-MM-DD'))}}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </Button>
            </InputGroup>
            
            
            <div style={{width: '100%', flex: 1, display: 'flex', flexDirection: 'column', padding: 4, gap: 12}}>
                {timesheetCards}
            </div>
            
        </div>
    );
}