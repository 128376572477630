import React, { useEffect, useState } from 'react'
import { getEmployeeSchedule } from '../../../services/HumanResourcesService';
import moment from 'moment';
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import QuickTable from '../../../components/QuickTable';
import PageSpinner from '../../../components/PageSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import HRSchedulePendingRequest from './HRSchedulePendingRequest';
import HRScheduleTimeOffCreator from './HRScheduleTimeOffCreator';
import HRScheduleCallOffCreator from './HRScheduleCallOffCreator';

export default function HRSchedule({selectedEmployee}) {
    const [isLoading, setIsLoading] = useState(false);
    const [timeOff, setTimeOff] = useState([]);
    const [callOffs, setCallOffs] = useState([]);

    const [selectedItem, setSelectedItem] = useState(undefined);
    const [modalSwitch, setModalSwitch] = useState('');

    const loadData = async () => {
        setIsLoading(true);
        const response = await getEmployeeSchedule(selectedEmployee.uid);
        if(response.status == '200'){
            setTimeOff(response.timeOff);
            setCallOffs(response.callOffs);
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        loadData();
    }, [selectedEmployee])

    const sortTimeOffAsc = (a, b) => {
        return a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0;
    }
    const sortItemsDesc = (a, b) => {
        const aDate = a.startDate ?? a.date;
        const bDate = b.startDate ?? b.date;
        return aDate < bDate ? 1 : aDate > bDate ? -1 : 0;
    }

    const handleAnswerRequest = (uid, status) => {
        const newTimeOff = Array.from(timeOff);
        newTimeOff.find(t => t.uid == uid).status = status;
        setTimeOff(newTimeOff);
        setModalSwitch('');
    }

    const handleCreateTimeOff = (ptoArray, item) => {
        const newTimeOff = Array.from(timeOff);
        newTimeOff.push(item);
        setTimeOff(newTimeOff);
        setModalSwitch('');
    }

    const handleCreateCallOff = (callOff) => {
        const newCallOffs = Array.from(callOffs);
        newCallOffs.push(callOff);
        setCallOffs(newCallOffs);
        setModalSwitch('');
    }


    const pendingRequestRows = timeOff.filter(t => t.status == 0 && t.startDate > moment().format('YYYY-MM-DD')).sort(sortTimeOffAsc).map((timeOff) => {
        return (
            <tr key={timeOff.uid} className='cursor-pointer' onClick={() => {setSelectedItem(timeOff); setModalSwitch('pendingRequest');}}>
                <td>{moment(timeOff.startDate).format('MMM D, YYYY')}</td>
                <td>{moment(timeOff.endDate).format('MMM D, YYYY')}</td>
                <td>{moment(timeOff.submissionDate).format('MMM D, YYYY')}</td>
                <td>
                    <OverlayTrigger overlay={
                        <Popover style={{position: 'fixed'}}>
                            <Popover.Header>Notes</Popover.Header>
                            <Popover.Body>{timeOff.notes}</Popover.Body>
                        </Popover>
                    }>
                        <FontAwesomeIcon style={{color: 'var(--bs-primary)'}} icon={faInfoCircle}/>
                    </OverlayTrigger>
                </td>
            </tr>
        )
    })

    const currentAndFutureTimeOffRows = timeOff.filter(t => t.status == 1 && t.endDate >= moment().format('YYYY-MM-DD')).sort(sortTimeOffAsc).map((timeOff) => {
        return (
            <tr key={timeOff.uid}>
                <td>{moment(timeOff.startDate).format('MMM D, YYYY')}</td>
                <td>{moment(timeOff.endDate).format('MMM D, YYYY')}</td>
                <td>{moment(timeOff.submissionDate).format('MMM D, YYYY')}</td>
                <td>
                    <OverlayTrigger overlay={
                        <Popover style={{position: 'fixed'}}>
                            <Popover.Header>Notes</Popover.Header>
                            <Popover.Body>{timeOff.notes}</Popover.Body>
                        </Popover>
                    }>
                        <FontAwesomeIcon style={{color: 'var(--bs-primary)'}} icon={faInfoCircle}/>
                    </OverlayTrigger>
                </td>
            </tr>
        )
    })

    const historyRows = timeOff.filter(t => t.status == -1 || t.endDate < moment().format('YYYY-MM-DD') || (t.status != 1 && t.startDate <= moment().format('YYYY-MM-DD'))).concat(callOffs).sort(sortItemsDesc).map((item) => {
        if (item.endDate) {
            return (
                <tr key={'timeOff' + item.uid}>
                    <td>{`${moment(item.startDate).format('MMM D, YYYY')} - ${moment(item.endDate).format('MMM D, YYYY')}`}</td>
                    <td>{item.status == 0 ? 'Expired Time Off Request' : item.status == 1 ? 'Accepted Time Off Request' : 'Rejected Time Off Request'}</td>
                    <td>{moment(item.submissionDate).format('MMM D, YYYY')}</td>
                    <td>
                        <OverlayTrigger overlay={
                            <Popover style={{position: 'fixed'}}>
                                <Popover.Header>Notes</Popover.Header>
                                <Popover.Body>{item.notes}</Popover.Body>
                            </Popover>
                        }>
                            <FontAwesomeIcon style={{color: 'var(--bs-primary)'}} icon={faInfoCircle}/>
                        </OverlayTrigger>
                    </td>
                </tr>
            )
        } else {
            return (
                <tr key={'callOff' + item.uid}>
                    <td>{moment(item.date).format('MMM D, YYYY')}</td>
                    <td>{item.notifiedManager ? 'Call-Off' : 'No-Show'}</td>
                    <td>{moment(item.submissionDate).format('MMM D, YYYY')}</td>
                    <td>
                        <OverlayTrigger overlay={
                            <Popover style={{position: 'fixed'}}>
                                <Popover.Header>Notes</Popover.Header>
                                <Popover.Body>{item.notes}</Popover.Body>
                            </Popover>
                        }>
                            <FontAwesomeIcon style={{color: 'var(--bs-primary)'}} icon={faInfoCircle}/>
                        </OverlayTrigger>
                    </td>
                </tr>
            )
        }
    })

    const content = (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 12}}>
            <div style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                <Button variant='outline-primary' onClick={() => {setModalSwitch('createTimeOff')}}>Schedule Time Off</Button>
                <Button variant='outline-primary' onClick={() => {setModalSwitch('createCallOff')}}>Report Call-Off / No-Show</Button>
            </div>
            <QuickTable title='Pending Time Off Requests' headers={['Start Date', 'End Date', 'Submission Date', 'Notes']} widths={[null, null, null, 1]} rows={pendingRequestRows} hover/>
            <QuickTable title='Current & Upcoming Time Off' headers={['Start Date', 'End Date', 'Submission Date', 'Notes']} widths={[null, null, null, 1]} rows={currentAndFutureTimeOffRows}/>
            <QuickTable title='Time Off & Call-Off History' headers={['Date', 'Type', 'Submission Date', 'Notes']} widths={[null, null, null, 1]} rows={historyRows}/>
            <Modal show={modalSwitch === 'pendingRequest'} onHide={() => {setModalSwitch('')}}>
                <HRSchedulePendingRequest selectedTimeOff={selectedItem} handleAnswerRequest={handleAnswerRequest}/>
            </Modal>
            <Modal show={modalSwitch === 'createTimeOff'} onHide={() => {setModalSwitch('')}}>
                <HRScheduleTimeOffCreator userIdentifier={selectedEmployee.uid} handleCreateTimeOff={handleCreateTimeOff}/>
            </Modal>
            <Modal show={modalSwitch === 'createCallOff'} onHide={() => {setModalSwitch('')}}>
                <HRScheduleCallOffCreator userIdentifier={selectedEmployee.uid} handleCreateCallOff={handleCreateCallOff}/>
            </Modal>
        </div>
    )

    return isLoading ? <PageSpinner/> : content;
}
