/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import Popover from "react-bootstrap/Popover";
import PageSpinner from '../../components/PageSpinner';
import CalendarObject from './CalendarObject';
import Form from 'react-bootstrap/Form';
import getSchedule from '../../services/MyScheduleService';
import CustomButton from '../../components/CustomButton';
import { Button } from 'react-bootstrap';
import { faPlaneDeparture, faUserClock, faCheck, faX, faHourglass} from "@fortawesome/free-solid-svg-icons";

function getDaysOfTheWeek(date) {
    const startOfWeek = moment(date).startOf('week');
    const daysOfWeek = [
        moment(startOfWeek), 
        moment(startOfWeek).add(1, 'days'), 
        moment(startOfWeek).add(2, 'days'), 
        moment(startOfWeek).add(3, 'days'), 
        moment(startOfWeek).add(4, 'days'), 
        moment(startOfWeek).add(5, 'days'), 
        moment(startOfWeek).add(6, 'days'),
    ];
    return daysOfWeek;
}

class MySchedule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            selectedDate: moment().startOf('month'),
            scheduleItems: [],
        }
        this.handleSetDate = this.handleSetDate.bind(this);
    }


    loadData() {
        this.setState({isLoading: true});
        
        getSchedule(moment(this.state.selectedDate).startOf('month').format('YYYY-MM-DD'), moment(this.state.selectedDate).endOf('month').format('YYYY-MM-DD')).then((response) => {
            if (response.status == '200') {
                this.setState({scheduleItems: response});
            }
            this.setState({isLoading: false});
        });
    }

    componentDidMount() {
        this.loadData();
    }

    handleSetDate(value) {
        this.setState({selectedDate: value}, () => {
            this.loadData();
        });
    }

    render() { 
        const rows = [0, 1, 2, 3, 4, 5].map((rowIndex) => {
    
            const columns = getDaysOfTheWeek(moment(this.state.selectedDate).add(rowIndex, 'weeks')).map((date, colIndex) => {
    
                const shifts = this.state.scheduleItems?.shifts?.filter((entry) => {
                    if (entry.endTime) {
                        return entry.date === date.format('YYYY-MM-DD');
                    }
                });

                const timeOff = this.state.scheduleItems?.timeOff?.filter((entry) => {
                    if (entry.endDate) {
                        return entry.date === date.format('YYYY-MM-DD') || date.isBetween(moment(entry.startDate), moment(entry.endDate), 'day', '[]');
                    } else {
                        return entry.date === date.format('YYYY-MM-DD');
                    }
                });

    
                let shiftObjects = shifts?.map((shift) => {
                    return <CalendarObject key={shift.uid} popup={rowIndex > 2} object={shift} title={shift.title} type={shift.shiftIdentifier} color={`#${shift.color}`} icon={faUserClock}/>;
                    
                });

                let timeObjects = timeOff?.map((timeOff) => {

                    let icon = faCheck;
                    let status = 'Accepted';
                    let iconColor = 'green';

                    if(timeOff.status == -1){
                        icon = faX;
                        status = 'Rejected';
                        iconColor = 'red';
                    }
                        if(timeOff.status == 0){

                        icon = faHourglass;
                        status = "Pending";
                        iconColor = 'gray';
                    }
                    
                    return <CalendarObject key={timeOff.uid} popup={rowIndex > 2} object={timeOff} type={'timeOff'} title={'Time Off'} color={'lightgray'} status={status} icon={icon} iconColor={iconColor}/>;
                    
                });
            
    
                return (
                    <td key={colIndex} style={{padding: 0}} className={'calendar-td' + (date.format('MMM D') === moment().format('MMM D') ? ' calendar-td-selected' : '') + (date.format('MMM') !== moment(this.state.selectedDate).format('MMM') ? ' calendar-td-off-month' : '')}>
                        <div style={{display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', padding: 4, backgroundColor: date.format('MMM D') === moment().format('MMM D') ? 'rgba(238, 255, 0, 0.3)' : ''}}>
                            <p style={{alignSelf: 'flex-end', fontSize: '1.5vmax', marginBottom: 0}}>{date.format('D')}</p>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                                {timeObjects}{shiftObjects}
                            </div>
                        </div>
                    </td>
                );
            });
    
            return (
                <tr key={rowIndex} style={{height: '16.66%'}}>
                    {columns}
                </tr>
            );
        });
    
        const columnHeaders = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((str) => {
            return (<th key={str} className='calendar-th' style={{fontSize: '1vmax', width: '14.29%'}}>{str}</th>);
        });
    

        const content = (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', margin: 8}}>
                    <Button variant='outline-primary' onClick={() => this.handleSetDate(moment(this.state.selectedDate).subtract(1, 'month').format('YYYY-MM-DD'))}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                    </Button>
                    <Form.Control style={{marginRight: 12, marginLeft: 12}} type='month' value={moment(this.state.selectedDate).format('YYYY-MM')} onChange={(event) => {this.handleSetDate(moment(event.target.value).startOf('month').format('YYYY-MM-DD'))}}/>
                    
                    <Button variant='outline-primary' onClick={() => this.handleSetDate(moment(this.state.selectedDate).add(1, 'month').format('YYYY-MM-DD'))}>
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </Button>
                </div>
                
                
                <div style={{width: '100%', flex: 1}}>
                    <Table bordered size='sm' style={{width: '100%', height: '100%'}}>
                        <thead>
                            <tr>
                                {columnHeaders}
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </div>
                
            </div>
        )
    
        return (
            <div className='page-wrapper' style={{padding: 12}}>
                {this.state.isLoading ? <PageSpinner/> : content}
            </div>
        );
    }
}

export default MySchedule;