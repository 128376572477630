/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function checkAuthentication() {
    const data = {
        type: 'authentication',
        target: 'checkAuthentication',
        token: getToken()
    }

    return await getResponse(data);
}

export async function login(email, password) {
    const data = {
        type: 'authentication',
        target: 'login',
        email: email,
        password: password
    }
    return await getResponse(data);
}

export async function authenticateMFA(tokenIdentifier, mfa) {
    const data = {
        type: 'authentication',
        target: 'mfa',
        tokenIdentifier: tokenIdentifier,
        mfa: mfa
    }
    return await getResponse(data);
}

export async function logout() {
    const data = {
        type: 'authentication',
        target: 'logout',
        token: getToken()
    }
    return await getResponse(data);
}