/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getAllMyDocuments() {
    const data = {
        type: 'myDocuments',
        target: 'getAll',
        token: getToken(),
    }
    
    return await getResponse(data);
}

export async function getMyDocument(uid) {
    const data = {
        type: 'myDocuments',
        target: 'get',
        token: getToken(),
        uid: uid
    }
    
    return await getResponse(data);
}

export async function acknowledgeDocuments(uids) {
    const data = {
        type: 'myDocuments',
        target: 'acknowledge',
        token: getToken(),
        uids: uids
    }
    
    return await getResponse(data);
}