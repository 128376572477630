/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

////////////////
/// PROFILE
////////////////
export async function getCompanyResources() {
    const data = {
        type: 'myCompany',
        target: 'get',
        token: getToken()
    }

    const response = await getResponse(data);
    return response;
}

export async function getCompanyProfile() {
    const data = {
        type: 'myCompany',
        target: 'getProfile',
        token: getToken()
    }
    return await getResponse(data);
}

export async function updateCompanyProfile(company) {
    const data = {
        type: 'myCompany',
        target: 'updateProfile',
        token: getToken(),
        company
    }

    return await getResponse(data);
}

////////////////
/// LOCATIONS
////////////////
export async function getLocations() {
    const data = {
        type: 'myCompany',
        target: 'getLocations',
        token: getToken()
    }

    return await getResponse(data);
}

export async function createLocations(locationsArray) {
    const data = {
        type: 'myCompany',
        target: 'createLocations',
        token: getToken(),
        locationsArray
    }

    return await getResponse(data);
}

export async function updateLocation(location) {
    const data = {
        type: 'myCompany',
        target: 'updateLocation',
        token: getToken(),
        location
    }

    return await getResponse(data);

}

////////////////
/// DEVICES
////////////////
export async function getDevices(){
    const data = {
        type: 'myCompany',
        target: 'getDevices',
        token: getToken(),
    }

    return await getResponse(data);
}

export async function createDevice(device){
    const data = {
        type: 'myCompany',
        target: 'createDevice',
        token: getToken(),
        device
    }

    return await getResponse(data);
}

export async function updateDevice(device){
    const data = {
        type: 'myCompany',
        target: 'updateDevice',
        token: getToken(),
        device
    }
    return await getResponse(data);
}

export async function deleteDevice(deviceIdentifier){
    const data = {
        type: 'myCompany',
        target: 'deleteDevice',
        token: getToken(),
        deviceIdentifier
    }
    return await getResponse(data);
}

////////////////
/// Documents
////////////////

export async function getAllCompanyDocuments() {
    const data = {
        type: 'myCompany',
        target: 'getAllDocuments',
        token: getToken(),
    }
    return await getResponse(data);
}

export async function getCompanyDocument(uid) {
    const data = {
        type: 'myCompany',
        target: 'getDocument',
        token: getToken(),
        uid: uid
    }
    return await getResponse(data);
}

export async function createCompanyDocument(document) {
    const data = {
        type: 'myCompany',
        target: 'createDocument',
        token: getToken(),
        document: document
    }
    return await getResponse(data);
}

export async function updateCompanyDocument(document) {
    const data = {
        type: 'myCompany',
        target: 'updateDocument',
        token: getToken(),
        document: document
    }
    return await getResponse(data);
}

export async function deleteCompanyDocument(uid) {
    const data = {
        type: 'myCompany',
        target: 'deleteDocument',
        token: getToken(),
        uid: uid
    }
    return await getResponse(data);
}

///////////////////
/// Offer Letters
///////////////////

export async function createOfferLetter(offerLetter){
    const data = {
        type: 'myCompany',
        target: 'createOfferLetter',
        token: getToken(),
        offerLetter
    }
    return await getResponse(data);
}

export async function updateOfferLetter(offerLetter){
    const data = {
        type: 'myCompany',
        target: 'updateOfferLetter',
        token: getToken(),
        offerLetter
    }
    return await getResponse(data);
}

export async function deleteOfferLetter(uid){
    const data = {
        type: 'myCompany',
        target: 'deleteOfferLetter',
        token: getToken(),
        uid
    }
    return await getResponse(data);
}