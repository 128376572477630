/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import React from "react";
import { Button, ButtonGroup, Dropdown, InputGroup, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import SchedulingTableChronological from "./SchedulingTable/SchedulingTableChronological";
import { getSchedule } from "../../services/SchedulingService";
import PageSpinner from "../../components/PageSpinner";
import Cookies from "universal-cookie";
import ShiftTypeEditor from "./ShiftTypeEditor/ShiftTypeEditor";
import SchedulingWeekSelector from "./SchedulingWeekSelector";
import ShiftEditor from "./ShiftEditor/ShiftEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus } from "@fortawesome/free-solid-svg-icons";
import SchedulingTableCatagorized from "./SchedulingTable/SchedulingTableCatagorized";
import CustomTabs from "../../components/CustomTabs";
import ShiftTemplateEditor from "./ShiftTemplateEditor";
import SwitchControl from "../../components/SwitchControl";
import ScheduleTemplateEditor from "./ScheduleTemplateEditor";

export default class Scheduling extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            selectedDate: moment().startOf('week').format('YYYY-MM-DD'),
            selectedLocation: new Cookies().get('userData').locationIdentifier,
            selectedShift: undefined,
            selectedShiftDate: undefined,
            modalSwitch: '',
            viewSwitch: 0,
            collapseEvents: false,

            locations: [],
            employees: [],
            shifts: [],
            shiftTypes: [],
            shiftTemplates: [],
            scheduleTemplates: [],
            callOffs: [],
            timeOff: [],
        }

        this.loadData = this.loadData.bind(this);
        this.handleSetShiftTypes = this.handleSetShiftTypes.bind(this);
        this.handleShiftCrud = this.handleShiftCrud.bind(this);
        this.handleShiftTemplateCrud = this.handleShiftTemplateCrud.bind(this);
        this.handleScheduleTemplateCrud = this.handleScheduleTemplateCrud.bind(this);
        this.handleSelectShift = this.handleSelectShift.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        this.setState({isLoading: true});

        const startDate = moment(this.state.selectedDate).startOf('week').format('YYYY-MM-DD');
        const endDate = moment(startDate).add(6, 'days').format('YYYY-MM-DD');

        const response = await getSchedule(this.state.selectedLocation, startDate, endDate);

        if (response.status === 200) {
            this.setState({
                locations: response.locations, 
                employees: response.employees, 
                shifts: response.shifts, 
                shiftTypes: response.shiftTypes, 
                shiftTemplates: response.shiftTemplates,
                scheduleTemplates: response.scheduleTemplates,
                callOffs: response.callOffs, 
                timeOff: response.timeOff
            });
        }

        this.setState({isLoading: false});

    }


    handleSetShiftTypes(shiftTypes) {
        this.setState({shiftTypes: shiftTypes});
    }

    handleShiftCrud(type, data) {
        let newShifts = Array.from(this.state.shifts);
        if (type === 'create') {
            newShifts.push(data);
        } else if (type === 'update') {
            newShifts = newShifts.filter(s => s.uid !== data.uid);
            newShifts.push(data);
        } else if (type === 'delete') {
            newShifts = newShifts.filter(s => s.uid !== data);
        }
        this.setState({shifts: newShifts, modalSwitch: ''});
    }

    handleShiftTemplateCrud(type, data) {
        let newShiftTemplates = Array.from(this.state.shiftTemplates);
        if (type === 'create') {
            newShiftTemplates.push(data);
        } else if (type === 'update') {
            newShiftTemplates = newShiftTemplates.filter(s => s.uid !== data.uid);
            newShiftTemplates.push(data);
        } else if (type === 'delete') {
            newShiftTemplates = newShiftTemplates.filter(s => s.uid !== data);
        }
        this.setState({shiftTemplates: newShiftTemplates});
    }

    handleScheduleTemplateCrud(type, data) {
        let newScheduleTemplates = Array.from(this.state.scheduleTemplates);
        if (type === 'create') {
            newScheduleTemplates.push(data);
        } else if (type === 'update') {
            newScheduleTemplates = newScheduleTemplates.filter(s => s.uid !== data.uid);
            newScheduleTemplates.push(data);
        } else if (type === 'delete') {
            newScheduleTemplates = newScheduleTemplates.filter(s => s.uid !== data);
        }
        this.setState({scheduleTemplates: newScheduleTemplates});
    }

    handleSelectShift(shift) {
        this.setState({selectedShift: shift, modalSwitch: 'shift'});
    }

    hideModal() {
        this.setState({modalSwitch: ''});
    }

    render() {


        const startOfWeek = moment(this.state.selectedDate).startOf('week');

        const dayHeaders = [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {
            const header = moment(startOfWeek).add(weekDayIndex, 'days').format('ddd D');

            return (
                <div key={weekDayIndex} style={{borderRight: '1px solid lightgray', flex: 1, textAlign: 'center', fontWeight: 'bold'}}>
                    <span>{header}</span>
                </div>
            )

        });

        const allDayEventRows = [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {
            const date = moment(startOfWeek).add(weekDayIndex, 'days').format('YYYY-MM-DD');
            const timeOffOnDay = this.state.timeOff.filter(to => date >= to.startDate && date <= to.endDate && to.status !== -1);
            const callOffsOnDay = this.state.callOffs.filter(co => co.date === date);

            const timeOffOnDayList = timeOffOnDay.map((to) => {
                const employee = this.state.employees.find(e => e.uid === to.userIdentifier);
                return (
                    <div key={to.uid} style={{display: 'flex', justifyContent: 'space-between', gap: 12}}>

                        <span style={{fontSize: !this.state.collapseEvents ? 12 : undefined, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`${employee.lastName}, ${employee.firstName} ${employee.middleName}`}</span>
                        <span style={{fontSize: !this.state.collapseEvents ? 12 : undefined, opacity: 0.5, whiteSpace: 'nowrap'}}>{to.status ? 'Approved Time Off' : 'Pending Time Off'}</span>
                    </div>
                )
            })
            const callOffsOnDayList = callOffsOnDay.map((co) => {
                const employee = this.state.employees.find(e => e.uid === co.userIdentifier);
                return (
                    <div key={co.uid} style={{display: 'flex', justifyContent: 'space-between', gap: 12}}>

                        <span style={{fontSize: !this.state.collapseEvents ? 12 : undefined, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`${employee.lastName}, ${employee.firstName} ${employee.middleName}`}</span>
                        <span style={{fontSize: !this.state.collapseEvents ? 12 : undefined, opacity: 0.5, whiteSpace: 'nowrap'}}>{co.notifiedManager ? 'Call-Off' : 'No-Show'}</span>
                    </div>
                )
            })

            const totalLength = timeOffOnDay.length + callOffsOnDay.length;
            

            return (
                <div key={weekDayIndex} style={{borderRight: '1px solid lightgray', flex: 1, overflowX: 'hidden', display: 'flex', flexDirection: 'column', padding: 2, textAlign: 'center', fontWeight: 'bold'}}>
                    { this.state.collapseEvents ?
                        <OverlayTrigger placement="bottom" overlay={
                            (
                                <Popover style={{maxWidth: 'none', position: 'fixed'}}>
                                    <Popover.Body>

                                        { totalLength > 0 ?
                                            <>
                                                {timeOffOnDayList}
                                                {callOffsOnDayList}
                                            </>
                                            :
                                            <span>None</span>
                                        }
                                    
                                    </Popover.Body>
                                </Popover>
                            )
                        }>
                            <Button variant="outline-primary" style={{padding: '0px 4px 0px 4px'}}>
                                {`${totalLength} Event${totalLength === 1 ? '' : 's'}`}
                            </Button>
                        </OverlayTrigger>
                        :
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', overflowX: 'hidden', paddingLeft: 2, paddingRight: 2}}>
                            { (timeOffOnDay.length > 0 || callOffsOnDay.length > 0) && 
                                
                                <OverlayTrigger placement="bottom" overlay={
                                    (
                                        <Popover style={{maxWidth: 'none', position: 'fixed'}}>
                                            <Popover.Body>
        
                                                { totalLength > 0 ?
                                                    <>
                                                        {timeOffOnDayList}
                                                        {callOffsOnDayList}
                                                    </>
                                                    :
                                                    <span>None</span>
                                                }
                                            
                                            </Popover.Body>
                                        </Popover>
                                    )
                                }>
                                    <div className="cursor-pointer">
                                        {timeOffOnDayList}
                                        {callOffsOnDayList}
                                    </div>
                                </OverlayTrigger>
                            }
                        </div>


                    }
                </div>
            )
        })

        

        const totalsHeaders = [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {
            const date = moment(startOfWeek).add(weekDayIndex, 'days').format('YYYY-MM-DD');

            const catagoryBadges = this.state.shiftTypes.filter(st => this.state.shifts.find(s => s.date === date && s.shiftTypeIdentifier === st.uid)).map((shiftType) => {

                const number = this.state.shifts.filter(s => s.date === date && s.title === shiftType.title).length;

                return (
                    <OverlayTrigger key={shiftType.uid} overlay={
                        <Popover style={{position: 'fixed'}}>
                            <Popover.Body>{shiftType.title}</Popover.Body>
                        </Popover>
                    }>
                        <div>
                            <span className="unselectable-text" key={shiftType.uid} style={{border: `1px solid #${shiftType.color}`, padding: '0px 4px 0px 4px', borderRadius: 4}}>{number}</span>
                        </div>
                    </OverlayTrigger>
                )
            })

            return (
                <div key={weekDayIndex} style={{borderRight: '1px solid lightgray', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4}}>
                    {catagoryBadges}
                </div>
            )
        })

        const addShiftHeaders = [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {
            return (
                <div key={weekDayIndex} style={{borderRight: '1px solid lightgray', flex: 1, display: 'flex', flexDirection: 'column', padding: 2}}>
                    <Button variant="outline-primary" style={{padding: '0px 4px 0px 4px'}} onClick={() => {
                        this.setState({selectedShiftDate: moment(startOfWeek).add(weekDayIndex, 'days').format('YYYY-MM-DD'), selectedShift: undefined, modalSwitch: 'shift'})
                    }}>
                        <FontAwesomeIcon icon={faPlus} style={{marginRight: 4}}/>
                        Add Shift
                    </Button>
                </div>
            )
        })

        const header = (
            <div>
                <div style={{display: 'flex', borderBottom: '1px solid lightgray'}}>
                    {dayHeaders}
                </div>
                { (this.state.timeOff.length > 0 || this.state.callOffs.length > 0) &&
                    <div style={{display: 'flex', borderBottom: '1px solid lightgray'}}>
                        {allDayEventRows}
                    </div>
                }
                <div style={{display: 'flex', borderBottom: '1px solid lightgray'}}>
                    {addShiftHeaders}
                </div>
                <div style={{display: 'flex', borderBottom: '1px solid lightgray'}}>
                    {totalsHeaders}
                </div>
            </div>
        )

        const locationDropdownItems = this.state.locations.map((location) => {
            return (
                <Dropdown.Item key={location.uid} active={this.state.selectedLocation === location.uid} onClick={() => {
                    this.setState({selectedLocation: location.uid}, () => {
                        this.loadData();
                    })
                }}>
                    {location.name}
                </Dropdown.Item>
            )
        })


        return this.state.isLoading ? <PageSpinner/> : (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%', padding: 16}}>
                
                <div style={{display: 'flex', gap: 12, justifyContent: 'space-between', alignItems: 'center', marginBottom: 8}}>
                    <div style={{display: 'flex', gap: 12, alignItems: 'center',}}> 
                        <InputGroup>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-primary">{this.state.locations.find(l => l.uid === this.state.selectedLocation)?.name}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {locationDropdownItems}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Button variant="outline-primary" onClick={() => {this.setState({modalSwitch: 'date'})}}>{`${startOfWeek.format('MMM D, YYYY')} - ${moment(startOfWeek).add(6, 'days').format('MMM D, YYYY')}`}</Button>
                        </InputGroup>



                    </div>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-primary">
                            <FontAwesomeIcon icon={faBars}/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>View / Edit</Dropdown.Header>
                            <Dropdown.Item onClick={() => {this.setState({modalSwitch: 'shiftTypes'})}}>Shift Types</Dropdown.Item>
                            <Dropdown.Item onClick={() => {this.setState({modalSwitch: 'shiftTemplates'})}}>Shift Templates</Dropdown.Item>
                            <Dropdown.Item onClick={() => {this.setState({modalSwitch: 'scheduleTemplates'})}}>Schedule Templates</Dropdown.Item>
                            
                            <Dropdown.Divider/>
                            <Dropdown.Header>Table View</Dropdown.Header>
                            <Dropdown.Item active={this.state.viewSwitch === 0} onClick={() => {this.setState({viewSwitch: 0})}}>Chronological</Dropdown.Item>
                            <Dropdown.Item active={this.state.viewSwitch === 1} onClick={() => {this.setState({viewSwitch: 1})}}>Categorized</Dropdown.Item>
                            
                            <Dropdown.Divider/>
                            <Dropdown.Header>Additional View Options</Dropdown.Header>
                            <Dropdown.Item active={this.state.collapseEvents} onClick={() => {this.setState({collapseEvents: !this.state.collapseEvents})}}>Hide Time Off/Call-Offs</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>

                <div style={{display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'hidden', borderTop: '1px solid lightgray', borderLeft: '1px solid lightgray'}}>
                    {header}
                    { this.state.viewSwitch === 0 ?
                        <SchedulingTableChronological 
                            startOfWeek={startOfWeek} 
                            employees={this.state.employees} 
                            shifts={this.state.shifts} 
                            handleSelectShift={this.handleSelectShift} 
                            shiftTypes={this.state.shiftTypes} 
                            timeOff={this.state.timeOff} 
                            callOffs={this.state.callOffs}
                        />
                        :
                        <SchedulingTableCatagorized 
                            startOfWeek={startOfWeek} 
                            employees={this.state.employees} 
                            shifts={this.state.shifts} 
                            handleSelectShift={this.handleSelectShift} 
                            shiftTypes={this.state.shiftTypes}
                            timeOff={this.state.timeOff}
                            callOffs={this.state.callOffs}
                        />
                    }
                </div>

                <Modal show={this.state.modalSwitch === 'shiftTypes'} onHide={this.hideModal} backdrop='static' size="lg">
                    <ShiftTypeEditor shiftTypes={this.state.shiftTypes} handleSetShiftTypes={this.handleSetShiftTypes} loadData={this.loadData}/>
                </Modal>
                <Modal show={this.state.modalSwitch === 'shiftTemplates'} onHide={this.hideModal} size="xl">
                    <ShiftTemplateEditor shiftTemplates={this.state.shiftTemplates} handleShiftTemplateCrud={this.handleShiftTemplateCrud}/>
                </Modal>
                <Modal show={this.state.modalSwitch === 'scheduleTemplates'} onHide={this.hideModal} size="lg">
                    <ScheduleTemplateEditor 
                        scheduleTemplates={this.state.scheduleTemplates} 
                        handleScheduleTemplateCrud={this.handleScheduleTemplateCrud} 
                        shifts={this.state.shifts} 
                        locationIdentifier={this.state.selectedLocation} 
                        startOfWeek={startOfWeek} 
                        loadData={this.loadData}
                    />
                </Modal>
                <Modal show={this.state.modalSwitch === 'date'} onHide={this.hideModal}>
                    <SchedulingWeekSelector date={this.state.selectedDate} setDate={(date) => {
                        this.setState({selectedDate: date, modalSwitch: ''}, () => {
                            this.loadData();
                        });
                    }}/>
                </Modal>
                <Modal show={this.state.modalSwitch === 'shift'} onHide={this.hideModal} size="lg">
                    <ShiftEditor 
                        employees={this.state.employees}
                        shiftTypes={this.state.shiftTypes} 
                        shiftTemplates={this.state.shiftTemplates}
                        locationIdentifier={this.state.selectedLocation} 
                        date={this.state.selectedShift?.date ?? this.state.selectedShiftDate} 
                        shift={this.state.selectedShift}
                        handleShiftCrud={this.handleShiftCrud} 
                        handleShiftTemplateCrud={this.handleShiftTemplateCrud}
                        shifts={this.state.shifts}
                        timeOff={this.state.timeOff}
                        callOffs={this.state.callOffs}
                    />
                </Modal>

            </div>
        )
    }
}