/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { ListGroup, Modal } from 'react-bootstrap';
import TimesheetReportForm from './TimesheetReportForm';


export default function Reports({}) {
    const [modalSwitch, setModalSwitch] = useState('');

    const onHide = () => {
        setModalSwitch('');
    }


    return (
        <div style={{padding: 12}}>
            <ListGroup>
                <ReportElement label='Timesheet Report' onClick={() => {setModalSwitch('timesheetForm')}}/>
            </ListGroup>
            <Modal show={modalSwitch === 'timesheetForm'} onHide={onHide} size='lg'>
                <TimesheetReportForm/>
            </Modal>
        </div>

    )
}

function ReportElement({label, onClick}) {
    return (
        <ListGroup.Item action onClick={onClick} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'var(--bs-primary)'}}>
            <span>{label}</span>
            <FontAwesomeIcon icon={faChevronRight}/>
        </ListGroup.Item>
    )
}

