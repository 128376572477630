/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import React from "react";
import { Page, Document, View, Text, Image, PDFViewer } from "@react-pdf/renderer";
import moment from "moment";
import logo from '../../assets/TTA-Logo-150.png';
import Cookies from "universal-cookie";
import { Modal } from "react-bootstrap";


export default function TimesheetReport({users, timesheets, startDate, endDate}) {

    const sortedUsers = Array.from(users).sort((a, b) => {
        return a.location.name < b.location.name ? -1 
            : a.location.name > b.location.name ? 1 
            : `${a.lastName} ${a.firstName}` < `${b.lastName} ${b.firstName}` ? -1 
            : `${a.lastName} ${a.firstName}` > `${b.lastName} ${b.firstName}` ? 1 
            : 0
        ;
    })

    const tables = sortedUsers.map((user) => {

        const userTimesheets = timesheets.filter(t => t.userIdentifier == user.uid).sort((a, b) => {return a.inPunch < b.inPunch ? -1 : a.inPunch > b.inPunch ? 1 : 0});

        const rows = userTimesheets.map((timesheet) => {
            const outPunchDifferentDay = timesheet.inPunch.substring(0, 10) !== timesheet.outPunch?.substring(0, 10);

            return (
                <PdfTableRow key={timesheet.uid}>
                    <PdfTableCell width='25%'>{moment(timesheet.inPunch).format('MMM D, YYYY')}</PdfTableCell>
                    <PdfTableCell width='25%'>{moment(timesheet.inPunch).format('h:mm A')}</PdfTableCell>
                    <PdfTableCell width='25%'>{timesheet.outPunch ? moment(timesheet.outPunch).format(outPunchDifferentDay ? 'MMM D [at] h:mm A' : 'h:mm A') : ''}</PdfTableCell>
                    <PdfTableCell width='25%'>{timesheet.outPunch ? moment(timesheet.outPunch).diff(moment(timesheet.inPunch), 'hours', true).toFixed(2) : ''}</PdfTableCell>
                </PdfTableRow>
            )
        });

        const sum = userTimesheets.reduce((prev, curr) => {
            return prev + (curr.outPunch ? moment(curr.outPunch).diff(moment(curr.inPunch), 'hours', true) : 0)
        }, 0);

        const strictSum = userTimesheets.reduce((prev, curr) => {
            if (!curr.outPunch) {
                return prev;
            } else {
                const endDatePlusOne = moment(endDate).add(1, 'days').format('YYYY-MM-DD');
                const inPunch = curr.inPunch < startDate ? startDate : curr.inPunch;
                const outPunch = curr.outPunch > endDatePlusOne ? endDatePlusOne : curr.outPunch;
                return prev + moment(outPunch).diff(moment(inPunch), 'hours', true);
            }
        }, 0);

        return (
            <View key={user.uid} style={{display:'flex', flexDirection:'column',gap:8, marginBottom:10}}>
                <View style={{display: 'flex', flexDirection:'row',justifyContent:'space-between', alignContent:'center'}}>
                    <Text>{`${user.lastName}, ${user.firstName} ${user.middleName}`}</Text>
                    <Text style={{fontSize:11}}>{user.location.name}</Text>  
                </View>
                <View style={{borderTop: '1px solid lightgray', borderLeft: '1px solid lightgray', width: '100%', fontSize: 9}}>
                    { rows.length > 0 && 
                        <PdfTableRow>
                            <PdfTableCell width='25%' fontSize={13}>Date</PdfTableCell>
                            <PdfTableCell width='25%' fontSize={13}>In Time</PdfTableCell>
                            <PdfTableCell width='25%' fontSize={13}>Out Time</PdfTableCell>
                            <PdfTableCell width='25%' fontSize={13}>Hours Worked</PdfTableCell>
                        </PdfTableRow>
                    }
                    {rows}
                    <PdfTableRow>
                        <PdfTableCell width='100%'>
                            <View style={{flexDirection: 'row', justifyContent: 'space-between', width: '100%', fontSize: 11}}>
                                <Text>Total Hours Worked</Text>
                                <Text>{sum.toFixed(2)}</Text>
                            </View>
                        </PdfTableCell>
                    </PdfTableRow>
                    { sum.toFixed(2) !== strictSum.toFixed(2) && 
                        <PdfTableRow>
                            <PdfTableCell width='100%'>
                                <View style={{flexDirection: 'row', justifyContent: 'space-between', width: '100%', fontSize: 11}}>
                                    <Text>{`Precise Hours Worked Between ${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}`}</Text>
                                    <Text>{strictSum.toFixed(2)}</Text>
                                </View>
                            </PdfTableCell>
                        </PdfTableRow>
                    }
                </View>
            </View>
        )
        
    })
   
    return (
        <>
            <Modal.Header closeButton/>
            <Modal.Body>
                <PDFViewer style={{width: '100%', height: '100%'}}>
                    <Document> 
                        <Page size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}}>
                        <View fixed style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom:10}}>
                            <Image src={logo} style={{width: 100}}/>
                            <Text style={{fontSize: 12, color: 'gray'}}>{new Cookies().get('companyName')}</Text>
                        </View>
                        <Text style={{textAlign:'center', paddingBottom:4, fontSize: 20}}>Timesheet Report</Text>
                        <Text style={{opacity:0.5,textAlign:'center', fontSize: 13}}>{moment(startDate).format('MMM D, YYYY')+' - '+moment(endDate).format('MMM D, YYYY')}</Text>
                        {tables}
                        <Text style={{textAlign: 'center', color: 'gray', fontSize: 11, position: 'absolute', bottom: 24, left: 0, right: 0}} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed />
                        </Page>
                    </Document>
                </PDFViewer>
            </Modal.Body>
        </>
    )

}
  
function PdfTableRow({children}) {
    return (
        <View wrap={false} style={{flexDirection:'row', borderBottom: '1px solid lightgray'}}>
            {children}
        </View>
    )
}

function PdfTableCell({children, width, fontSize = 11}) {
    return (
        <View wrap={false} style={{width: width, borderRight: '1px solid lightgray', padding: 2}}>
            { typeof children === 'string' ? 
                <Text style={{fontSize: fontSize}}>{children}</Text>
            :
                <>
                    {children}
                </>
            }
        </View>
    )
}