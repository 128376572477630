/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { createRef, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Button, InputGroup } from 'react-bootstrap';
import CustomButton from './CustomButton';
import { getBase64FromFile } from '../tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

export default function FileControl({document, setDocument, title}) { 

    const ref = useRef();

    const handleOnChange = (_) => {
        const file = ref.current?.files[0];
        getBase64FromFile(file, (value) => {
            const newDocument = structuredClone(document);
            newDocument.base64 = value;
            if (file) {
                const indexOfDot = file.name.lastIndexOf('.');
                newDocument.type = file.name.slice(indexOfDot + 1);
                newDocument.name = file.name.slice(0, indexOfDot);
            }
            setDocument(newDocument);
        });
    }

    const handleSetDocument = (key, value) => {
        const newDocument = structuredClone(document);
        newDocument[key] = value;
        setDocument(newDocument);
    }

    return (
        <>
            <input ref={ref} style={{display: 'none'}} type='file' onChange={handleOnChange}/>
            <InputGroup>

                <CustomButton 
                    onClick={() => {ref.current?.click()}} 
                    label={document.base64 ? 'Change File' : 'Choose File'} 
                />
                <FloatingLabel label={document.base64 ? 'Document Name' : ''}>
                    <Form.Control type='text' placeholder=' ' disabled={!document.base64} value={document.name ?? ''} onChange={(event) => {handleSetDocument('name', event.target.value)}}/>
                </FloatingLabel>
                { document.base64 && document.type && 
                    <InputGroup.Text>{document.type}</InputGroup.Text>
                }
                { document.base64 && 
                    <Button variant='outline-danger' onClick={() => {handleSetDocument('base64', undefined)}}>
                        <FontAwesomeIcon icon={faX}/>
                    </Button>
                }
            </InputGroup>
        </>
    )
    
}
