/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import KeyValueRow from '../../components/KeyValueRow';
import { ListGroup, Card, OverlayTrigger, Popover, Modal } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { getDashboard } from '../../services/DashboardService';
import QuickTable from '../../components/QuickTable';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import HRSchedulePendingRequest from '../HumanResources/HRSchedule/HRSchedulePendingRequest';
import { NavLink } from 'react-router-dom';

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            clockedInCount: 0,
            scheduledCount: 0,
            scheduleConflicts: 0,
            timeOff: [],
            selectedTimeOff: undefined,
            showModal: false
        }
        this.loadData = this.loadData.bind(this);

        this.permissions = new Cookies().get('userData')?.pay?.permissions;
    }


    async loadData() {
        this.setState({isLoading: true, showModal: false});

        const response = await getDashboard();
        if (response.status === 200) {
            this.setState({
                clockedInCount: response.clockedInCount, 
                scheduledCount: response.scheduledCount, 
                scheduleConflicts: response.scheduleConflicts, 
                timeOff: response.timeOff
            });
        }

        this.setState({isLoading: false});
    }

    componentDidMount() {
        if (this.permissions.hrSchedule && this.permissions.scheduling) {
            this.loadData();
        }
    }

    sortTimeOffAsc(a, b) {
        return a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0;
    }
    
    
    render() {


        const pendingRequestRows = this.state.timeOff.filter(t => t.status == 0 && t.startDate > moment().format('YYYY-MM-DD')).sort(this.sortTimeOffAsc).map((timeOff) => {
            return (
                <tr key={timeOff.uid} className='cursor-pointer' onClick={() => {this.setState({selectedTimeOff: timeOff, showModal: true})}}>
                    <td>{`${timeOff.user.firstName} ${timeOff.user.lastName}`}</td>
                    <td>{moment(timeOff.startDate).format('MMM D, YYYY')}</td>
                    <td>{moment(timeOff.endDate).format('MMM D, YYYY')}</td>
                    <td>{moment(timeOff.submissionDate).format('MMM D, YYYY')}</td>
                    <td>
                        <OverlayTrigger overlay={
                            <Popover style={{position: 'fixed'}}>
                                <Popover.Header>Notes</Popover.Header>
                                <Popover.Body>{timeOff.notes}</Popover.Body>
                            </Popover>
                        }>
                            <FontAwesomeIcon style={{color: 'var(--bs-primary)'}} icon={faInfoCircle}/>
                        </OverlayTrigger>
                    </td>
                </tr>
            )
        })
        const futureTimeOffRows = this.state.timeOff.filter(t => t.status == 1).sort(this.sortTimeOffAsc).map((timeOff) => {
            return (
                <tr key={timeOff.uid}>
                    <td>{`${timeOff.user.firstName} ${timeOff.user.lastName}`}</td>
                    <td>{moment(timeOff.startDate).format('MMM D, YYYY')}</td>
                    <td>{moment(timeOff.endDate).format('MMM D, YYYY')}</td>
                    <td>{moment(timeOff.submissionDate).format('MMM D, YYYY')}</td>
                    <td>
                        <OverlayTrigger overlay={
                            <Popover style={{position: 'fixed'}}>
                                <Popover.Header>Notes</Popover.Header>
                                <Popover.Body>{timeOff.notes}</Popover.Body>
                            </Popover>
                        }>
                            <FontAwesomeIcon style={{color: 'var(--bs-primary)'}} icon={faInfoCircle}/>
                        </OverlayTrigger>
                    </td>
                </tr>
            )
        })

        return (
            <div style={{height: '100%', overflowY: 'scroll'}}>
                <div style={{position: 'absolute', width: '100%', height: '100%', opacity: 0.03}} className='login-background'/>
                <div style={{padding: 16, display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <div style={{display: 'flex', justifyContent: 'center', padding: 12}}>
                        <Card style={{maxWidth: 1200, flex: 1}}>
                            <Card.Body>
                                <h1 style={{textAlign: 'center', color: 'var(--bs-primary)', fontWeight: 'bold'}}>My Tactical Business</h1>
                                <h2 style={{textAlign: 'center', opacity: 0.5, margin: 0}}>~ Dashboard ~</h2>
                            </Card.Body>
                        </Card>
                    </div>
                    { this.permissions.hrSchedule && this.permissions.scheduling ?
                        <>
                            <div style={{display: 'flex', justifyContent: 'center', padding: 12}}>
                                <Card style={{display: 'flex', flexDirection: 'column', maxWidth: 1200, flex: 1}}>
                                    <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <div style={{display: 'flex', gap: 12}}>
                                                <Card.Title style={{fontWeight: 'bold', color: 'var(--bs-primary)'}}>Clocked-In Vs. Scheduled Today:</Card.Title>
                                                <Card.Title style={{color: this.state.clockedInCount != this.state.scheduledCount > 0 ? 'gold' : 'green'}}>{`${this.state.clockedInCount} / ${this.state.scheduledCount}`}</Card.Title>
                                            </div>

                                            <NavLink className='btn btn-outline-primary' to='/human-resources'>
                                                <span>View in Human Resources</span>
                                                <FontAwesomeIcon icon={faChevronRight} style={{marginLeft: 6}}/>
                                            </NavLink>
                                        </div>
                                        <div style={{height: 1, backgroundColor: 'lightgray', marginBottom: 24}}/>
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <div style={{display: 'flex', gap: 12}}>
                                                <Card.Title style={{fontWeight: 'bold', color: 'var(--bs-primary)'}}>Schedule Conflicts This Week:</Card.Title>
                                                <Card.Title style={{color: this.state.scheduleConflicts > 0 ? 'gold' : 'green'}}>{this.state.scheduleConflicts}</Card.Title>
                                            </div>
                                            <NavLink className='btn btn-outline-primary' to='/scheduling'>
                                                <span>View in Scheduling</span>
                                                <FontAwesomeIcon icon={faChevronRight} style={{marginLeft: 6}}/>
                                            </NavLink>
                                        </div>
                                        <div style={{height: 1, backgroundColor: 'lightgray', marginBottom: 24}}/>
                                        <div>
                                            <Card.Title style={{fontWeight: 'bold', color: 'var(--bs-primary)'}}>Pending Time-Off Requests</Card.Title>
                                            { pendingRequestRows.length > 0 ?
                                                <QuickTable headers={['Employee', 'Start Date', 'End Date', 'Submission Date', 'Notes']} widths={[null, null, null, null, 1]} rows={pendingRequestRows} hover/>
                                                :
                                                <h5 style={{opacity: 0.5}}>None</h5>
                                            }
                                        </div>
                                        <div style={{height: 1, backgroundColor: 'lightgray', marginBottom: 24}}/>
                                        <div>
                                            <Card.Title style={{fontWeight: 'bold', color: 'var(--bs-primary)'}}>Upcoming Time-Off</Card.Title>
                                            { futureTimeOffRows.length > 0 ?
                                                <QuickTable headers={['Employee', 'Start Date', 'End Date', 'Submission Date', 'Notes']} widths={[null, null, null, null, 1]} rows={futureTimeOffRows} hover/>
                                                :
                                                <h5 style={{opacity: 0.5}}>None</h5>
                                            }
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <Modal show={this.state.showModal} onHide={() => {this.setState({showModal: false})}}>
                                <HRSchedulePendingRequest selectedTimeOff={this.state.selectedTimeOff} handleAnswerRequest={this.loadData}/>
                            </Modal>
                        </>
                    :   
                        <div style={{display: 'flex', justifyContent: 'center', padding: 12}}>
                            <Card style={{display: 'flex', flexDirection: 'column', maxWidth: 1200, flex: 1}}>
                                <Card.Body>
                                    <h2 style={{textAlign: 'center', marginBottom: 16}}>Overview of Features</h2>
                                    <ListGroup>
                                        <KeyValueRow isListItem titleStyle={{color: 'var(--bs-primary)', fontWeight: 'bold', whiteSpace: 'nowrap'}} title={'Profile'} value={'View personal information and pay information. Change your password.'}/>
                                        <KeyValueRow isListItem titleStyle={{color: 'var(--bs-primary)', fontWeight: 'bold', whiteSpace: 'nowrap'}} title={'My Schedule'} value={'View personal calendar of scheduled shifts, time off, etc.'}/>
                                        <KeyValueRow isListItem titleStyle={{color: 'var(--bs-primary)', fontWeight: 'bold', whiteSpace: 'nowrap'}} title={'Days Off'} value={'View time-off requests and their statuses (pending, accepted, rejected). Request time off.'}/>
                                        <KeyValueRow isListItem titleStyle={{color: 'var(--bs-primary)', fontWeight: 'bold', whiteSpace: 'nowrap'}} title={'My Documents'} value={'View signed documents and acknowledged company documents.'}/>
                                        <KeyValueRow isListItem titleStyle={{color: 'var(--bs-primary)', fontWeight: 'bold', whiteSpace: 'nowrap'}} title={'My Company'} value={'View/Edit company profile and settings. Manage locations, devices and company documents.'}/>
                                        <KeyValueRow isListItem titleStyle={{color: 'var(--bs-primary)', fontWeight: 'bold', whiteSpace: 'nowrap'}} title={'Scheduling'} value={'Powerful yet easy-to-use employee scheduling tool.'}/>
                                        <KeyValueRow isListItem titleStyle={{color: 'var(--bs-primary)', fontWeight: 'bold', whiteSpace: 'nowrap'}} title={'Payroll'} value={'A Payroll processing tool which utilizes timesheets and pay information to do the heavy-lifting for you.'}/>
                                        <KeyValueRow isListItem titleStyle={{color: 'var(--bs-primary)', fontWeight: 'bold', whiteSpace: 'nowrap'}} title={'Human Resources'} value={'A suite of HR tools to help manage timekeeping, time-off requests, PTO, Documents, and more.'}/>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </div>
                    }
                    <div style={{flex: 2}}/>
                </div>
            </div>
        )
    }
}