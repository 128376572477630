import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap';
import PersonalInfo from './PersonalInfo';
import PositionInfo from './PositionInfo';
import { getProfileInformation } from '../../services/ProfileService';
import PageSpinner from '../../components/PageSpinner';

export default function MyCompany() {

  const [activeTab, setActiveTab] = useState('Personal Details');
  const [user, setUser] = useState(null);

    async function loadData(){
        const response = await getProfileInformation();
        if(response.status == '200'){
            setUser(response.user);
        }
    }

    useEffect(()=>{
        loadData();
    }, [])

    const tabs = {
        'Personal Details': <PersonalInfo user={user}/>,
        'Position Details': <PositionInfo user={user} pay={user?.pay}/>,
    };

    const tabButtons = Object.keys(tabs).map((key) => {
        return (
            <Button 
                key={key}
                style={activeTab=== key ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} 
                onClick={() => setActiveTab(key)}
                >
                {key}
            </Button>
        )
    });

    return (
        <>
        {!user ? 
            <PageSpinner/>
        :
            <div className="page-wrapper" style={{padding: 12, flexDirection: 'column'}}>
                <ButtonGroup>
                    {tabButtons}
                </ButtonGroup>
                <div style={{display: 'flex', gap: 20, margin: 'auto', marginTop: 12, alignItems: 'center'}}>

                </div>
                <div style={{height: '100%', margin: '12px 0px 0px 12px',  overflowY: 'scroll'}}>
                    <div style={{height: '100%', marginRight: 12}}>
                        {tabs[activeTab]}
                    </div>
                </div>
            </div>
        }
        </>
    )
    }
