/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import PageSpinner from '../../components/PageSpinner';
import Button from 'react-bootstrap/Button';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { changePassword } from "../../services/ProfileService";
import { Card, Col, Container, Modal, Table, Row, ListGroup } from "react-bootstrap";
import { Validator, validatePasswordMatch, validateExistence } from "../../validation";
import moment from "moment";
import CustomControl from "../../components/CustomControl";
import AddressControl from "../../components/AddressControl";
import CustomButton from "../../components/CustomButton";
import KeyValueRow from "../../components/KeyValueRow";
import { addressToString, formatPhoneNumber } from "../../tools";

export default function PersonalInfo({user}){
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [responseIsError, setResponseIsError] = useState(false);

    const validators = [
        new Validator(() => validateExistence(oldPassword)),
        new Validator(() => validatePasswordMatch(newPassword, confirmPassword)),
        new Validator(() => validatePasswordMatch(confirmPassword, newPassword)),
    ];

    async function handlePasswordChange() {
        setIsChangingPassword(true);
        const response = await changePassword(oldPassword, newPassword);
        if (response.status == 200) {
            setResponseMessage(response.message);
            setResponseIsError(false);
            setIsChangingPassword(false);
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } else {
            setResponseMessage(response.message);
            setResponseIsError(true);
            setOldPassword('');
        }
        setIsChangingPassword(false);
    };
    
    return (
        <Container fluid>
            <div style={{padding:5}}>
                <p style={{textAlign: 'center', margin: 0, fontSize: 24, fontWeight: 'bold', padding:10}}>Personal Details</p>
            </div>
            <Row style={{paddingBottom:5, marginBottom: 8}}>
                <Col>
                    <Card>
                        <Card.Body style={{display:'flex', flexDirection:'column'}}>
                            <div>
                                <Button variant={'outline-primary'} onClick={() => setShowModal(true)}>Change Password</Button>
                                <Card.Title style={{textAlign:'center', paddingBottom:10}}>Contact Information</Card.Title>
                            </div>
                            <ListGroup>
                                <KeyValueRow isListItem title={'First Name'} value={user.firstName} />
                                <KeyValueRow isListItem title={'Middle Name'} value={user.middleName} />
                                <KeyValueRow isListItem title={'Last Name'} value={user.lastName}/>
                                <KeyValueRow isListItem title={'Email Address'} value={user.email}/>
                                <KeyValueRow isListItem title={'Phone Number'} value={formatPhoneNumber(user.phoneNumber)}/>
                                <KeyValueRow isListItem title={'Address'} value={addressToString(user.address)} />
                                <KeyValueRow isListItem title={'Date of Birth'} value={moment(user.dateOfBirth).format('MMMM D, YYYY')}/>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            <Modal show={showModal} onHide={() => {setShowModal(false);setResponseMessage(''); setOldPassword('');setNewPassword('');setConfirmPassword('');}} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                        <CustomControl floatingLabel={true} validator={validators[0]} type="password" title='Old Password' value={oldPassword} setValue={(value) => {setOldPassword(value)}}/>
                        <CustomControl floatingLabel={true} validator={validators[1]} type="password" title='New Password' value={newPassword} setValue={(value) => {setNewPassword(value)}}/>
                        <CustomControl floatingLabel={true} validator={validators[2]} type="password" title='Confirm New Password' value={confirmPassword} setValue={(value) => {setConfirmPassword(value)}}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <p style={{marginBottom: 0, color: responseIsError ? 'red' : 'green'}}>{responseMessage}</p>
                        <CustomButton disabled={!validators[0].isValid() || !validators[1].isValid() || !validators[2].isValid} isLoading={isChangingPassword} label='Submit' onClick={handlePasswordChange}/>
                    </Modal.Footer>
                </Modal>
            </Row>
            <Row style={{paddingBottom:5}}>
                {user.emergencyContacts.map((contact, index)=>{
                    return (
                        <Col key={contact.uid} lg={12}>
                            <UserEmergencyContact contact={contact} index={index}/>
                        </Col>                 
                        )
                    })}
            </Row>
        </Container>
    )
}

function UserEmergencyContact({contact, index}){

    return (
        <Card style={{marginBottom: 12}}>
            <Card.Body style={{paddingBottom:30}}>
                <Card.Title style={{textAlign:'center', paddingBottom:10}}>Emergency Contact #{index+1}</Card.Title>
                <ListGroup>
                    <KeyValueRow isListItem title={'First Name'} value={contact.firstName}/>
                    <KeyValueRow isListItem title={'Last Name'} value={contact.lastName}/>
                    <KeyValueRow isListItem title={'Phone Number'} value={formatPhoneNumber(contact.phoneNumber)}/>
                    <KeyValueRow isListItem title={'Email Address'} value={contact.email}/>
                    <KeyValueRow isListItem title={'Relationship'} value={contact.relationship}/>
                    <KeyValueRow isListItem title={'Address'} value={addressToString(contact.address)}/>   
                </ListGroup>
            </Card.Body>
        </Card>
    )
}