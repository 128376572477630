/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import LoadingWrapper from '../../components/LoadingWrapper';
import { getAllUsers, shadow } from '../../services/AdminService';
import { AppGlobals } from '../../App';
import SortableTable, { SortableTableCol } from '../../components/SortableTable';
import { Modal } from 'react-bootstrap';
import SearchBar from '../../components/SearchBar';
import { filterArrayByObjectProperty } from '../../tools';
import QuickTable from '../../components/QuickTable';
import CustomAlert from '../../components/CustomAlert';

export default function Shadow() {
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [searchType, setSearchType] = useState('firstName');

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getAllUsers();
        if (response.status === 200) {
            setUsers(response.users);
        } else {
            AppGlobals.alert(<CustomAlert type='danger' title={response.message}/>);
        }
        setIsLoading(false);
    }

    const handleShadowUser = async (userIdentifier) => {
        setIsLoading(true);
        const response = await shadow(userIdentifier);
        if (response.status === 200) {
            window.location.reload();
        } else {
            AppGlobals.alert(<CustomAlert type='danger' title={response.message}/>);
        }
        setIsLoading(false);
    }

    const userElements = filterArrayByObjectProperty(users, searchType, search).map((user) => {
        return (
            <tr key={user.uid} className='cursor-pointer' onClick={() => {handleShadowUser(user.uid)}}>
                <td>{user.uid}</td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.locationName}</td>
                <td>{user.companyName}</td>
            </tr>
        )
        
    })


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Shadow User</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <LoadingWrapper isLoading={isLoading} color='var(--bs-primary)'>
                    <SearchBar 
                        value={search} 
                        setValue={setSearch} 
                        typeValues={['uid', 'firstName', 'lastName', 'locationName', 'companyName']} 
                        typeNames={['UID', 'First Name', 'Last Name', 'Location Name', 'Company Name']} 
                        type={searchType} 
                        setType={setSearchType}
                    />
                    <QuickTable rows={userElements} headers={['UID', 'First Name', 'Last Name', 'Location Name', 'Company Name']}/>
                </LoadingWrapper>
            </Modal.Body>
        </>
    )
}