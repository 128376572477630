/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import TimesheetCalendar from "./TimesheetCalendar";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import PageSpinner from '../../components/PageSpinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { getTimesheetEntries, punch } from "../../services/TimeClockService";
import TimeClockTicker from "./TimeClockTicker";
import { getFakeUID } from "../../tools";
import { Col, Container, Row } from "react-bootstrap";
import TimesheetList from "./TimesheetList";



export default function TimeClock({mobileView}) {

    const [isLoading, setIsLoading] = useState(false);
    const [isPunching, setIsPunching] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    const [timesheetEntries, setTimesheetEntries] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        loadData();
    }, [selectedDate])
    
    const loadData = async () => {
        setIsLoading(true);

        const response = await getTimesheetEntries(moment(selectedDate).startOf('month').format('YYYY-MM-DD'), moment(selectedDate).endOf('month').format('YYYY-MM-DD'));
        if (response.status === 200) {
            setTimesheetEntries(response.timesheetEntries.sort((a, b) => {
                if (a.inPunch < b.inPunch) {
                    return -1;
                } else if (a.inPunch > b.inPunch) {
                    return 1;
                } else {
                    return 0;
                }
            }));
        }

        setIsLoading(false);
    }

    const handlePunch = async () => {
        setIsPunching(true);
        setButtonDisabled(true);
        setTimeout(() => {
            setButtonDisabled(false);
        }, 2000);
        const response = await punch();
        if (response.status === 200) {
            const newEntries = Array.from(timesheetEntries);
            if (response.uid) {
                const newPunch = {
                    uid: response.uid,
                    inPunch: moment().format('YYYY-MM-DD HH:mm:ss'),
                    outPunch: undefined,
                }
                newEntries.push(newPunch);
            } else {
                newEntries[newEntries.length - 1].outPunch = moment().format('YYYY-MM-DD HH:mm:ss');
            }
            setTimesheetEntries(newEntries);
        }
        
        setIsPunching(false);
    }


    
    const lastPunch = timesheetEntries[timesheetEntries.length - 1];
    const isClockedIn = lastPunch && !lastPunch.outPunch;
    

    const PunchIOCard = (
        <div style={{display: 'flex', flexDirection:'column', alignContent:'center', alignItems:'center', width: mobileView ? '100%' : '25%'}}>
            <p style={{fontWeight:'bold', fontSize: '3vmin', flexWrap:'wrap'}}>{'You are currently clocked ' + (isClockedIn ? 'in' : 'out') + '.'}</p>
            <Button
                disabled={buttonDisabled}
                onClick={handlePunch}
                style={{
                    aspectRatio: 1,
                    width: '80%',
                    padding: 24,
                    borderRadius: '50%',
                    backgroundColor: isClockedIn ? '#ff8a8a' : '#a7ff8a',
                    border: 'none',
                    fontSize: 24,
                    color: isClockedIn ? 'white' : 'black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    marginTop:10,
                    marginLeft:10,
                    boxShadow: '16px 24px 32px 8px rgba(255, 255, 255, 0.5) inset', 
                }}
            >
                { isPunching ? <Spinner /> : (
                    <>
                        <span style={{ margin: 0, textAlign: 'center' }}>
                            {isClockedIn ? 'Clock Out' : 'Clock In'}
                            <br />
                        <FontAwesomeIcon
                            icon={isClockedIn ? faStop : faPlay}
                            style={{
                                fontSize: 64,
                                marginBottom: 8,
                            }}
                        />
                            <TimeClockTicker/>
                        </span>
                    </>
                )}
            </Button> 


            
            <div style={{padding:10, textAlign:'center'}}>
                <p style={{fontWeight:'bold', fontSize: '3vmin'}}>Last Punch</p>
                { lastPunch &&                      
                    <span style={{fontSize: '3vmin',}}>{(isClockedIn ? 'In on ' : 'Out on ') + moment(isClockedIn ? lastPunch.inPunch : lastPunch.outPunch).format('MMM D, YYYY [at] h:mm A')}</span>
                }
            </div>
        </div>
    );

    const timesheetCard = (
        <div style={{display: 'flex', flex:1}}>
            <div style={{flex:1}}>
                <TimesheetCalendar timesheetData={timesheetEntries} selectedDate={selectedDate} setSelectedDate={(value) => {setSelectedDate(value)}}/>
            </div>
        </div> 
    );

    return (
        <div style={{padding: 12, height: '100%', overflowY: 'scroll'}}>
            { isLoading ? <PageSpinner/> : 
                <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', width: '100%', height: '100%'}}>
                    {PunchIOCard}
                    { mobileView ?
                        <TimesheetList timesheetData={timesheetEntries} selectedDate={selectedDate} setSelectedDate={(value) => {setSelectedDate(value)}}/>
                    :
                        timesheetCard
                    }
                </div>
            }
        </div>
    )
    
}
