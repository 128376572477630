/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomButton from '../../components/CustomButton';
import TextAreaControl from '../../components/TextAreaControl';
import RadioControl from '../../components/RadioControl';
import { validateExistence, ValidationGroup } from '../../validation';
import CustomControl from '../../components/CustomControl';
// import FileControl from '../../components/FileControl';
import { Form } from 'react-bootstrap';
import FileControl from '../../components/FileControl';
import { terminateEmployee } from '../../services/HumanResourcesService';
import moment from 'moment';
import { AppGlobals } from '../../App';
import CustomAlert from '../../components/CustomAlert';

export default function HumanResourcesTerminate({ selectedEmployee, loadHub }) {

    const [termination, setTermination] = useState({disciplinaryOne: {}, disciplinaryTwo: {}, screenshotOne: {}, screenshotTwo: {}, terminationLetter: {} });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validationGroup = new ValidationGroup();

    async function handleSubmit() {
        setIsSubmitting(true);
        const terminationToSend = {
            userIdentifier: selectedEmployee.uid,
            ...termination,
            disciplinaryOne: termination.disciplinaryOne.base64 ? {
                userIdentifier: selectedEmployee.uid,
                name: termination.disciplinaryOne.name,
                type: termination.disciplinaryOne.type,
                base64: termination.disciplinaryOne.base64,
                dateCreated: moment().format('YYYY-MM-DD'),
                
            } : null,
            disciplinaryTwo: termination.disciplinaryTwo.base64 ? {
                userIdentifier: selectedEmployee.uid,
                name: termination.disciplinaryTwo.name,
                type: termination.disciplinaryTwo.type,
                base64: termination.disciplinaryTwo.base64,
                dateCreated: moment().format('YYYY-MM-DD'),
            } : null,
            screenshotOne: termination.screenshotOne.base64 ? {
                userIdentifier: selectedEmployee.uid,
                name: termination.screenshotOne.name,
                type: termination.screenshotOne.type,
                base64: termination.screenshotOne.base64,
                dateCreated: moment().format('YYYY-MM-DD'),
            } : null,
            screenshotTwo:  termination.screenshotTwo.base64 ?{
                userIdentifier: selectedEmployee.uid,
                name: termination.screenshotTwo.name,
                type: termination.screenshotTwo.type,
                base64: termination.screenshotTwo.base64,
                dateCreated: moment().format('YYYY-MM-DD'),
            } : null,
            terminationLetter: termination.terminationLetter.base64 ? {
                userIdentifier: selectedEmployee.uid,
                name: termination.terminationLetter.name,
                type: termination.terminationLetter.type,
                base64: termination.terminationLetter.base64,
                dateCreated: moment().format('YYYY-MM-DD'),
            } : null,
        }
        const response = await terminateEmployee(terminationToSend);
        if (response.status === 200) {
            loadHub();
        } else {
            AppGlobals.alert(<CustomAlert type='danger' title={response.message}/>);
        }
        setIsSubmitting(false);
    }

    function handleSetTermination(key, value) {
        const newTermination = structuredClone(termination)
        newTermination[key] = value;
        setTermination(newTermination);
    }

    return (
        <>
                <Modal.Header closeButton>
                    <Modal.Title>Terminate Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                    <span>The employee will no longer show up in Schedule Match or future payroll periods. The employee and all his or her data will not be deleted and will still be viewable in Human Resources. <b>However, any future pay which has an effective date after this termination will be deleted.</b></span>
                    <CustomControl
                        floatingLabel
                        title='Date Effective'
                        type='date'
                        value={termination.dateEffective}
                        setValue={(value) => handleSetTermination('dateEffective', value)}
                        validator={validationGroup.createValidator(() => validateExistence(termination.dateEffective))}
                    />
                    <RadioControl
                        inline
                        title='Termination Type'
                        optionNames={['Resignation', 'Abandonment', 'Retirement', 'Terminate for Cause', 'Layoff', 'End of Contract']}
                        optionValues={[1, 2, 3, 4, 5, 6]}
                        selection={termination.terminationType}
                        setSelection={(value) => handleSetTermination('terminationType', value)}
                        validator={validationGroup.createValidator(() => validateExistence(termination.terminationType))}
                    />
                    <h6 style={{margin: '12px 0 0 0'}}>Disciplinary #1:</h6>
                    <FileControl document={termination.disciplinaryOne} setDocument={(value)=>handleSetTermination('disciplinaryOne', value)}/>
                    <h6 style={{margin: '12px 0 0 0'}}>Disciplinary #2:</h6>
                    <FileControl document={termination.disciplinaryTwo} setDocument={(value)=>handleSetTermination('disciplinaryTwo', value)}/>
                    <h6 style={{margin: '12px 0 0 0'}}>Screenshot #1:</h6>
                    <FileControl document={termination.screenshotOne} setDocument={(value)=>handleSetTermination('screenshotOne', value)}/>
                    <h6 style={{margin: '12px 0 0 0'}}>Screenshot #2:</h6>
                    <FileControl document={termination.screenshotTwo} setDocument={(value)=>handleSetTermination('screenshotTwo', value)}/>
                    <h6 style={{margin: '12px 0 0 0'}}>Termination Letter:</h6>
                    <FileControl document={termination.terminationLetter} setDocument={(value)=>handleSetTermination('terminationLetter', value)}/>
                    <TextAreaControl
                        label='Customer Complaints'
                        value={termination.customerComplaints}
                        setValue={(value) => { handleSetTermination('customerComplaints', value) }}
                        rows={4}
                        max={300}
                    />
                    <TextAreaControl
                        label='Termination Synopsis'
                        value={termination.terminationSynopsis}
                        setValue={(value) => { handleSetTermination('terminationSynopsis', value) }}
                        rows={4}
                        max={300}
                        validator={validationGroup.createValidator(() => validateExistence(termination.terminationSynopsis))}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} disabled={!validationGroup.isValid()} onClick={handleSubmit} label='Terminate Employee' />
                </Modal.Footer>
        </>
    )
}