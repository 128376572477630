/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../decoding";

export default class Address {
    uid; //: number;
    thoroughfare; //: string;
    premise; //: string;
    locality; //: string;
    administrativeArea; //: string;
    postalCode; //: string;

    constructor(
        uid,
        thoroughfare,
        premise,
        locality,
        administrativeArea,
        postalCode,
    ) {
        this.uid = uid;
        this.thoroughfare = thoroughfare;
        this.premise = premise;
        this.locality = locality;
        this.administrativeArea = administrativeArea;
        this.postalCode = postalCode;
    }

    static initDefault() {
        return new Address(
            '',
            '',
            '',
            '',
            '',
        );
    }
  
    static decodeValidated(json, optional = false) {

      const decoder = new Decoder(json, optional);
      const thoroughfare = decoder.decode('thoroughfare', Decoder.String);
      const premise = decoder.decode('premise', Decoder.String, {defaultValue: '', warn: false});
      const locality = decoder.decode('locality', Decoder.String);
      const administrativeArea = decoder.decode('administrativeArea', Decoder.String);
      const postalCode = decoder.decode('postalCode', Decoder.String);

      if (decoder.checkForErrors()) {
          return new Address(-1, thoroughfare, premise, locality, administrativeArea, postalCode);
      } else if (optional) {
          return undefined;
      } else {
          return Address.initDefault();
      }
  }
  
  }