/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react'
import HumanResourcesHub from './HumanResourcesHub';
import {getHumanResourceEmployees} from '../../services/HumanResourcesService';
import HREmployeeSelectorModal from './HREmployeeSelectorModal';
import { Button, Modal } from 'react-bootstrap';
import PageSpinner from '../../components/PageSpinner';
import moment from 'moment';
import Cookies from 'universal-cookie';
import HumanResourcesUser from './HumanResourcesUser';

export default function HumanResources() {

    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [locations, setLocations] = useState([]);
    const [isloading, setIsLoading] = useState(true);   
    
    const [dateSelected, setDateSelected] = useState(moment().format('YYYY-MM-DD'));

  
  
    const loadData = async() =>{
        setIsLoading(true);
        const response = await getHumanResourceEmployees(dateSelected);
        if(response.status === 200){
            setEmployees(response.employees);
            setLocations(response.locations);
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        loadData();
    }, [dateSelected])

    const handleGoBackToHub = () => {
        loadData();
        setSelectedEmployee(undefined);
    }

    // All Tabs for human resources
    

    return isloading ? <PageSpinner/> : (
        <div style={{padding: 12, display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden'}}>
            { selectedEmployee ?
                <HumanResourcesUser selectedEmployee={selectedEmployee} employees={employees} locations={locations} handleGoBackToHub={handleGoBackToHub}/>
            :
                <HumanResourcesHub 
                    employees={employees} 
                    setSelectedEmployee={setSelectedEmployee} 
                    selectedEmployee={selectedEmployee} 
                    setDateSelected={setDateSelected} 
                    loadHub={loadData} 
                    dateSelected={dateSelected} 
                    locations={locations}
                />
            }
        </div>
    )
}