/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getPayrollHistory() {
    var data = {
        type: 'payroll',
        target: 'getAll',
        token: getToken(),
    }
    const response = await getResponse(data);

    return response;
}

export async function getPayrollPeriod(uid) {
    var data = {
        type: 'payroll',
        target: 'get',
        token: getToken(),
        uid: uid
    }
    const response = await getResponse(data);
    return response;
}
    
export async function reviewPayrollPeriod(uid) {
    var data = {
        type: 'payroll',
        target: 'review',
        token: getToken(),
        uid: uid
    }
    const response = await getResponse(data);
    return response;
}

export async function approvePayrollPeriod(payrollVersion) {
    var data = {
        type: 'payroll',
        target: 'approve',
        token: getToken(),
        payrollVersion: payrollVersion,
    }

    return await getResponse(data);
}

export async function savePayrollVersion(payrollVersion){
    var data = {
        type:'payroll',
        target: 'saveVersion',
        token: getToken(),
        payrollVersion: payrollVersion
    }

    const response = await getResponse(data);
    return response;
}

export async function getPayrollVersion(uid) {
    var data = {
        type: 'payroll',
        target: 'getVersion',
        token: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function hydratePayrollEntry(entry) {
    var data = {
        type: 'payroll',
        target: 'hydratePayrollEntry',
        token: getToken(),
        entry: entry,
    }

    return await getResponse(data);
}

export async function generatePayrollEntries(payrollIdentifier, userIdentifiers) {
    var data = {
        type: 'payroll',
        target: 'generatePayrollEntries',
        token: getToken(),
        payrollIdentifier: payrollIdentifier,
        userIdentifiers: userIdentifiers
    }

    return await getResponse(data);
}