/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Cookies from "universal-cookie";
import debugMode from "./DebugMode.js"
export const APIURL = debugMode ? "http://192.168.1.52/mtb/public/" : "https://api.mytacticalbusiness.com";



const cookieMonster = new Cookies();
export function getToken() {
    return cookieMonster.get('token');
}

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}
function getRequest(data) {
    return {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    }
}

export async function getResponse(data) {

    if(debugMode){
        console.log(data);
    }
    
    const response = await fetch(APIURL, getRequest(data)).catch((error) => {
        console.log(error);
        return;
    });
        
    const responseObject = await response.json();
    responseObject.status = response.status;
    if(debugMode){
        console.log(responseObject);
    }
    return responseObject;
}