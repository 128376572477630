/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import CustomButton from '../../components/CustomButton'
import { getHumanResourcePermissions, updateHumanResourcePermissions } from '../../services/HumanResourcesService';
import PageSpinner from '../../components/PageSpinner';
import QuickTable from '../../components/QuickTable';
import { AppGlobals } from '../../App';
import CustomAlert from '../../components/CustomAlert';

export default function HumanResourcesPermissions({selectedEmployee}) {

    const [employeePermissions, setEmployeePermissions] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [permissionsTemplate, setPermissionsTemplate] = useState([]);
    
    async function loadData() {
        setIsLoading(true);
        const response = await getHumanResourcePermissions(selectedEmployee.uid);
        if (response.status === 200) {
            setEmployeePermissions(response.permissions);
            setPermissionsTemplate(response.permissionsTemplate);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [selectedEmployee])
    
    async function handlePermissionChange(permissionKey){
        const newPermissions = structuredClone(employeePermissions);
        newPermissions[permissionKey] = newPermissions[permissionKey] ? 0 : 1;
        setEmployeePermissions(newPermissions);
    }

    function handleSetHireEmployeePermissionsTemplate(template){
        const newPermissions = structuredClone(employeePermissions)
        permissionsTemplate.map(p=> newPermissions[p.key] = parseInt(p[template]));
        setEmployeePermissions(newPermissions);
    }

    async function handleSubmit() {
        setIsSubmitting(true);
        const response = await updateHumanResourcePermissions(employeePermissions);
        if (response.status === 200) {
            AppGlobals.alert(<CustomAlert title='Successfully Updated Permissions' type='success'/>);
        }    
        setIsSubmitting(false);
    }
    
    const permissionRows = permissionsTemplate.map((p) => {
        return (
            <tr key={p.key}>
                <td>
                    {p.label}
                </td>
                <td>{p.description}</td>
                <td>
                    <Form.Check type='switch' checked={employeePermissions != null && employeePermissions[p.key]} onChange={()=> handlePermissionChange(p.key)}/>
                </td>
            </tr>
        )
    })
    

    return isLoading ? <PageSpinner/> : (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center', padding: 12, border: '1px solid lightgray', marginBottom: 8, gap: 18}}>
                <p style={{fontSize: 16, margin: 0}}>Default Permission Templates</p>
                <Button onClick={()=>handleSetHireEmployeePermissionsTemplate('associate')} style={{width: 120}} variant='outline-primary'>Associate</Button>
                <Button onClick={()=>handleSetHireEmployeePermissionsTemplate('manager')}  style={{width: 120}} variant='outline-primary'>Manager</Button>
                <Button onClick={()=>handleSetHireEmployeePermissionsTemplate('owner')} style={{width: 120}} variant='outline-primary'>Owner</Button>
            </div>
            <QuickTable title='Permissions' rows={permissionRows} headers={['Permission Title', 'Description', 'On / Off']} widths={[null, null, 1]} noWrap responsive={false}/>
            <div style={{alignSelf: 'flex-end'}}>
                <CustomButton isLoading={isSubmitting} onClick={handleSubmit} label='Save Changes'/>
            </div>
        </div>
    )

}