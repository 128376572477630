/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../CustomButton";

export default function AlertModal({show, onHide, centered, size, callBack, title, message, buttonLabel, variant, loadingProp}) {

    const [isLoading, setIsLoading] = useState(loadingProp ? loadingProp : false);

    const handleCallBack = () => {
        setIsLoading(true);
        callBack().then((response) => {
            if (response) {
                onHide();
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <Modal show={show} onHide={onHide} centered={centered} size={size}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            { message &&
                <Modal.Body>
                    {message}
                </Modal.Body>
            }
            <Modal.Footer>
                <CustomButton variant={variant} isLoading={isLoading} label={buttonLabel} onClick={handleCallBack}/>
            </Modal.Footer>
        </Modal>
    )
}