/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import CustomControl from "../../components/CustomControl";
import CustomButton from "../../components/CustomButton";
import { createShiftTemplate, deleteShiftTemplate, updateShiftTemplate } from "../../services/SchedulingService";
import AlertModal from "../../components/AlertModals/AlertModal";
import ShiftTimeline from "./ShiftTimeline/ShiftTimeline";
import SwitchControl from "../../components/SwitchControl";
import KeyValueRow from "../../components/KeyValueRow";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { intToTime } from "../../tools";


export default function ShiftTemplateEditor({shiftTemplates, handleShiftTemplateCrud}) {

    const [selectedShiftTemplate, setSelectedShiftTemplate] = useState(undefined);
    const [isCreating, setIsCreating] = useState(false);

    const [name, setName] = useState('');
    const [startTime, setStartTime] = useState(540);
    const [endTime, setEndTime] = useState(1020);
    const [breaks, setBreaks] = useState([]);


    const [showDelete, setShowDelete] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const templateToSubmit = {
            uid: selectedShiftTemplate,
            name: name,
            startTime: startTime,
            endTime: endTime,
            breaks: breaks,
        }

        if (selectedShiftTemplate) {
            const response = await updateShiftTemplate(templateToSubmit);
            if (response.status === 200) {
                handleShiftTemplateCrud('update', templateToSubmit);
                setSelectedShiftTemplate(undefined);
            }
        } else {
            const response = await createShiftTemplate(templateToSubmit);
            if (response.status === 200) {
                templateToSubmit.uid = response.uid;
                handleShiftTemplateCrud('create', templateToSubmit);
                setIsCreating(false);
            }
        }

        setIsSubmitting(false);
    }

    const handleDeleteShiftTemplate = async () => {
        const response = await deleteShiftTemplate(selectedShiftTemplate);
        if (response.status === 200) {
            handleShiftTemplateCrud('delete', selectedShiftTemplate);
            setSelectedShiftTemplate(undefined);
            setIsCreating(false);
            return true;
        }
        return false;
    }

    const handleAddBreak = () => {
        const newBreaks = Array.from(breaks);
        if (newBreaks.length > 0 && newBreaks[newBreaks.length - 1].endTime + 30 <= endTime - 30) {
            newBreaks.push({
                startTime: newBreaks[newBreaks.length - 1].endTime + 30,
                endTime: newBreaks[newBreaks.length - 1].endTime + 30 + 30
            })
        } else if (breaks.length === 0 && startTime + 30 <= endTime - 30) {
            newBreaks.push({
                startTime: startTime + 30,
                endTime: startTime + 60
            })

        }
        setBreaks(newBreaks);
    }

    const handleRemoveBreak = (index) => {
        const newBreaks = breaks.toSpliced(index, 1);
        setBreaks(newBreaks);
    }

    const handleSetSelectedShiftTemplate = (shiftTemplate) => {
        setSelectedShiftTemplate(shiftTemplate.uid);
        setName(shiftTemplate.name);
        setStartTime(shiftTemplate.startTime);
        setEndTime(shiftTemplate.endTime);
        setBreaks(shiftTemplate.breaks.map(sb => structuredClone(sb)));
        setIsCreating(false);
    }

    const handleCreateNewShiftTemplate = () => {
        setSelectedShiftTemplate(undefined);
        setName('Untitled Template');
        setStartTime(540);
        setEndTime(1020);
        setBreaks([]);
        setIsCreating(true);
    }


    const shiftTemplateElements = shiftTemplates.map((st) => {
        return (
            <ListGroup.Item key={st.uid} action active={selectedShiftTemplate === st.uid} onClick={() => {handleSetSelectedShiftTemplate(st)}}>
                {st.name}
            </ListGroup.Item>
        )
    })

    const is2DayShift = endTime >= 24 * 60;

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Shift Templates</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'row', padding: 0}}>
                <div style={{flex: 1, padding: 16, display: 'flex', flexDirection: 'column', gap: 12}}>
                    <Button variant='outline-primary' onClick={handleCreateNewShiftTemplate}>
                        <FontAwesomeIcon icon={faPlus}/>
                        <span style={{marginLeft: 8}}>Create New Template</span>
                    </Button>
                    <ListGroup>
                        {shiftTemplateElements}
                    </ListGroup>
                </div>
                <div style={{width: 1, backgroundColor: 'lightgray', marginLeft: 6, marginRight: 6}}/>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', gap: 8, padding: 16}}>
                    { (selectedShiftTemplate || isCreating) && 
                        <>
                            <CustomControl floatingLabel title='Name' value={name} setValue={setName} maxLength={30}/>
                            <ListGroup>
                                <KeyValueRow isListItem forceRow title={intToTime(startTime)} value='Start Time'/>
                                { 
                                    breaks.map((b, index) => {
                                        return (
                                            <ListGroup.Item key={index} style={{display: 'flex', padding: 0, border: 'none'}}>
                                                <div style={{padding: '8px 16px 8px 16px', display: 'flex', justifyContent: 'space-between', flex: 1, borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray'}}>
                                                    <span>{intToTime(b.startTime)}</span>
                                                    <span style={{opacity: 0.5}}>{`${b.endTime - b.startTime}-Minute Break`}</span>
                                                </div>
                                                <div style={{display: 'flex'}}>
                                                    <Button variant='outline-danger' style={{borderRadius: 0, borderTop: index > 0 ? 'none' : undefined}} onClick={() => {
                                                        handleRemoveBreak(index)
                                                    }}>
                                                        <FontAwesomeIcon icon={faX}/>
                                                    </Button>
                                                </div>

                                            </ListGroup.Item>
                                        )
                                    })
                                    
                                }
                                <KeyValueRow isListItem forceRow title={intToTime(endTime)} value='End Time'/>
                            </ListGroup>
                            <Button variant='outline-primary' onClick={handleAddBreak}>Add Break</Button>
                            <ShiftTimeline startTime={startTime} setStartTime={setStartTime} endTime={endTime} setEndTime={setEndTime} breaks={breaks} setBreaks={setBreaks}/>
                            <CustomButton disabled={!name} isLoading={isSubmitting} onClick={handleSubmit} label={selectedShiftTemplate ? 'Save Changes' : 'Submit'}/>
                            { selectedShiftTemplate && 
                                <Button style={{border: 'none', backgroundColor: 'transparent', boxShadow: 'none', color: 'red', paddingBottom: 0}} onClick={() => {setShowDelete(true)}}>Delete Template</Button>
                            }
                        </>
                            
                        
                    }
                </div>

            </Modal.Body>
            <AlertModal show={showDelete} onHide={() => {setShowDelete(false)}} title={'Delete Shift Template?'} message={'This cannot be undone.'} buttonLabel={'Delete Shift Template'} callBack={handleDeleteShiftTemplate}/>
        </>
    )
}