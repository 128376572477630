/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import QuickTable from '../../components/QuickTable';
import SortableTable, { SortableTableCol, SortableTableRow } from '../../components/SortableTable';
import moment from 'moment';

export default function SchedulingEmployeeSelector({employees, locationIdentifier, date, selectedEmployee, setSelectedEmployee, selectedShift, shifts, timeOff, callOffs}) {
    const allTitles = employees.reduce((prev, curr) => {
        return prev.includes(curr.pay.title) ? prev : prev.concat([curr.pay.title]);
    }, []);
    const allLocations = employees.reduce((prev, curr) => {
        return prev.find(l => l.uid === curr.locationIdentifier) ? prev : prev.concat([curr.location]);
    }, []);


    const [titleFilter, setTitleFilter] = useState(allTitles);
    const [locationFilter, setLocationFilter] = useState([locationIdentifier]);
    const [employeeTypeFilter, setEmployeeTypeFilter] = useState([0, 1]);


    const dayIndex = moment(date).format('d');

    const columns = [
        new SortableTableCol('name', 'Name', null, (a, b) => {return a > b ? 1 : a < b ? -1 : 0}, null),
        new SortableTableCol('title', 'Title', null, (a, b) => {return a > b ? 1 : a < b ? -1 : 0}, null),
        new SortableTableCol('location', 'Location', null, (a, b) => {return (a > b ? 1 : a < b ? -1 : 0)}, null),
        new SortableTableCol('available', `Available ${moment(date).format('dddd')}s`, null, (a, b) => {return (a === b ? 0 : a ? -1 : 1)}, null),
        new SortableTableCol('employeeType', 'Employee Type', null, (a, b) => {return (b - a)}, null),
        new SortableTableCol('conflicts', 'Conflicts?', null, (a, b) => {return (a - b)}, null),
    ]

    const shiftsOnDay = shifts.filter(s => s.date === date);
    const timeOffOnDay = timeOff.filter(to => date >= to.startDate && date <= to.endDate);
    const callOffsOnDay = callOffs.filter(co => co.date === date);

    const rows = employees.filter((employee) => {
        return titleFilter.includes(employee.pay.title) 
            && locationFilter.includes(employee.locationIdentifier) 
            && employeeTypeFilter.includes(employee.pay.fulltime) 
            && employee.pay.payType >= 0
        ;
    }).map((employee) => {
        
        const employeeShifts = shiftsOnDay.filter(s => s.uid !== selectedShift && s.userIdentifier === employee.uid).map(s => 'Shift');
        const employeeTimeOff = timeOffOnDay.filter(to => to.userIdentifier === employee.uid).map(to => to.status === 0 ? 'Pending Time Off Request' : 'Approved Time Off');
        const employeeCallOffs = callOffsOnDay.filter(co => co.userIdentifier === employee.uid).map(co => co.notifiedManager ? 'Call-Off' : 'No-Show');

        const conflicts = [...employeeShifts, ...employeeTimeOff, ...employeeCallOffs];

        let conflictSeverity = conflicts.length > 0 ? 1 : 0;

        for (let i = 0; i < conflicts.length; i++) {
            if (conflicts[i] === 'Shift') {
                conflictSeverity = 3;
                break;
            } else if (conflicts[i] === 'Approved Time Off' && conflictSeverity < 2) {
                conflictSeverity = 2;
            }
        }

        return new SortableTableRow(
            {
                name: employee.lastName, 
                title: employee.pay.title,
                location: employee.location.name,
                available: employee.availability.charAt(dayIndex) === '1',
                employeeType: employee.pay.fulltime,
                conflicts: conflictSeverity
            }, 
            (
                <tr key={employee.uid} style={selectedEmployee === employee.uid ? {backgroundColor: 'var(--bs-primary)'} : {}} className='cursor-pointer' onClick={() => {setSelectedEmployee(employee.uid)}}>
                    <td style={{color: selectedEmployee === employee.uid ? 'white' : 'black'}}>{`${employee.lastName}, ${employee.firstName} ${employee.middleName}`}</td>
                    <td style={{color: selectedEmployee === employee.uid ? 'white' : 'black'}}>{employee.pay.title}</td>
                    <td style={{color: selectedEmployee === employee.uid ? 'white' : 'black'}}>{employee.location.name}</td>
                    <td style={{color: selectedEmployee === employee.uid ? 'white' : 'black'}}>{employee.availability.charAt(dayIndex) === '1' ? 'Yes' : 'No'}</td>
                    <td style={{color: selectedEmployee === employee.uid ? 'white' : 'black'}}>{employee.pay.fulltime ? 'Full-Time' : 'Part-Time'}</td>
                    <td style={{color: conflictSeverity === 0 ? 'limegreen' : conflictSeverity === 1 ? '#AAAA00' : conflictSeverity === 2 ? 'orange' : 'red'}}>
                        <div style={{backgroundColor: selectedEmployee === employee.uid ? 'white' : undefined, borderRadius: 6, width: 'fit-content', padding: '0px 4px 0px 4px'}}>
                            {conflicts.length === 0 ? 'None' : conflicts.length === 1 ? conflicts[0] : `${conflicts[0]} +${conflicts.length - 1}`}
                        </div>
                    </td>
                </tr>
            )
        )
    })

    

    const titleFilterDropdownItems = allTitles.map((title) => {
        return (
            <Dropdown.Item key={title} active={titleFilter.includes(title)} onClick={() => {
                if (titleFilter.includes(title)) {
                    setTitleFilter(titleFilter.filter(t => t !== title));
                } else {
                    setTitleFilter(titleFilter.concat([title]));
                }
            }}>
                {title}
            </Dropdown.Item>
        )
    })
    const locationFilterDropdownItems = allLocations.map((location) => {
        return (
            <Dropdown.Item key={location.uid} active={locationFilter.includes(location.uid)} onClick={() => {
                if (locationFilter.includes(location.uid)) {
                    setLocationFilter(locationFilter.filter(l => l !== location.uid));
                } else {
                    setLocationFilter(locationFilter.concat([location.uid]));
                }
            }}>
                {location.name}
            </Dropdown.Item>
        )
    })
    const employeeTypeFilterDropdownItems = [1, 0].map((employeeType) => {
        return (
            <Dropdown.Item key={employeeType} active={employeeTypeFilter.includes(employeeType)} onClick={() => {
                if (employeeTypeFilter.includes(employeeType)) {
                    setEmployeeTypeFilter(employeeTypeFilter.filter(et => et !== employeeType));
                } else {
                    setEmployeeTypeFilter(employeeTypeFilter.concat([employeeType]));
                }
            }}>
                {employeeType ? 'Full-Time' : 'Part-Time'}
            </Dropdown.Item>
        )
    })


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Select Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', gap: 8, marginBottom: 8}}>
                    <Dropdown autoClose='outside'>
                        <Dropdown.Toggle variant='outline-primary'>Filter By Title</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {titleFilterDropdownItems}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown autoClose='outside'>
                        <Dropdown.Toggle variant='outline-primary'>Filter By Location</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {locationFilterDropdownItems}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown autoClose='outside'>
                        <Dropdown.Toggle variant='outline-primary'>Fitler By Employee Type</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {employeeTypeFilterDropdownItems}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <SortableTable title={'Employees'} columns={columns} rows={rows} bordered striped hover/>
            </Modal.Body>
        </>
    )
}