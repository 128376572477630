/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react'
import HumanResourcesHub from './HumanResourcesHub';
import HumanResourcesProfile from './HumanResourcesProfile';
import HumanResourcesTimesheet from './HRTimesheet/HumanResourcesTimesheet';
import HumanResourcesPermissions from './HumanResourcesPermissions';
import {getHumanResourceEmployees} from '../../services/HumanResourcesService';
import HREmployeeSelectorModal from './HREmployeeSelectorModal';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import HRSchedule from './HRSchedule/HRSchedule';
import HRUserLoan from './HRLoans/HRUserLoan';
import HRUserPto from './HRUserPTO/HRUserPto';
import HRUserPay from './HRPay/HRUserPay';
import PageSpinner from '../../components/PageSpinner';
import moment from 'moment';
import HRDocuments from './HRDocuments/HRDocuments';
import Cookies from 'universal-cookie';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function HumanResourcesUser({selectedEmployee, employees, locations, handleGoBackToHub}) {
    const permissions = new Cookies().get('userData')['pay']['permissions'];
    
    const tabs = {
        'Profile': {
            permission: 'hrProfile',
            component: <HumanResourcesProfile permissions={permissions} locations={locations?.filter(l => l.isEnabled)} selectedEmployee={selectedEmployee} employees={employees} />,
        },
        'Schedule': {
            permission: 'hrSchedule',
            component: <HRSchedule selectedEmployee={selectedEmployee}/>,
        },
        'Timesheet': {
            permission: 'hrTimesheet',
            component: <HumanResourcesTimesheet selectedEmployee={selectedEmployee}/>,
        },
        'Pay': {
            permission: 'hrPay',
            component: <HRUserPay selectedEmployee={selectedEmployee}/>,
        },
        'PTO': {
            permission: 'hrPto',
            component: <HRUserPto selectedEmployee={selectedEmployee}/>,
        },
        'Loans': {
            permission: 'hrLoans',
            component: <HRUserLoan selectedEmployee={selectedEmployee}/>,
        },
        'Documents': {
            permission: 'hrDocuments',
            component: <HRDocuments selectedEmployee={selectedEmployee}/>,
        },
        'Permissions': {
            permission: 'hrPermissions',
            component: <HumanResourcesPermissions selectedEmployee={selectedEmployee}/>,
        },
        
    };

    const [activeTab, setActiveTab] = useState(Object.keys(tabs).find(key => permissions[tabs[key].permission] == 1));

    //Creating the button elements from tabs
    const tabButtons = Object.keys(tabs).filter(key => key == 'Hub' || permissions[tabs[key].permission] == 1).map((key) => {
        return (
            <Button 
                key={key}
                style={activeTab === key ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} 
                onClick={() => setActiveTab(key)}
            >
                {key}
            </Button>
        )
    });

    return (
        <div style={{display: 'flex', flexDirection: 'column', padding: 12, height: '100%'}}>
            <div style={{display: 'flex', alignItems: 'flex-start', gap: 12}}>
                <Button variant='outline-primary' style={{display: 'flex', gap: 4, alignItems: 'center'}} onClick={handleGoBackToHub}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                    <span>Back to Hub</span>
                </Button>
                <div style={{flex: 1}}>
                    <ButtonGroup style={{display: 'flex'}}>{tabButtons}</ButtonGroup>
                </div>
                <h4 style={{textAlign: 'center', opacity: 0.65, fontWeight: 'bold', marginBottom: 0, marginTop: 4}}>{`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</h4>
            </div>
            <hr/>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 12, paddingRight: 12, overflowY: 'hidden'}}>
                { (activeTab === 'Hub' || selectedEmployee) && 
                    <div style={{flex: 1, overflowY: 'scroll', padding: 8}}>
                        {tabs[activeTab]?.component} 
                    </div>
                }
            </div>
        </div>
    )
}