import React, { useDebugValue, useEffect, useState } from 'react'
import { Button, Col, Dropdown, Modal } from 'react-bootstrap'
import SearchBar from '../../components/SearchBar';
import SortableTable from '../../components/SortableTable';
import { createDevice, deleteDevice, getDevices, updateDevice } from '../../services/MyCompanyService';
import QuickTable from '../../components/QuickTable';
import CustomButton from '../../components/CustomButton';
import CustomControl from '../../components/CustomControl';
import RadioControl from '../../components/RadioControl';
import { ValidationGroup, validateExistence } from '../../validation';
import QRCode from 'react-qr-code';
import { AppGlobals } from '../../App';
import CustomAlert from '../../components/CustomAlert';

export default function MyCompanyDevices() {

    const [deviceSearch, setDeviceSeach] = useState('');
    const [devices, setDevices] = useState(null);
    const [locationFilter, setLocationFilter] = useState('');
    const [allLocations, setAllLocations] = useState(null);
    const [kioskToken, setKioskToken] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showEditorModal, setShowEditorModal] = useState(false);
    const [device, setDevice] = useState({});
    const [selectedDevice, setSelectedDevice] = useState(null);

    const validationGroupCreate = new ValidationGroup();
    const validationGroupEdit = new ValidationGroup();
    
    async function loadData(){
        const response = await getDevices();
        if (response.status === 200) {
            setDevices(response.devices);
            setAllLocations(response.locations);
            setLocationFilter(response.locations.map(l=>l.uid))
            handleSetDevice('locationIdentifier', response.locations?.[0].uid);
        }
    }
    
    useEffect(() => {
        loadData();
    }, [])

    async function handleCreate(){
        const response = await createDevice(device);
        if (response.status === 200) {
            setKioskToken(response.kioskToken);
            loadData()
        } 
    }

    async function handleUpdate(){
        const response = await updateDevice(selectedDevice);
        if(response.status === 200){
            loadData();
        }
        setShowEditorModal(false);
    }

    async function handleDelete(){
        const response = await deleteDevice(selectedDevice.uid);
        if(response.status === 200){
            loadData();
        } else{
            AppGlobals.alert(<CustomAlert type='danger' title='Failed to delete device!'/>);
        }
        setShowEditorModal(false);
    }

    function handleSetDevice(key, value){
        const newDevice = structuredClone(device);
        newDevice[key] = value;
        setDevice(newDevice);
    }
    
    function handleUpdateDevice(key, value){
        const newDevice = structuredClone(selectedDevice);
        newDevice[key] = value;
        setSelectedDevice(newDevice);
    }

    const devicesRows = devices?.filter(d=>{
        if (!d.name.toLowerCase().includes(deviceSearch.toLowerCase())){
            return;
        }
        return locationFilter.includes(d.locationIdentifier)
    }).map((d) => {
        return (
            <tr key={d.uid} className='cursor-pointer' onClick={()=>{setSelectedDevice(d); setKioskToken(d.kioskToken); setShowEditorModal(true)}}>
                <td>{d.name}</td>
                <td>{allLocations.find(l=>l.uid == d.locationIdentifier).name}</td>
                <td>{d.vendorId ? 'Yes': 'No'}</td>
            </tr>
        )
    }).sort((a, b)=> a.vendorId ? 0 : b? -1 : 1)
    
    const locationFilterDropdownItems = allLocations?.map((location) => {
        return (
            <Dropdown.Item key={location.uid} active={locationFilter.includes(location.uid)} onClick={() => {
                if (locationFilter.includes(location.uid)) {
                    setLocationFilter(locationFilter.filter(l => l !== location.uid));
                } else {
                    setLocationFilter(locationFilter.concat([location.uid]));
                }
            }}>
                {location.name}
            </Dropdown.Item>
        )
    })

    return (
        <>
            <div style={{display: 'flex', gap: 8, marginBottom: 8, justifyContent: 'space-between'}}>
                <Dropdown autoClose='outside'>
                    <Dropdown.Toggle variant='outline-primary'>Filter By Location</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {locationFilterDropdownItems}
                    </Dropdown.Menu>
                </Dropdown>
                <Button onClick={()=>{setShowModal(true); setKioskToken(null)}} variant='outline-primary'>Add Device</Button>
            </div>
            <>
                <SearchBar label='Search by name' value={deviceSearch} setValue={(value) => setDeviceSeach(value)}/>
                <div style={{padding: 6}}></div>
                <QuickTable rows={devicesRows} headers={['Name', 'Location', 'Registered']} widths={[null, null, 1]} noWrap responsive={false}/>
            </>
            <Modal show={showModal} onHide={(()=> !kioskToken && setShowModal(false))} backdrop='static' >
                <Modal.Header onHide={(()=>{setShowModal(false); setKioskToken(null)})} closeButton>
                    <Modal.Title>{!kioskToken ? 'Adding a Device' : 'Scan Code'}</Modal.Title>               
                </Modal.Header>
                <Modal.Body>
                {!kioskToken &&
                        <>
                        <CustomControl
                            floatingLabel 
                            title={'Name'} 
                            maxLength={30} 
                            value={device.name} 
                            setValue={value=>handleSetDevice('name', value)} 
                            validator={validationGroupCreate.createValidator(() => validateExistence(device.name))}
                            />
                        <div style={{marginBottom: 16}}></div>
                        <RadioControl 
                            lg={6}
                            title='Select Location:' 
                            inline
                            selection={device.locationIdentifier} 
                            setSelection={(value) => handleSetDevice('locationIdentifier', value)} 
                            optionNames={allLocations?.filter(l=> l.isEnabled == 1).map(l => l.name)} 
                            optionValues={allLocations?.filter(l=> l.isEnabled == 1).map(l => l.uid)}
                            />
                        </>
                    }
                    {kioskToken && 
                        <>
                            <div style={{ height: "auto", margin: "0 auto", padding: '12px 0', maxWidth: 256, width: "100%" }}>
                                <QRCode
                                    value={kioskToken}
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    viewBox={`0 0 256 256`}
                                    />
                            </div>
                            <h5 style={{textAlign:'center', marginTop: 16, marginBottom: 10}}>{kioskToken}</h5>
                        </>
                    }
                    </Modal.Body>
                <Modal.Footer>
                    <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                        {!kioskToken ? <CustomButton disabled={!validationGroupCreate.isValid()} label={'Create Device'} onClick={handleCreate}/> :
                        <p style={{textAlign: 'center'}}>Open the My Tactical Business App on your device and scan the QR code or type in the token to register your device.</p>}
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditorModal} onHide={(()=>{setShowEditorModal(false);})}>
                <Modal.Header closeButton>
                <>
                    <Modal.Title>{selectedDevice?.vendorId ? 'Editing Device' : 'Scan Code'}</Modal.Title>
                    {selectedDevice?.vendorId && <CustomButton style={{marginLeft: 12}} variant='outline-danger' label={'Delete Device'} onClick={handleDelete}/>}             
                </>
                </Modal.Header>
                    <Modal.Body>
                    {selectedDevice?.vendorId &&
                        <>
                            <CustomControl
                                floatingLabel 
                                title={'Name'} 
                                maxLength={30} 
                                value={selectedDevice?.name} 
                                setValue={value=>handleUpdateDevice('name', value)} 
                                validator={validationGroupEdit.createValidator(() => validateExistence(selectedDevice?.name))}
                            />
                            <div style={{marginBottom: 16}}></div>
                            <RadioControl 
                                lg={6}
                                title='Select Location:' 
                                inline
                                selection={selectedDevice?.locationIdentifier} 
                                setSelection={(value) => handleUpdateDevice('locationIdentifier', value)} 
                                optionNames={allLocations?.filter(l=> l.isEnabled == 1).map(l => l.name)} 
                                optionValues={allLocations?.filter(l=> l.isEnabled == 1).map(l => l.uid)}
                            />
                        </>
                    }
                    { kioskToken && !selectedDevice?.vendorId &&
                        <>
                            <div style={{ height: "auto", margin: "0 auto", padding: '12px 0', maxWidth: 256, width: "100%"}}>
                                <QRCode
                                    value={kioskToken}
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <h5 style={{textAlign:'center', marginTop: 16, marginBottom: 10}}>{kioskToken}</h5>
                        </>
                    }
                    </Modal.Body>
                <Modal.Footer>
                    <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                        { selectedDevice?.vendorId ?
                            <CustomButton disabled={!validationGroupEdit.isValid()} label={'Confirm'} onClick={handleUpdate}/>   
                        :
                            <p style={{textAlign: 'center'}}>Open the My Tactical Business App on your device and scan the QR code or type in the token to register your device.</p>
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
