/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { timeout } from "../tools";
import { getResponse } from "./serviceTools";

export async function getCallin(token) {
    const data = {
        type: 'REPLACE',
        target: 'REPLACE',
        callinToken: token,
    }

    await timeout(300);

    return {
        status: '200',
        data: {
            companyName: 'TTA',
            date: '2023-04-01',
            timeScheduled: '08:00',
            csaName: 'Twinsburg',
            workAreaName: '132A',
            vehicleName: '345233',
        }
    }
    //return await getResponse(data);
}

export async function acceptCallin(token) {
    const data = {
        type: 'REPLACE',
        target: 'REPLACE',
        callinToken: token,
    }
    await timeout(300);

    return {
        status: '200',
    }
}