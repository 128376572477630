/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import { createShift, createShiftTemplate, deleteShift, updateShift } from '../../../services/SchedulingService';
import CustomButton from '../../../components/CustomButton';
import { Button, Col, Container, Dropdown, Form, InputGroup, ListGroup, Modal, Row } from 'react-bootstrap';
import CustomControl from '../../../components/CustomControl';
import { ValidationGroup } from '../../../validation';
import SwitchControl from '../../../components/SwitchControl';
import SchedulingEmployeeSelector from '../SchedulingEmployeeSelector';
import moment from 'moment';
import KeyValueRow from '../../../components/KeyValueRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import AlertModal from '../../../components/AlertModals/AlertModal';
import ShiftTimeline from '../ShiftTimeline/ShiftTimeline';

export default function ShiftEditor({employees, shiftTypes, shiftTemplates, locationIdentifier, date, shift, handleShiftCrud, handleShiftTemplateCrud, shifts, timeOff, callOffs}) {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userIdentifier, setUserIdentifier] = useState(shift?.userIdentifier);
    const [startTime, setStartTime] = useState(shift?.startTime ?? 9 * 60);
    const [endTime, setEndTime] = useState(shift?.endTime ?? 17 * 60);
    const [shiftTypeIdentifier, setShiftTypeIdentifier] = useState(shift?.shiftTypeIdentifier);
    const [allDay, setAllDay] = useState(shift && !shift.startTime && shift.startTime !== 0 ? true : false);

    const [showEmployeeSelector, setShowEmployeeSelector] = useState(false);

    const [breaks, setBreaks] = useState(shift?.breaks ?? []);

    const [isSavingTemplate, setIsSavingTemplate] = useState(false);
    const [templateName, setTemplateName] = useState('');

    const [showDelete, setShowDelete] = useState(false);
    

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const shiftType = shiftTypes.find(st => st.uid === shiftTypeIdentifier);
        const shiftToSubmit = {
            uid: shift?.uid,
            locationIdentifier: locationIdentifier,
            userIdentifier: userIdentifier,
            date: date,
            startTime: allDay ? null : startTime,
            endTime: allDay ? null : endTime,
            breaks: allDay ? [] : breaks,
            shiftTypeIdentifier: shiftTypeIdentifier,
            title: shiftType.title,
            color: shiftType.color
        }

        if (shift) {
            const response = await updateShift(shiftToSubmit);
            if (response.status === 200) {
                handleShiftCrud('update', shiftToSubmit);
            }
        } else {
            const response = await createShift(shiftToSubmit);
            if (response.status === 200) {
                shiftToSubmit.uid = response.uid;
                handleShiftCrud('create', shiftToSubmit);
            }
        }
        setIsSubmitting(false);
    }

    const handleCreateTemplate = async () => {
        setIsSavingTemplate(true);
        const templateToSubmit = {
            name: templateName,
            startTime: allDay ? null : startTime,
            endTime: allDay ? null : endTime,
            breaks: allDay ? [] : breaks,
        }
        const response = await createShiftTemplate(templateToSubmit);
        if (response.status === 200) {
            templateToSubmit.uid = response.uid;
            handleShiftTemplateCrud('create', templateToSubmit);
            setTemplateName('');
        }

        setIsSavingTemplate(false);
    }

    const handleDeleteShift = async () => {
        const response = await deleteShift(shift.uid);
        if (response.status === 200) {
            handleShiftCrud('delete', shift.uid);
            return true;
        }
        return false;
    }

    const handleAddBreak = () => {
        const newBreaks = Array.from(breaks);
        if (newBreaks.length > 0 && newBreaks[newBreaks.length - 1].endTime + 30 <= endTime - 30) {
            newBreaks.push({
                startTime: newBreaks[newBreaks.length - 1].endTime + 30,
                endTime: newBreaks[newBreaks.length - 1].endTime + 30 + 30
            })
        } else if (breaks.length === 0 && startTime + 30 <= endTime - 30) {
            newBreaks.push({
                startTime: startTime + 30,
                endTime: startTime + 60
            })

        }
        setBreaks(newBreaks);
    }

    const handleRemoveBreak = (index) => {
        const newBreaks = breaks.toSpliced(index, 1);
        setBreaks(newBreaks);
    }


    const handleImportTemplate = (template) => {
        setStartTime(template.startTime);
        setEndTime(template.endTime);
        setBreaks(template.breaks);
    }

    const validationGroup = new ValidationGroup();

    const shiftTypeDropdownElements = shiftTypes.map((st) => {
        return (
            <Dropdown.Item key={st.uid} active={shiftTypeIdentifier === st.uid} onClick={() => {setShiftTypeIdentifier(st.uid)}}>{st.title}</Dropdown.Item>
        )
    })

    const shiftTemplateElements = shiftTemplates.map((template) => {
        return (
            <Dropdown.Item key={template.uid} onClick={() => {handleImportTemplate(template)}}>{template.name}</Dropdown.Item>
        )
    })

    const selectedEmployee = employees.find(e => e.uid === userIdentifier);



    const is2DayShift = endTime >= 24 * 60;

    

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{shift ? 'Editing Shift' : 'Creating Shift'}</Modal.Title>
            </Modal.Header>
            <Modal.Header>
                { shiftTemplates.length > 0 &&
                    <Dropdown>
                        <Dropdown.Toggle variant='outline-primary'>Import Shift Template</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {shiftTemplateElements}
                        </Dropdown.Menu>
                    </Dropdown>
                }
                <div style={{flex: 1}}/>
                { !allDay && 
                    <InputGroup style={{width: 'fit-content'}}>
                        <CustomControl style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}} title='Template name' wrapperStyle={{width: 300}} maxLength={30} value={templateName} setValue={setTemplateName}/>
                        <CustomButton disabled={!templateName} isLoading={isSavingTemplate} onClick={handleCreateTemplate} label='Save as Template'/>
                    </InputGroup>
                }

            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <Container>
                    <Row>
                        <Col lg={allDay ? 12 : 6} style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                            <InputGroup>
                                <InputGroup.Text style={{flex: 1}}>Date</InputGroup.Text>
                                <InputGroup.Text>{moment(date).format('MMM D, YYYY')}</InputGroup.Text>
                            </InputGroup>
                            <div>
                                <InputGroup>
                                    <InputGroup.Text style={{flex: 1}}>Employee</InputGroup.Text>
                                    <Button variant='outline-primary' onClick={() => {setShowEmployeeSelector(true)}}>{selectedEmployee ? `${selectedEmployee.lastName}, ${selectedEmployee.firstName} ${selectedEmployee.middleName}` : 'Select Employee'}</Button>
                                </InputGroup>
                                { !userIdentifier &&
                                    <span style={{color: 'red', fontSize: 14}}>Required</span>
                                }
                            </div>
                            <div>
                                <Dropdown>
                                    <InputGroup>
                                        <InputGroup.Text style={{flex: 1}}>Shift Type</InputGroup.Text>
                                        <Dropdown.Toggle variant='outline-primary'>{shiftTypes.find(st => st.uid === shiftTypeIdentifier)?.title ?? 'Select Shift Type'}</Dropdown.Toggle>
                                    </InputGroup>
                                    <Dropdown.Menu>
                                        {shiftTypeDropdownElements}
                                    </Dropdown.Menu>
                                </Dropdown>
                                { !shiftTypeIdentifier &&
                                    <span style={{color: 'red', fontSize: 14}}>Required</span>
                                }
                            </div>

                            <SwitchControl title='All Day?' value={allDay} setValue={setAllDay}/>
                            { !allDay && 
                                <>
                                    <ListGroup>
                                        <KeyValueRow isListItem forceRow title={moment(date).add(startTime, 'minutes').format(is2DayShift ? 'MMM D [at] h:mm A' : 'h:mm A')} value='Start Time'/>
                                        { 
                                            breaks.map((b, index) => {
                                                return (
                                                    <ListGroup.Item key={index} style={{display: 'flex', padding: 0, border: 'none'}}>
                                                        <div style={{padding: '8px 16px 8px 16px', display: 'flex', justifyContent: 'space-between', flex: 1, borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray'}}>
                                                            <span>{moment(date).add(b.startTime, 'minutes').format(is2DayShift ? 'MMM D [at] h:mm A' : 'h:mm A')}</span>
                                                            <span style={{opacity: 0.5}}>{`${b.endTime - b.startTime}-Minute Break`}</span>
                                                        </div>
                                                        <div style={{display: 'flex'}}>
                                                            <Button variant='outline-danger' style={{borderRadius: 0, borderTop: index > 0 ? 'none' : undefined}} onClick={() => {
                                                                handleRemoveBreak(index)
                                                            }}>
                                                                <FontAwesomeIcon icon={faX}/>
                                                            </Button>
                                                        </div>

                                                    </ListGroup.Item>
                                                )
                                            })
                                            
                                        }
                                        <KeyValueRow isListItem forceRow title={moment(date).add(endTime, 'minutes').format(is2DayShift ? 'MMM D [at] h:mm A' : 'h:mm A')} value='End Time'/>
                                    </ListGroup>


                                    <Button variant='outline-primary' onClick={handleAddBreak}>Add Break</Button>
                                </>
                            }


                        </Col>
                        { !allDay && 
                            <Col lg={6} style={{padding: 0}}>
                                <ShiftTimeline startTime={startTime} setStartTime={setStartTime} endTime={endTime} setEndTime={setEndTime} breaks={breaks} setBreaks={setBreaks}/>
                            </Col>
                        }
                    </Row>
                </Container>


            </Modal.Body>
            <Modal.Footer>
                { shift && 
                    <Button variant='outline-danger' onClick={() => {setShowDelete(true)}}>Delete Shift</Button>
                }
                <CustomButton label={shift ? 'Save Changes' : 'Submit'} disabled={!userIdentifier || !shiftTypeIdentifier} isLoading={isSubmitting} onClick={handleSubmit}/>
            </Modal.Footer>
            <Modal show={showEmployeeSelector} onHide={() => {setShowEmployeeSelector(false)}} size='xl'>
                <SchedulingEmployeeSelector 
                    employees={employees} 
                    date={date} 
                    selectedEmployee={userIdentifier} 
                    locationIdentifier={locationIdentifier} 
                    setSelectedEmployee={(value) => {
                        setUserIdentifier(value); 
                        setShowEmployeeSelector(false);
                    }} 
                    selectedShift={shift?.uid}
                    shifts={shifts}
                    timeOff={timeOff}
                    callOffs={callOffs}
                />
            </Modal>
            <AlertModal show={showDelete} onHide={() => {setShowDelete(false)}} title={'Delete Shift?'} message={'This cannot be undone.'} buttonLabel={'Delete Shift'} callBack={handleDeleteShift}/>
        </>
    )
}