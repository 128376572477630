/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import CustomButton from '../../../components/CustomButton';
import { Modal } from 'react-bootstrap';
import { createEmployeeDocument } from '../../../services/HumanResourcesService';
import moment from 'moment';
import FileControl from '../../../components/FileControl';

export default function HRDocumentUpload({userIdentifier, handleDocumentCrud}) {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [document, setDocument] = useState({});

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const newDocument = {
            userIdentifier: userIdentifier,
            name: document.name,
            type: document.type,
            base64: document.base64,
            dateCreated: moment().format('YYYY-MM-DD'),
        }

        const response = await createEmployeeDocument(newDocument);
        if (response.status === 200) {
            newDocument.uid = response.uid;
            handleDocumentCrud('create', newDocument);
        }
        
        setIsSubmitting(false);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Upload Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FileControl document={document} setDocument={setDocument}/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isSubmitting} label='Upload Document' onClick={handleSubmit}/>
            </Modal.Footer>
        </>
          
    );
}