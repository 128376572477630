/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import CustomControl from './CustomControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';

export default function ChildSupportControl({value, setValue}) {
    
    const handleSetChildSupport = (index, newValue) => {
        const newChildSupport = Array.from(value);
        newChildSupport[index] = newValue;
        setValue(newChildSupport);
    }

    const handleAddChildSupport = () => {
        const newChildSupport = Array.from(value);
        newChildSupport.push('');
        setValue(newChildSupport);
    }

    const handleRemoveChildSupport = (index) => {
        setValue(value.toSpliced(index, 1));
    }



    const csElements = value.map((cs, index) => {
        return (
            <InputGroup key={index} style={{width: 220}}>
                <InputGroup.Text>$</InputGroup.Text>
                <CustomControl maxDecimal={9999.99} title={`#${index + 1}`} value={cs} setValue={(value) => {handleSetChildSupport(index, value)}} style={{borderRadius: 0}}/>
                <Button variant='outline-primary'  onClick={() => {handleRemoveChildSupport(index)}}>
                    <FontAwesomeIcon icon={faX}/>
                </Button>
            </InputGroup>

        )
    })

    return (
        <div style={{border: '1px solid lightgray', borderRadius: 6, padding: 12}}>
            <p>Child Support</p>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: 12}}>
                {csElements}
                <Button variant='outline-primary' onClick={handleAddChildSupport}><FontAwesomeIcon icon={faPlus}/></Button>
            </div>
        </div>
    )
}