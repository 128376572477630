import React, { useEffect, useState } from 'react'
import { getCompanyProfile, updateCompanyProfile } from '../../services/MyCompanyService';
import AddressControl from '../../components/AddressControl';
import PageSpinner from '../../components/PageSpinner';
import { AddressValidator, ValidationGroup, validateKioskSchedule, validatePhoneNumber } from '../../validation';
import { Button, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import CustomControl from '../../components/CustomControl';
import SwitchControl from '../../components/SwitchControl';
import KeyValueRow from '../../components/KeyValueRow';
import { AppGlobals } from '../../App';
import CustomAlert from '../../components/CustomAlert';
import CustomButton from '../../components/CustomButton';
import MyCompanyOfferLetters from './MyCompanyOfferLetters';

export default function MyCompanyProfile() {

    const [isLoading, setIsLoading] = useState(true);
    const [companyProfile, setCompanyProfile] = useState(null);
    const [offerLetters, setOfferLetters] = useState([]);
    const [showOfferLetterModal, setShowOfferLetterModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validationGroup = new ValidationGroup();
    const addressValidator = new AddressValidator(companyProfile?.address);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    async function loadData(){
        setIsLoading(true);
        const response = await getCompanyProfile();
        if(response.status === 200) {
            setCompanyProfile(response.company)
            setOfferLetters(response.offerLetters)
        }

        setIsLoading(false);
    }

    function handleSetCompany(key, value) {
        const newCompany = structuredClone(companyProfile);
        newCompany[key] = value;
        setCompanyProfile(newCompany);  
    }

    async function handleSubmit(){    
        setIsSubmitting(true);
        const response = await updateCompanyProfile(companyProfile);
        if(response.status === 200){
            AppGlobals.alert(<CustomAlert type='success' title={response.message}/>);
        }else{
            AppGlobals.alert(<CustomAlert type='danger' title={response.message}/>);
        }
        setIsSubmitting(false);
    }

    useEffect(()=>{
        loadData();
    }, [])

    return (isLoading ? <PageSpinner/> :
    <div style={{display: 'flex', flexDirection: 'column'}}>
        <Container fluid>
            <p style={{textAlign: 'center', margin: 0, fontSize: 36, fontWeight: 'bold', marginBottom: 24}}>{companyProfile.name}</p>
            <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                <Col lg={12}>
                <p style={{fontWeight: 'bold', fontSize: 24, width: '100%', textAlign: 'left'}}>Company Information</p>
                </Col>
                <Col style={{marginBottom: 10}} lg={12}> 
                    <CustomControl 
                    maxLength={10}
                    floatingLabel={true} 
                    title='Phone Number' 
                    value={companyProfile.phoneNumber} 
                    setValue={(value)=>handleSetCompany('phoneNumber', value)}
                    validator={validationGroup.createValidator(() => validatePhoneNumber(companyProfile.phoneNumber))}
                    />
                </Col>
                <Col style={{marginBottom: 24}} lg={12}> 
                    <CustomControl 
                    floatingLabel={true} 
                    title='Company Benefits'
                    value={companyProfile.benefits} 
                    setValue={(value)=>handleSetCompany('benefits', value)}
                    maxLength={200}
                    />
                </Col>
                <AddressControl title={'Company Address'} validator={addressValidator} lg={4} address={companyProfile.address} setAddress={(value)=> handleSetCompany('address', value)}/>
            </Row>
            <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                <Col lg={12}>
                    <p style={{fontWeight: 'bold', fontSize: 24, width: '100%', textAlign: 'left'}}>Company Settings</p>
                </Col>
                <Col lg={12}>
                    <CustomControl
                        maxInt={365}
                        floatingLabel
                        wrapperStyle={{padding: 0}}
                        style={{marginBottom: 10}}
                        type='number' 
                        title='Set number of days an employee can see while viewing their schedule in the MTB App' 
                        value={companyProfile.kioskScheduleDays} 
                        setValue={(value) => {handleSetCompany('kioskScheduleDays', parseInt(value))}} 
                        validator={validationGroup.createValidator(() => validateKioskSchedule(companyProfile.kioskScheduleDays))}
                    />
                </Col>
                <Col lg={12}>
                    <CustomControl
                        maxLength={20}
                        floatingLabel
                        wrapperStyle={{padding: 0}}
                        style={{marginBottom: 10}}
                        type='text' 
                        title='EIN' 
                        value={companyProfile.ein} 
                        setValue={(value) => {handleSetCompany('ein', parseInt(value))}} 
                    />
                </Col>
                <SwitchControl
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 12, border: '1px solid lightgray', borderRadius: 6, padding: '12px 12px 12px 12px', backgroundColor: 'white'}}
                    lg={12}
                    title='Force all employees to use MFA'
                    value={companyProfile.forceMfa}
                    setValue={(value)=>handleSetCompany('forceMfa', value)}
                />
            </Row>
            <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <p style={{fontWeight: 'bold', fontSize: 24, textAlign: 'left'}}>Offer Letter Settings</p>
                    <div style={{display: 'flex', alignItems: 'center', gap: 6}}>
                        <Button style={{marginBottom: 12, marginLeft: 'auto', display: 'block'}} variant={'outline-primary'} onClick={() => setShowOfferLetterModal(true)}>View/Edit Offer Letter Templates</Button>
                    </div>
                </div>
                <SwitchControl
                    noWrap={false}
                    breakpoints={{lg: 12}}
                    title='Require employees to provide at least one emergency contact when onboarding'
                    value={companyProfile.require1EContact}
                    setValue={(value) => handleSetCompany('require1EContact', value)}
                />
            </Row>
        </Container>
        <CustomButton label='Save Changes' isLoading={isSubmitting} disabled={isSubmitting || !addressValidator.isValid() || !validationGroup.isValid()} onClick={handleSubmit}/>
        <h4 style={{textAlign: 'center', marginTop: 64, marginBottom: 12}}>Contact TTA to alter any of the following settings:</h4>
        <ListGroup>
            <KeyValueRow isListItem title='Payroll Enabled' value={companyProfile.payrollEnabled == 1 ? 'Yes' : 'No'}/>
            <KeyValueRow isListItem title='Payroll Period Length' value={companyProfile.payrollPeriodLength}/>
            <KeyValueRow isListItem title='Payroll Period Start Date' value={days[companyProfile.payrollPeriodStartDay]}/>
            <KeyValueRow isListItem title='Payroll For Each Location' value={companyProfile.payrollForEachLocation == 1 ? 'Yes' : 'No'}/>
        </ListGroup>
        <Modal centered size='lg' show={showOfferLetterModal} onHide={() => setShowOfferLetterModal(false)}>
            <MyCompanyOfferLetters offerLetters={offerLetters} setOfferLetters={setOfferLetters} settings={companyProfile} setSettingsKey={handleSetCompany}/>
        </Modal>
    </div>
    )
      
    
}
