/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './css/custom.min.css';
import Login from './views/Login/Login';
import Cookies from 'universal-cookie';
import Callin from './views/Callin/Callin';
import PasswordReset from './views/PasswordReset';
import { checkAuthentication } from './services/AuthenticationService';
import './css/custom.css';

document.title = 'TTA';

const isCallin = window.location.href.split('/')[3] === 'callin';
const isPasswordReset = window.location.href.split('/')[3] === 'password-reset';
const passwordResetToken = window.location.href.split('/')[4];

if (isPasswordReset) {
    ReactDOM.createRoot(document.getElementById("root")).render(
        <PasswordReset token={passwordResetToken}/>
    );
} else if (isCallin) {
    ReactDOM.createRoot(document.getElementById("root")).render(
        <Callin/>
    );
} else {
    checkAuthentication().then((response) => {
        if (response && response.status === 200) {
            const cookieMonster = new Cookies();
            cookieMonster.set('userData', response.userData);
            cookieMonster.set('companyName', response.companyName);
            cookieMonster.set('timeclockEnabled', response.timeclockEnabled);

            ReactDOM.createRoot(document.getElementById("root")).render(<App auth={response.auth} documents={response.documents}/>);
        } else if (response.status === 401) {
            ReactDOM.createRoot(document.getElementById("root")).render(<Login/>);
        }
    });
}