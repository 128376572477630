/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { toFixedMax } from '../Payroll/payrollTools';
import { Form } from 'react-bootstrap';

export default function TimesheetCalendar({timesheetData, selectedDate, setSelectedDate}) {

    const startOfMonth = moment(selectedDate).startOf('month').startOf('week').format('YYYY-MM-DD');
    
    const rows = [0, 1, 2, 3, 4, 5].map((weekIndex) => {
        const columns = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {

            const date = moment(startOfMonth).add(weekIndex, 'weeks').add(dayIndex, 'days').format('YYYY-MM-DD');

            const dataForDay = timesheetData.filter((entry) => {
                return entry.inPunch.substring(0, 10) === date;
            });

            const timesheetCards = dataForDay.map((entry) => {
                return (
                    <div key={entry.uid} style={{backgroundColor: 'white', display: 'flex', flexDirection: 'column', textAlign: 'start', paddingLeft: 4, paddingRight: 4, border: '1px solid var(--bs-primary)', borderLeft: '6px solid var(--bs-primary)', borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
                        
                        { entry.outPunch ?
                            <>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2}}>
                                    <span style={{color: 'black'}}>{ moment(entry.inPunch).format('h:mm A')}</span>
                                    <FontAwesomeIcon icon={faArrowRight}/>
                                    <span style={{color: 'black'}}>{moment(entry.outPunch).format('h:mm A')}</span>
                                </div>
                                <span style={{fontWeight: 'bold', opacity: 0.7, textAlign: 'center'}}>{'Total: ' + toFixedMax(moment(entry.outPunch).diff(moment(entry.inPunch), 'hours', true), 2) + ' Hours'}</span>
                            </>
                            :
                            <span style={{color: 'green', opacity: 0.6}}>{'IN: ' + moment(entry.inPunch).format('h:mm A')}</span>
                        }
                    </div>
                )
            })

            return (
                <td key={dayIndex} style={{padding: 0, textAlign: 'center', fontSize: '1.5vmin', backgroundColor: date === moment().format('YYYY-MM-DD') ? 'rgba(255, 255, 0, 0.2)' : undefined, opacity: moment(date).format('MMM') !== moment(selectedDate).format('MMM') ? 0.5 : 1}}>
                    <div style={{display: 'flex', height: '100%', flexDirection: 'column', gap: 2, justifyContent: 'space-between'}}>
                        <p style={{alignSelf: 'flex-end', marginBottom: 0, marginRight: 4}}>{moment(date).format('D')}</p>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 2, padding: 4}}>
                            {timesheetCards}
                        </div>
                    </div>
                </td>
            );
        });

        return (
            <tr key={weekIndex} style={{height: '16.66%'}}>
                {columns}
            </tr>
        );
    });

    const columnHeaders = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((str) => {
        return (<th key={str} style={{fontSize: '1.5vmin', width: '14.3%', textAlign: 'center', fontWeight: 'normal', opacity: 0.66}}>{str}</th>);
    });

   

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 8, gap: 12}}>
                <Button variant={'outline-primary'} onClick={() => {setSelectedDate(moment(selectedDate).subtract(1, 'month').format('YYYY-MM-DD'))}}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </Button>
                <Form.Control type='month' value={moment(selectedDate).format('YYYY-MM')} onChange={(event) => {setSelectedDate(`${event.target.value}-01`)}}/>
                <Button variant={'outline-primary'} onClick={() => {setSelectedDate(moment(selectedDate).add(1, 'month').format('YYYY-MM-DD'))}}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </Button>
            </div>
            
            
            <div style={{width: '100%', flex: 1}}>
                <Table bordered size='sm' style={{width: '100%', height: '100%'}}>
                    <thead>
                        <tr>
                            {columnHeaders}
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
            </div>
            
        </div>
    );
}