/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';


export default function SortableTable({title, rows, columns, fontSize, defaultSort, noMargin, centerHeaders, responsive, bordered, striped, hover, size}) {
    const [sortOrder, setSortOrder] = useState(defaultSort ? defaultSort : [0, true]);


    const handleSort = (index) => {
        if (sortOrder[0] === index) {
            setSortOrder([index, !sortOrder[1]]);
        } else {
            setSortOrder([index, true]);
        }
    }

    const rowsSorted = Array.from(rows).sort((a, b) => {
        const sortMethod = columns[sortOrder[0]].sorting;
        const key = columns[sortOrder[0]].key;
        if (sortMethod) {
            return sortMethod(a.values[key], b.values[key]) * (sortOrder[1] ? 1 : -1);
        } else {
            return 0;
        }
    })


    const headerElements = columns.map((col, index) => {
        return (
            <th 
                className='cursor-pointer'
                onClick={() => {handleSort(index)}}
                key={col.key} 
                style={{padding: 0, textAlign: centerHeaders ? 'center' : undefined, width: col.width, whiteSpace: col.noWrap ? 'nowrap' : 'normal'}}
            >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 8, marginTop: -1, marginBottom: -1, borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray'}}>
                    <span className='unselectable-text'>{col.label}</span>
                    { col.sorting &&
                        <FontAwesomeIcon icon={index === sortOrder[0] ? sortOrder[1] ? faSortUp : faSortDown : faSort} style={{marginLeft: 4}}/>
                    }
                </div>
            </th>
        )
    });

    const rowElements = rowsSorted.map(row => row.content);

    return (
        <div style={{flex: 1}}>
            <Table responsive={responsive} bordered={bordered} striped={striped} hover={hover} size={size} style={{fontSize: fontSize ? fontSize : undefined, marginBottom: noMargin ? 0 : undefined}}>
                <thead>
                    { title && 
                        <tr>
                            <th colSpan={columns.length} style={{textAlign: 'center'}}>{title}</th>
                        </tr>
                    }
                    <tr>
                        {headerElements}
                    </tr>
                </thead>
                <tbody>
                    {rowElements}
                </tbody>
            </Table>
        </div>
    )
}

export class SortableTableCol {
    key;
    label;
    width;
    sorting;
    noWrap;

    constructor(key, label, width, sorting, noWrap) {
        this.key = key;
        this.label = label;
        this.width = width;
        this.sorting = sorting;
        this.noWrap = noWrap;
    }
}

export class SortableTableRow {
    values;
    content;

    constructor(values, content) {
        this.values = values;
        this.content = content;
    }
}