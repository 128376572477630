/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { getAllEmployeePay, getEmployeeDocument } from '../../../services/HumanResourcesService';
import QuickTable from '../../../components/QuickTable';
import { saveFile, usdFormatter } from '../../../tools';
import { Button, ListGroup, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import PageSpinner from '../../../components/PageSpinner';
import moment from 'moment';
import KeyValueRow from '../../../components/KeyValueRow';
import HRUserPayEditor from './HRUserPayEditor';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { AppGlobals } from '../../../App';
import CustomAlert from '../../../components/CustomAlert';

export default function HRUserPay({selectedEmployee, crudCallback}) {
    const [isLoading, setIsLoading] = useState(false);
    const [effectivePay, setEffectivePay] = useState(undefined);
    const [nonEffectivePay, setNonEffectivePay] = useState([]);
    const [permissionsTemplate, setPermissionsTemplate] = useState([]);
    const [selectedPay, setSelectedPay] = useState(false);
    const [showEditor, setShowEditor] = useState(false);

    
    const loadData = async () => {
        setIsLoading(true);
        const response = await getAllEmployeePay(selectedEmployee.uid);
        if (response.status === 200) {
            setEffectivePay(response.effectivePay);
            setNonEffectivePay(response.nonEffectivePay);
            setPermissionsTemplate(response.permissionsTemplate);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, crudCallback ? [] : [selectedEmployee])

    const sortPayAsc = (a, b) => {
        return a.dateEffective < b.dateEffective ? -1 : a.dateEffective > b.dateEffective ? 1 : 0;   
    }

    const sortPayDesc = (a, b) => {
        return a.dateEffective < b.dateEffective ? 1 : a.dateEffective > b.dateEffective ? -1 : 0;   
    }

    const handlePayCrud = (type, data) => {
        let newArray = Array.from(nonEffectivePay);
        let newEffectivePay = effectivePay;
        if (type === 'create') {
            if (effectivePay) {
                if (data.dateEffective > effectivePay.dateEffective && data.dateEffective <= moment().format('YYYY-MM-DD')) {
                    newArray.push(effectivePay);
                    newEffectivePay = data;
                } else {
                    newArray.push(data);
                }
            } else if (data.dateEffective <= moment().format('YYYY-MM-DD')) {
                newEffectivePay = data;
            } else {
                newArray.push(data);
            }

        } else if (type === 'update') {
            if (effectivePay && data.uid == effectivePay?.uid) {
                newEffectivePay = data;
            } else {
                newArray = newArray.filter(p => p.uid !== data.uid);
                newArray.push(data);
            }
        }
        setEffectivePay(newEffectivePay);
        setNonEffectivePay(newArray);

        if (crudCallback) {
            crudCallback();
        }
    }

    const handleDownloadDocument = async (uid) => {
        const response = await getEmployeeDocument(uid);
        if (response.status === 200) {
            saveFile(response.document.name, response.document.base64);
        } else {
            AppGlobals.alert(<CustomAlert type={'danger'} title={response.message ?? 'Failed to Download Document'}/>)
        }
    }



    const upcomingPayRows = nonEffectivePay.filter(p => p.dateEffective > moment().format('YYYY-MM-DD')).sort(sortPayAsc).map((p) => {
        return (
            <PayRow key={p.uid} p={p} onClick={() => {setSelectedPay(p); setShowEditor(true);}}/>
        )
    })

    const historicPayRows = nonEffectivePay.filter(p => p.dateEffective < moment().format('YYYY-MM-DD') && p.payType !== -1).sort(sortPayDesc).map((p) => {
        return (
            <PayRow key={p.uid} p={p} onClick={() => {setSelectedPay(p); setShowEditor(true);}}/>
        )
    })

    const terminatedPayRows = nonEffectivePay.filter(p => p.payType === -1).sort(sortPayDesc).map((p) => {
        return (
            <PayRow key={p.uid} p={p} noCursor/>
        )
    })


    return isLoading ? <PageSpinner/> : (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            
            <Button style={{alignSelf: 'flex-end', marginBottom: 12}} variant='outline-primary' onClick={() => {setSelectedPay(undefined); setShowEditor(true)}}>{selectedEmployee.pay.payType !== -1 ? 'Create New Pay' : 'Rehire'}</Button>
            
            { effectivePay ?
                <div style={{marginBottom: 12, border: '1px solid lightgray', borderRadius: 6, padding: 12}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                        <h4 style={{margin: 0}}>Effective Pay</h4>
                        { effectivePay.payType !== -1 && 
                            <Button variant='outline-primary' onClick={() => {setSelectedPay(effectivePay); setShowEditor(true);}}>View / Edit</Button>
                        }
                    </div>
                    { effectivePay.payType != -1 ?
                        <ListGroup>
                            <KeyValueRow isListItem title='Effective Date' value={moment(effectivePay.dateEffective).format('MMM D, YYYY')}/>
                            <KeyValueRow isListItem title='Title' value={effectivePay.title}/>
                            <KeyValueRow isListItem title='Employee Type' value={effectivePay.fulltime ? 'Full-Time' : 'Part-Time'}/>
                            <KeyValueRow isListItem title='Pay Type' value={effectivePay.payType == 0 ? 'Per Hour' : effectivePay.payType == 1 ? 'Salaried (Non-Exempt)' : 'Salaried (Exempt)'}/>
                            <KeyValueRow isListItem title='Pay Rate' value={usdFormatter.format(effectivePay.payRate)}/>
                            <ListGroup.Item style={{opacity: 0.5}}>
                                {effectivePay.notes}
                            </ListGroup.Item>
                        </ListGroup>
                    :
                        <ListGroup>
                            <KeyValueRow isListItem title='Effective Date' value={moment(effectivePay.dateEffective).format('MMM D, YYYY')}/>
                            <KeyValueRow isListItem title='Termination Type' value={['Unknown', 'Resignation', 'Abandonment', 'Retirement', 'Terminate for Cause', 'Layoff', 'End of Contract'][effectivePay.termination?.terminationType ?? 0]}/>
                            {effectivePay.termination?.disciplinaryOne &&
                            <KeyValueRow 
                                isListItem title='Disciplinary #1'
                                opacity={effectivePay.termination.disciplinaryOne ? 1 : .5}
                                value={<Button variant='outline-primary' onClick={() => {handleDownloadDocument(effectivePay.termination.disciplinaryOne)}}><FontAwesomeIcon icon={faDownload}/></Button>}
                            />}
                            {effectivePay.termination?.disciplinaryTwo &&
                            <KeyValueRow 
                                isListItem title='Disciplinary #2'
                                opacity={effectivePay?.termination.disciplinaryTwo ? 1 : .5}
                                value={<Button variant='outline-primary' onClick={() => {handleDownloadDocument(effectivePay.termination.disciplinaryTwo)}}><FontAwesomeIcon icon={faDownload}/></Button>}
                            />}
                            {effectivePay.termination?.screenshotOne &&
                            <KeyValueRow 
                                isListItem title='Screenshot #1'
                                opacity={effectivePay.termination.screenshotOne ? 1 : .5}
                                value={<Button variant='outline-primary' onClick={() => {handleDownloadDocument(effectivePay.termination.screenshotOne)}}><FontAwesomeIcon icon={faDownload}/></Button> }
                            />}
                            {effectivePay.termination?.screenshotTwo &&
                            <KeyValueRow 
                                isListItem title='Disciplinary #2'
                                opacity={effectivePay.termination.screenshotTwo ? 1 : .5}
                                value={<Button variant='outline-primary' onClick={() => {handleDownloadDocument(effectivePay.termination.screenshotTwo)}}><FontAwesomeIcon icon={faDownload}/></Button>}
                            />}
                            {effectivePay.termination?.terminationLetter &&   
                            <KeyValueRow 
                                isListItem title='Termination Letter'
                                opacity={effectivePay.termination.terminationLetter ? 1 : .5}
                                value={<Button variant='outline-primary' onClick={() => {handleDownloadDocument(effectivePay.termination.terminationLetter)}}><FontAwesomeIcon icon={faDownload}/></Button>}
                            />}
                            {effectivePay.termination?.customerComplaints &&
                            <ListGroup.Item>
                                <h6>Customer Complaints</h6>
                                <p style={{opacity: .6}}>{effectivePay.termination?.customerComplaints}</p>
                            </ListGroup.Item>}
                            <ListGroup.Item>
                                <h6>Termination Synposis</h6>
                                <p style={{opacity: .6}}>{effectivePay.termination?.terminationSynopsis}</p>
                            </ListGroup.Item>
                        </ListGroup>
                    }
                </div>
                :
                <h3>Employee has no effective pay</h3>
            }
            { upcomingPayRows.length > 0 && 
                <QuickTable title='Upcoming Pay' headers={['Effective Date', 'Title', 'Employee Type', 'Pay Type', 'Pay Rate', 'Notes']} rows={upcomingPayRows} hover/>
            }
            { historicPayRows.length > 0 && 
                <QuickTable title='Historic Pay' headers={['Effective Date', 'Title', 'Employee Type', 'Pay Type', 'Pay Rate', 'Notes']} rows={historicPayRows} hover/>
            }
            { terminatedPayRows.length > 0 && 
                <QuickTable title='Historic Termination' headers={['Effective Date', 'Title', 'Employee Type', 'Pay Type', 'Pay Rate', 'Notes']} rows={terminatedPayRows} hover/>
            }
            <Modal show={showEditor} onHide={() => {setShowEditor(false)}} size='xl'>
                <HRUserPayEditor userIdentifier={selectedEmployee.uid} selectedPay={selectedPay} nonEffectivePay={nonEffectivePay} effectivePay={effectivePay} permissionsTemplate={permissionsTemplate} handlePayCrud={handlePayCrud} hideModal={() => setShowEditor(false)}/>
            </Modal>

        </div>
    )

}


function PayRow({p, onClick, noCursor}) {
    return (
        <tr style={{cursor: noCursor ? '' : 'pointer'}} onClick={onClick}>
            <td>{moment(p.dateEffective).format('MMM D, YYYY')}</td>
            <td>{p.title}</td>
            <td>{p.fulltime ? 'Full-Time' : 'Part-Time'}</td>
            <td>{p.payType == 0 ? 'Per Hour' : p.payType == 1 ? 'Salaried (Non-Exempt)' : 'Salaried (Exempt)'}</td>
            <td>{usdFormatter.format(p.payRate)}</td>
            <td>
                <OverlayTrigger overlay={
                    <Popover style={{position: 'fixed', maxWidth: 400}}>
                        <Popover.Header>Notes</Popover.Header>
                        <Popover.Body>{p.notes}</Popover.Body>
                    </Popover>
                }>
                    <FontAwesomeIcon icon={faQuestionCircle} style={{color: 'var(--bs-primary)'}}/>
                </OverlayTrigger>
            </td>
        </tr>
    )
}