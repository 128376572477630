/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './Callin.css';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import logo from '../../assets/TTA-Logo-150.png';
import { acceptCallin, getCallin } from '../../services/CallinService';
import ListGroup from 'react-bootstrap/ListGroup';
import KeyValueRow from '../../components/KeyValueRow';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { formatTime } from '../../tools';
import PageSpinner from '../../components/PageSpinner';
import CustomSpinner from '../../components/CustomSpinner';
import AlertModal from '../../components/AlertModals/AlertModal';


export default class Callin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            isLoading: false,
            data: undefined,
            showModal: false,
            responseSwitch: undefined
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async loadData() {
        this.setState({isLoading: true});
        const token = window.location.href.split('/')[4];
        const response = await getCallin(token);
        if (response && response.status === '200') {
            this.setState({data: response.data});
        } else {
            this.setState({responseSwitch: 'invalidToken'});
        }
        this.setState({isLoading: false});
    }


    componentDidMount() {
        this.setState({token: window.location.href.split('/')[4]}, () => {
            this.loadData();
        });
    }

    async handleSubmit() {
        const response = await acceptCallin(this.state.token);
        if (response && response.status === '200') {
            this.setState({responseSwitch: 'accepted'});
        } else {
            this.setState({responseSwitch: 'failedToAccept'});
        }
        return true;
    }

    render() {

        const content = !this.state.data ? '' : (
            <>
                <ListGroup>
                    <KeyValueRow widthForRow={280} isListItem title='Company' value={this.state.data.companyName}/>
                    <KeyValueRow widthForRow={280} isListItem title='Date' value={moment(this.state.data.date).format('MMM D, YYYY')}/>
                    <KeyValueRow widthForRow={280} isListItem title='Time Scheduled' value={formatTime(this.state.data.timeScheduled, 'h:mm A')}/>
                    <KeyValueRow widthForRow={280} isListItem title='CSA' value={this.state.data.csaName}/>
                    <KeyValueRow widthForRow={280} isListItem title='Work Area' value={this.state.data.workAreaName}/>
                    <KeyValueRow widthForRow={280} isListItem title='Vehicle' value={this.state.data.vehicleName}/>
                </ListGroup>
                <Button variant={'outline-primary'} onClick={() => {this.setState({showModal: true})}}>Accept This Shift</Button>
            </>        
        )

        return (
            <div id='callin-wrapper'>
                <div id='callin-background-image'></div>
                <div id='callin-background-solid'></div>
                <div id='callin-card'>
                    <Card style={{width: '100%', height: '100%'}}>
                        <Card.Header style={{display: 'flex', flexDirection: 'column'}}>
                            <img src={logo} style={{margin: 20, maxWidth: 200, height: 'auto', alignSelf: 'center'}}/>
                        </Card.Header>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                            {this.state.isLoading ? <CustomSpinner/> : 
                                <>
                                    {this.state.responseSwitch === 'invalidToken' ?
                                        <span style={{textAlign: 'center', fontSize: 20, color: 'red'}}>Invalid Link</span>
                                        : this.state.responseSwitch === 'accepted' ?
                                        <span style={{textAlign: 'center', fontSize: 20, color: 'green'}}>Successfully Accepted Shift!</span>
                                        : this.state.responseSwitch === 'failedToAccept' ? 
                                        <span style={{textAlign: 'center', fontSize: 20, color: 'red'}}>Something Went Wrong</span>
                                        :
                                        content
                                    }
                                </>
                            }
                        </Card.Body>
                    </Card>
                </div>
                <AlertModal
                    show={this.state.showModal} 
                    onHide={() => {this.setState({showModal: false})}}
                    centered 
                    title='Confirm Submission' 
                    message={''}
                    callBack={this.handleSubmit}
                    buttonLabel='Accept Shift'
                />
            </div>
        )
    }
}