/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import React from "react";
import SchedulingTableShiftCard from "./SchedulingTableShiftCard";

export default function SchedulingTableChronological({startOfWeek, employees, shifts, handleSelectShift, shiftTypes, timeOff, callOffs}) {

    const tableColumns = [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {
        const date = moment(startOfWeek).add(weekDayIndex, 'days').format('YYYY-MM-DD');
        const shiftsOnDay = shifts.filter(s => s.date === date).sort((a, b) => {
            if (!a.startTime && !b.startTime) {
                return 0;
            } else if (!a.startTime) {
                return -1;
            } else if (!b.startTime) {
                return 1;
            } else {
                return a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0;
            }
        })
        const timeOffOnDay = timeOff.filter(to => date >= to.startDate && date <= to.endDate);
        const callOffsOnDay = callOffs.filter(co => co.date === date);


        const items = shiftsOnDay.map((shift) => {
            const shiftType = shiftTypes.find(st => st.uid === shift.shiftTypeIdentifier);
            const employee = employees.find(e => e.uid == shift.userIdentifier);

            const conflictingShifts = shiftsOnDay.filter(s => s.uid !== shift.uid && s.userIdentifier === shift.userIdentifier).map(s => 'Shift');
            const conflictingTimeOff = timeOffOnDay.filter(to => to.userIdentifier === shift.userIdentifier).map(to => to.status === 0 ? 'Pending Time Off Request' : 'Approved Time Off');
            const conflictingCallOffs = callOffsOnDay.filter(co => co.userIdentifier === shift.userIdentifier).map(co => co.notifiedManager ? 'Call-Off' : 'No-Show');
            
            let conflicts = [...conflictingShifts, ...conflictingTimeOff, ...conflictingCallOffs];

            return (
                <SchedulingTableShiftCard key={shift.uid} employee={employee} shift={shift} shiftType={shiftType} handleSelectShift={handleSelectShift} conflicts={conflicts} chronological/>
            )
        })

        return (
            <div key={weekDayIndex} style={{display: 'flex', flexDirection: 'column', gap: 2, borderRight: '1px solid lightgray', borderBottom: '1px solid lightgray', flex: 1, textAlign: 'center', fontWeight: 'bold', padding: '2px 4px 2px 4px', position: 'relative'}}>
                {items}
            </div>
        )

    });


    return (
        <div className="hidden-scrollbar" style={{display: 'flex', flex: 1, overflowY: 'scroll'}}>
            {tableColumns}
        </div>
    )
    
}