/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Document, Page, pdfjs} from 'react-pdf';
import CustomButton from "../components/CustomButton";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { acknowledgeDocuments } from "../services/MyDocumentsService";

export default function AcknowledgementModal({documents, hideModal}){

    const [acknowledgedDocs, setAcknowledgedDocs] = useState([]);
    const [initialized, setInitialized] = useState(false);

    const handleAcknowledgeDocument = async (doc) => {
        const newArray=Array.from(acknowledgedDocs);
        newArray.push(doc.uid);
        setAcknowledgedDocs(newArray);
        if (newArray.length === documents.length) {
            const response = await acknowledgeDocuments(newArray);
            if (response.status === 200) {
                hideModal();
            }
        }
    }

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        setTimeout(() => {
            setInitialized(true);
        }, 500)
    }, [])

    useEffect(() => {
        function handleKeyDown(event) {
            if (event.keyCode === 27) {
                event.preventDefault();
            }
        }
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    
   
    const pdfs=documents.map((document)=>{
        return (
            <PDFAcknowledgement key={document.uid} documentObject={document} acknowledgeDocument={handleAcknowledgeDocument} initialized={initialized} acknowledgedDocs={acknowledgedDocs}/>
            ) 
        })
        
        return (
            <>
                <Modal.Header>
                    <Modal.Title>Please Read And Acknowledge</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12,}}>
                    {pdfs}
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </>
    )
    
    
    
}

function PDFAcknowledgement({documentObject, acknowledgeDocument, initialized, acknowledgedDocs}){
    const [numPages, setNumPages] = useState(undefined);
    


    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/', 
    };
    
      
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }
     
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12, alignItems: 'center'}}> 
            <div style={{width: '100%', overflow: 'scroll', padding: 12, border: '1px solid lightgray', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                { initialized &&
                    <Document file={documentObject.base64} onLoadSuccess={onDocumentLoadSuccess} options={options} renderMode='canvas' > 
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>
                }
            </div>
            { !acknowledgedDocs.includes(documentObject.uid) ?
                <CustomButton label={'Acknowledge Document'} onClick={()=>acknowledgeDocument(documentObject)}/>
            :
                <span style={{color:'green'}}>Document Acknowledged</span>
            }
        </div>
    )

}