/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import { faCheckCircle, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { CloseButton, Toast } from "react-bootstrap";

export default function CustomAlert({type, title, dismiss}) {
    
    const types = {
        warning: {
            icon: faTriangleExclamation,
            color: 'var(--bs-warning)'
        },
        danger: {
            icon: faTriangleExclamation,
            color: 'var(--bs-danger)'
        },
        info: {
            icon: faQuestionCircle,
            color: 'var(--bs-primary)'
        },
        success: {
            icon: faCheckCircle,
            color: 'var(--bs-success)'
        }
    }

    return (
        <Toast.Header style={{borderBottom: 'none', borderBottomRightRadius: 6, borderBottomLeftRadius: 6}} closeButton={!dismiss}>
            <FontAwesomeIcon style={{color: types[type].color, marginRight: 8}} icon={types[type].icon}/>
            <strong style={{marginRight: 'auto'}}>{title}</strong>
            { dismiss &&
                <CloseButton onClick={dismiss}/>
            }
        </Toast.Header>
    )
    
}