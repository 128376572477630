/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import CustomButton from './CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import CustomControl from './CustomControl';

export default function PasswordProtectedControl({ type, disabled, originalValue, label, value, setValue, errorMsg, xs, sm, md, lg, xl, xxl, callBack, validator, maxLength, maxInt, maxDecimal, style }) {

    const [showModal, setShowModal] = useState(false);
    const [unlocked, setUnlocked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('')

    const handleSubmit = async () => {
        setIsLoading(true);
        setError(undefined);
        callBack(password).then((response) => {

            if (response) {
                setUnlocked(true);
                setShowModal(false);
            } else {
                setError(errorMsg)
            }

            setIsLoading(false);
        });
    }

    const content = (
        <div style={{ position: 'relative' }}>
            <CustomControl
                type={unlocked ? type : 'password'}
                disabled={disabled || !unlocked}
                value={value}
                originalValue={originalValue}
                title={label}
                validator={validator}
                setValue={setValue}
                placeholder=' '
                maxLength={unlocked ? maxLength : undefined}
                maxInt={unlocked ? maxInt : undefined}
                maxDecimal={unlocked ? maxDecimal : undefined}
                style={style}
            />
            {unlocked ? '' :
                <Button variant={'outline-primary'} style={{ position: 'absolute', right: 8, top: 16, padding: '0px 4px 0px 4px' }} onClick={() => { setShowModal(true) }}>
                    <FontAwesomeIcon icon={faLockOpen} size='xs' />
                </Button>
            }
            <Modal show={showModal} onHide={() => { setShowModal(false) }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Password Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Please enter the password for your account to access this field</Form.Label>
                        <FloatingLabel label='Password'>
                            <Form.Control type='password' disabled={isLoading} value={password} onChange={(event) => { setPassword(event.target.value) }} placeholder=' ' />
                        </FloatingLabel>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <span style={{ marginRight: 8, color: 'red' }}>{error}</span>
                    <CustomButton label='Submit' onClick={handleSubmit} isLoading={isLoading} />
                </Modal.Footer>
            </Modal>
        </div>
    );

    const colWrappedContent = (
        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} style={{ marginBottom: 8 }}>
            {content}
        </Col>
    )

    return (
        <>
            {xs || sm || md || lg || xl || xxl ?
                colWrappedContent : content
            }

        </>
    )
}